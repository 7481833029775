
import React, { useState, useEffect, useMemo, useRef } from 'react'
import { Formik, Field, ErrorMessage, useFormik } from 'formik';
import { CreateOutlet } from '../../../services/company_panel/outletService';
import { GetAllCountries, GetAllStates, GetAllCities } from '../../../services/globalService';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Swal from "sweetalert2"
import Breadcrums from '../../../components/company_panel/common/breadcrumbs';
import $ from "jquery";
import TimePicker from "@ashwinthomas/react-time-picker-dropdown";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { capitalizeAllFirstLetters, capitalizeFirstLetter, formatToTwoDecimals, getBrandId, getCompany, getOutletId, showPrice, SpecialCharacter } from '../../../helpers/commonFunctions';
import GoogleMapModal from '../../outlet/GoogleMapModal';
import { GetBrandList } from '../../../services/brandService';
import { Dropdown } from 'semantic-ui-react';

import { Table, Button, Modal, Form, } from 'react-bootstrap';
import axios from 'axios';
import { GetAllParentCategories } from '../../../services/company_panel/menuCategoryService';
import { GetAllActiveTaxes, GetTaxList } from '../../../services/company_panel/setting/taxConfigurationService';
import { GetActiveOrderModes, GetOrderModeList } from '../../../services/company_panel/setting/orderModeConfigurationService';
import { CreateAddOn, EditAddOn, GetAddOnCategoryList, GetAllAddOnCategory, SingleAddOn } from '../../../services/company_panel/menuAddOnCategory';
import { GetAllInventories, GetInventoryList, GetSingleInventory } from '../../../services/company_panel/inventoryService';
import CustomMultiSelect from '../../../components/common/CustomMultiSelect';
import * as Yup from "yup";

const AddOnsEdit = () => {
    const navigate = useNavigate()
    const [btnClicked, setBtnClicked] = useState(false);
    const [page, setPage] = useState(1);
    const [searchParams] = useSearchParams();
    const params = useParams()
    const [show, setShow] = useState(false);
    const [recipes, setRecipes] = useState([]);
    const [taxList, setTaxList] = useState([]);
    // const [orderList, setOrderList] = useState([]);
    const [inventoryList, setInventoryList] = useState([]);
    const [rawList, setRawList] = useState({});
    const [showDefault, setShowDefault] = useState({})

    // const [addOnDataList, setAddOnDataList] = useState([{
    //     rawMaterial: '',
    //     usageUOM: '',
    //     quantity: '',
    //     price: '',
    //     calories: '',
    //     orderMode: '',
    //     raw_material_price: '',
    //     raw_material_calories: ''
    // }])

    const [formData, setFormData] = useState({
        usageUOM: '',
        quantity: '',
        price: '',
        calories: '',
        raw_material_price: '',
        raw_material_calories: ''
    });
    const [orderMode, setOrderMode] = useState([])

    const [editIndex, setEditIndex] = useState(-1);
    const [isBrandChanged, setIsBrandChanged] = useState(false);

    const handleShow = () => setShow(true);
    // const handleClose = () => setShow(false);
    // const currentBrand = getBrandId();
    const [currentBrand] = useState(getBrandId());

    // useMemo(() => {
    //     document.addEventListener('brand-changed', function () {
    //         if (currentBrand != getBrandId()) {
    //             navigate(`/company/menu/menu/add_ons/list/${1}`);
    //         }
    //         setIsBrandChanged(!isBrandChanged);
    //     });
    // },[]);

    useMemo(() => {
        document.addEventListener('brand-changed', function () {
            setIsBrandChanged(!isBrandChanged);
        });
        if (currentBrand != getBrandId()) {
            navigate(`/company/menu/menu/add_ons/list/${1}`);
        }
    });



    // const handleChangeModel = (e) => {
    //     const { name, value } = e.target
    //     let obj = {};
    //     let ab = inventoryList.filter((item) => {
    //         if (item.id == value) {
    //             obj = item;
    //         }
    //     })
    //     setRawList({ id: Number(value), name: obj.item_name })
    //     //  setFormData({ ...formData, [e.target.name]: e.target.value });
    //     let inventory = {};
    //     if (Number(value)) {
    //         GetSingleInventory({ id: Number(value), outlet_id: getOutletId() }).then(data => {
    //             inventory = data?.data?.response || {}; // Use optional chaining and default to empty object
    //             // const inventoryData = inventory?.inventory_unit_converisons?.filter(item => item.id == value)
    //             const inventoryData = inventory?.inventory_unit_converisons.filter((item) => {
    //                 return item.unit_type == 'Usage Unit';
    //             })
    //             setFormData({
    //                 usageUOM: inventory?.uom?.uom_code,
    //                 quantity: 1,
    //                 price: inventoryData[0]?.price,
    //                 calories: inventoryData[0]?.calories,
    //                 raw_material_price: inventoryData[0]?.price,
    //                 raw_material_calories: inventoryData[0].calories
    //             })
    //         })
    //             .catch(error => {
    //                 console.log("error ====> ", error);
    //             });
    //     }
    // };

    // const resetForm = () => {
    //     setFormData({
    //         usageUOM: '',
    //         quantity: '',
    //         price: '',
    //         calories: '',
    //     });
    //     setRawList({ id: 0, name: '' });
    //     setOrderMode('');
    //     setShow(false);
    // }

    const saveData = (e) => {
        e.preventDefault();
        const newData = {
            rawMaterial: {
                id: rawList.id,
                name: rawList?.name,
            },
            usageUOM: formData?.usageUOM,
            quantity: formData?.quantity,
            price: formData?.price,
            calories: formData?.calories,
            orderMode: orderMode,
            raw_material_price: formData?.raw_material_price,
            raw_material_calories: formData?.raw_material_calories,
            display_rank: formData?.display_rank
        }

        if (editIndex != -1) {
            const addOnList = addOnDataList;
            addOnList[editIndex] = newData;
            setAddOnDataList(prevState => addOnList);
            setEditIndex(prevState => -1);
        } else {
            setAddOnDataList(prevState => [
                ...prevState,
                newData
            ]);
        }
        resetForm();
    }

    const breadcrumbs = [
        {
            title: "Menu",
            url: "/company/menu/menu/categories/list/1"
        },
        {
            title: "Add-Ons",
            url: "/company/menu/menu/add_ons/list/1"
        },
        {
            title: "Add-Ons Listing",
            url: "/company/menu/menu/add_ons/list/1"
        },
        {
            title: searchParams.get('copy') ? "Create an Add-On" : "Edit an Add-On",
            url: ""
        },
    ]

    const [submitted, setSubmitted] = useState(false)
    const [brandList, setBrandList] = useState([])
    const [addMoreCount, setAddMoreCount] = useState([1]);

    useEffect(() => {
        const formData = new FormData();
        formData.append('brand_id', getBrandId());
        GetAllAddOnCategory(formData)
            .then(data => {
                setBrandList(data && data.data && data.data.response ? data.data.response : []);
            })
            .catch(error => {
                console.log("error ====> ", error);
            });
    }, [isBrandChanged]);

    useEffect(() => {
        const formData = new FormData()
        formData.append('brand_id', getBrandId());
        formData.append('type', 'tax-groups');
        GetAllActiveTaxes(formData).then(data => {
            setTaxList(data && data.data && data.data.response ? data.data.response : [])
        }).catch(error => {
            console.log("error ====> ", error);
        })
    }, [isBrandChanged]);

    // useEffect(() => {
    //     GetActiveOrderModes(getBrandId()).then(data => {
    //         const tempData = data && data.data && data.data.response ? data.data.response : [];
    //         const mappedData = tempData.map((res) => ({
    //             key: res?.id,
    //             text: capitalizeAllFirstLetters(res.name),
    //             value: res?.id,
    //         }));
    //         setOrderList(mappedData);
    //     }).catch(error => {
    //         console.log("error ====> ", error);
    //     })
    // }, [isBrandChanged]);

    useEffect(() => {
        const formData = new FormData()
        formData.append('brand_id', getBrandId());
        GetAllInventories(formData).then(data => {
            setInventoryList(data && data.data && data.data.response ? data.data.response.rows : [])
        }).catch(error => {
            console.log("error ====> ", error);
        })
    }, [isBrandChanged]);

    // Tab script


    const nextStep = (selected) => {
        setPage(page + 1);
        var currentStepNum = $('#checkout-progress').data('current-step');
        var nextStepNum = (currentStepNum + 1);
        var currentStep = $('.step.step-' + currentStepNum);
        var nextStep = $('.step.step-' + nextStepNum);
        var progressBar = $('#checkout-progress');
        $('.btn-prev').show();
        $('#section' + currentStepNum).toggle();
        $('#section' + nextStepNum).toggle();
        if (nextStepNum == 3) {
            $(this).toggle();
            $('.btn-submit').toggle();
            $('.btn-next').hide();
        }

        $('.checkout-progress').removeClass('.step-' + currentStepNum).addClass('.step-' + (currentStepNum + 1));

        currentStep.removeClass('active').addClass('valid');
        currentStep.find('span').addClass('opaque');
        currentStep.find('.fa.fa-check').removeClass('opaque');

        nextStep.addClass('active');
        progressBar.removeAttr('class').addClass('step-' + nextStepNum).data('current-step', nextStepNum);
    }
    const prevStep = (selected) => {
        setPage(page - 1);
        var currentStepNum = $('#checkout-progress').data('current-step');
        var prevStepNum = (currentStepNum - 1);
        var currentStep = $('.step.step-' + currentStepNum);
        var prevStep = $('.step.step-' + prevStepNum);
        var progressBar = $('#checkout-progress');
        $('.btn-next').removeClass('disabled');
        $('#section' + currentStepNum).toggle();
        $('#section' + prevStepNum).toggle();
        if (currentStepNum == 3) {
            $('.btn-submit').toggle();
            $('.btn-next').toggle();
        }

        if (currentStepNum == 2) {
            $('.btn-prev').hide();
        }
        if (currentStepNum == 1) {
            $('.btn-prev').hide();
            return false;
        }
        if (prevStepNum == 1) {
            $(this).addClass('disabled');
        }
        $('.checkout-progress').removeClass('.step-' + currentStepNum).addClass('.step-' + (prevStepNum));

        currentStep.removeClass('active');
        prevStep.find('span').removeClass('opaque');
        prevStep.find('.fa.fa-check').addClass('opaque');

        prevStep.addClass('active').removeClass('valid');
        progressBar.removeAttr('class').addClass('step-' + prevStepNum).data('current-step', prevStepNum);
    };
    const brandOptions = brandList?.map(row => ({
        key: row.id,
        text: row.name,
        value: row.id,
    }));
    const orderChange = (val) => {
        // let filterData = orderList?.find((item) => item.id == val)
        // setOrderMode(filterData)
        setOrderMode(val);
    }

    // const handleRemoveItem = (id) => {
    //     const newList = addOnDataList.filter(item => item.id !== id);
    //     setAddOnDataList(newList); // Update the state with the new list
    // };
    // const handleRemoveItem = (index) => {
    //     const newList = [...addOnDataList];
    //     newList.splice(index, 1);
    //     setAddOnDataList(newList);
    // };
    // const handleEditItem = (index) => {
    //     setEditIndex(index);
    //     setShow(true);
    //     setRawList({
    //         id: addOnDataList[index]?.rawMaterial?.id,
    //         name: addOnDataList[index]?.rawMaterial?.name,
    //     })
    //     setFormData({
    //         usageUOM: addOnDataList[index]?.usageUOM,
    //         quantity: addOnDataList[index]?.quantity,
    //         price: addOnDataList[index]?.price,
    //         calories: addOnDataList[index]?.calories,
    //         raw_material_price: addOnDataList[index]?.raw_material_price,
    //         raw_material_calories: addOnDataList[index]?.raw_material_calories,
    //     })
    //     setOrderMode(addOnDataList[index]?.orderMode);
    // }
    const [activeStep, setActiveStep] = useState(1);

    // Function to handle step changes
    const handleStepChange = (step) => {
        setActiveStep(step);
    };

    // const onChangeQuantity = (quantity) => {
    //     setFormData({
    //         ...formData,
    //         quantity: quantity,
    //         price: quantity * formData.raw_material_price,
    //         calories: quantity * formData.raw_material_calories,
    //     })
    // }


    const [orderList, setOrderList] = useState([]);
    const [addOnDataList, setAddOnDataList] = useState([]);
    // const [menuRecipes, setMenuRecipes] = useState([])
    const formRef = useRef();

    const [recipeEditableIndex, setRecipeEditableIndex] = useState(null);



    const resetForm = () => {
        setRawList({ id: 0, name: '' });
        setOrderMode('');
        setShow(false);
    }

    const handleChangeModel = (e) => {
        const { value } = e.target;
        if (Number(value)) {
            GetSingleInventory({ id: Number(value), outlet_id: getOutletId() })
                .then(data => {
                    let inventory = data?.data?.response || {};
                    const inventoryData = inventory?.inventory_unit_converisons.filter((item) => {
                        return item.unit_type == 'Usage Unit';
                    });
                    let myData = { ...formik?.values };
                    myData.rawMaterial = value;
                    myData.usageUOM = inventoryData[0]?.uomDetail?.uom_code;
                    myData.quantity = 1;
                    myData.price = inventoryData[0]?.price;
                    myData.calories = inventoryData[0]?.calories;
                    myData.raw_material_price = inventoryData[0]?.price;
                    formik.setValues(myData);
                })
                .catch(error => {
                    console.log("error ====> ", error);
                });
        }
        else {
            formik.setFieldValue("rawMaterial", "");
        }
    };


    const formik = useFormik({
        validationSchema: Yup.object().shape({
            rawMaterial: Yup.string().trim().required("Raw material is required"),
            // quantity: Yup.string().required("Quantity is required"),
            quantity: Yup.number().integer("Quantity must be a integer").min(1, "Quantity must be greater than 0").required("Quantity is required"),
        }),
        initialValues: {
            rawMaterial: '',
            usageUOM: '',
            quantity: '',
            price: '',
            calories: '',
            orderMode: [],
            raw_material_price: 0
        },
        onSubmit: (values, { resetForm }) => {
            // saveData(e);
            if (recipeEditableIndex === null) {
                setAddOnDataList(prevState => [
                    ...prevState,
                    values
                ]);
            } else {
                let existingData = [...addOnDataList];
                existingData[recipeEditableIndex] = values;
                setAddOnDataList(existingData);
                setRecipeEditableIndex(null);
            }
            handleClose();
        },
    });

    const handleClose = () => {
        setShow(false);
        formik?.resetForm();
        setRecipeEditableIndex(null);
    };

    const handleRemoveItem = (index) => {
        let updatedList = [...addOnDataList];
        updatedList.splice(index, 1);
        setAddOnDataList(updatedList);
    };

    const handleEditItem = (index) => {
        setRecipeEditableIndex(index);
        // console.log("ramveer----", addOnDataList[index])
        // setFormData(addOnDataList[index]);
        formik?.setValues(addOnDataList[index]);
        setShow(true);
    };




    const getRecordFromList = (list, id) => {
        return list.filter(item => Number(item.id) === Number(id))[0];
    }

    useEffect(() => {
        GetActiveOrderModes(getBrandId()).then(data => {
            setOrderList(data && data.data && data.data.response ? data.data.response : [])
        }).catch(error => {
            console.log("error ====> ", error);
        })
    }, [isBrandChanged])

    //   useEffect(() => {
    //     SingleAddOn({ id: params.id }).then(data => {
    //         setShowDefault(data && data?.data && data?.data?.response ? data?.data?.response : []);
    //         const addOn = data && data?.data && data?.data?.response ? data?.data?.response : null;
    //         if (addOn) {
    //             const menuAddOnReciepes = addOn?.menu_add_on_reciepes;
    //             let newData = [];
    //             menuAddOnReciepes.map((item, index) => {
    //                 const usageUnits = item?.inventory?.inventory_unit_converisons?.filter((items) => items.unit_ == 'Usage Unit');
    //                 const uoms = addOn.uoms;
    //                 const uom = uoms?.filter((myUom) => {
    //                     return myUom.id == usageUnits[0]?.uom
    //                 });

    //                 let orderModeIds = [];
    //                 item.menu_add_on_reciepe_order_modes.forEach((orderMode) => {
    //                     orderModeIds.push(orderMode.order_mode);
    //                 })
    //                 const data = {
    //                     rawMaterial: {
    //                         id: item?.inventory?.id,
    //                         name: item?.inventory?.item_name,
    //                     },
    //                     usageUOM: uom[0]?.uom_code,
    //                     quantity: item?.quantity,
    //                     price: item?.quantity * usageUnits[0]?.price,
    //                     calories: item?.quantity * usageUnits[0]?.calor,
    //                     orderMode: orderModeIds,
    //                     raw_material_price: usageUnits[0]?.price,
    //                     raw_material_calories: usageUnits[0]?.calor
    //                 }
    //                 newData.push(data);
    //             })
    //             setAddOnDataList(prevState => newData);
    //         }
    //     }).catch(error => {
    //         console.log("error=====>", error)
    //     })
    // }, [])

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [defaultData] = await Promise.all([
                    SingleAddOn({ id: params?.id }),
                ]);
                const myData = defaultData?.data?.response;
                let menuReciepes = [];
                if (defaultData) {
                    setShowDefault(myData ? myData : {});
                    menuReciepes = myData?.menu_add_on_reciepes && myData?.menu_add_on_reciepes.length > 0 ? myData?.menu_add_on_reciepes : [];
                    formRef?.current?.setFieldValue('outlet_name', myData?.name);
                    formRef?.current?.setFieldValue('outlet_name_ar', myData?.name_ar);
                    formRef?.current?.setFieldValue('brand_id', myData?.menu_add_on_category?.id);
                    formRef?.current?.setFieldValue('tax_id', myData?.tax_group_id);
                    formRef?.current?.setFieldValue('short_code', myData?.price);
                    formRef?.current?.setFieldValue('short_description', myData?.short_description);
                    formRef?.current?.setFieldValue('short_description_ar', myData?.short_description_ar);
                    formRef?.current?.setFieldValue('display_rank', myData?.display_rank);
                }
                const uomsData = myData.uoms;
                let addedRecipe = menuReciepes.map((record) => {
                    const unitsData = (record?.inventory?.inventory_unit_converisons || []).filter(item => item?.unit_ === "Usage Unit")[0];
                    const UOM = uomsData.filter(item => item.id === unitsData?.uom)[0];
                    // console.log("ramveer **", unitsData, record);
                    const obj = {
                        rawMaterial: record?.inventory_id,
                        usageUOM: UOM?.uom_code,
                        quantity: record?.quantity,
                        price: unitsData?.price * record?.quantity,
                        raw_material_price: unitsData?.price,
                        calories: unitsData?.calor,
                        orderMode: Array.isArray(record?.menu_add_on_reciepe_order_modes) ? record?.menu_add_on_reciepe_order_modes?.map((orderModeItem) => orderModeItem?.order_mode) : [],
                    }
                    return obj;
                });
                setAddOnDataList(addedRecipe);
            } catch (error) {
                console.log("error ====> ", error);
            }
        };
        fetchData();
    }, [isBrandChanged]);

    const onChangeQuantity = (quantity, values) => {
        formik.setFieldValue(
            'price', values.raw_material_price * quantity
        )
    }

    const isExpired = (createdAt, shelfLife) => {
        const createdDate = new Date(createdAt);
        const expirationDate = new Date(createdDate);
        expirationDate.setDate(createdDate.getDate() + parseInt(shelfLife, 10));
        return expirationDate < new Date();
    };

    return (
        <>
            <div className="page-header d-flex align-items-center">
                <div className="page-header-left">
                    <Breadcrums data={breadcrumbs} />
                    <h4>{searchParams.get("copy") ? "Add On" : formRef?.current?.values?.outlet_name}</h4>
                </div>
                <div className="page-header-right ms-auto">

                </div>
            </div>
            <Formik
                innerRef={formRef}
                initialValues={{
                    // outlet_name: showDefault?.name || "",
                    // outlet_name_ar: showDefault?.name_ar || "",
                    // brand_id: showDefault?.menu_add_on_category?.id || "",
                    // tax_id: showDefault?.tax_group_id || "",
                    // short_code: showDefault?.price || "",
                    // vat_information: '',
                    // email: '',
                    // short_description: showDefault?.short_description,
                    // short_description_ar: showDefault?.short_description_ar,
                    // location: '',
                    // country_id: '',
                    // state_id: '',
                    // city_id: '',
                    // area: '',

                    outlet_name: "",
                    outlet_name_ar: "",
                    brand_id: "",
                    tax_id: "",
                    short_code: "",
                    vat_information: '',
                    email: '',
                    short_description: '',
                    short_description_ar: '',
                    location: '',
                    country_id: '',
                    state_id: '',
                    city_id: '',
                    area: '',
                    display_rank: '',
                }}
                enableReinitialize={true}
                validationSchema={Yup.object().shape({
                    // short_code: Yup.number().integer("Price must be a integer").positive("Price must be a positive number.").required("Price is required"),
                })}
                validate={values => {
                    const error = {};


                    if (!values.outlet_name) {
                        error.outlet_name = "Name is required";
                    } else if (values.outlet_name.length > 60) {
                        error.outlet_name = "Name should not exceed 60 characters";
                    }

                    if (!values.outlet_name_ar) {
                        error.outlet_name_ar = "Name (Arabic) is required";
                    } else if (values.outlet_name_ar.length > 60) {
                        error.outlet_name_ar = "Name (Arabic) should not exceed 60 characters";
                    }

                    if (values.short_description && values?.short_description?.length > 250) {
                        error.short_description = 'Short description cannot exceed 250 characters.';
                    }
                    if (values.short_description_ar && values?.short_description_ar?.length > 250) {
                        error.short_description_ar = 'Short description arabic cannot exceed 250 characters.';
                    }



                    if (!values.brand_id) error.brand_id = "Category is required";
                    // if (!values.short_code) error.short_code = "Price is required";
                    if (values.short_code === undefined || values.short_code === null || values.short_code === '') {
                        error.short_code = "Price is required";
                    }
                    else if (isNaN(values.short_code)) {
                        error.short_code = "Price must be a valid number";
                    }
                    else if (values.short_code < 0) {
                        error.short_code = "Price must be a positive number";
                    }
                    else if (!Number.isInteger(Number(values.short_code))) {
                        error.short_code = "Price must be an integer and cannot be a decimal";
                    }
                    // console.log("error:", error);



                    if (page == 1) {
                        // if (error.outlet_name 
                        // ) {
                        //     setBtnDisable(true)
                        // } else {
                        //     setBtnDisable(false);
                        // }
                    }
                    return error;
                }}


                onSubmit={(values, { setSubmitting }) => {
                    let recipeDataToSend = addOnDataList.map((item) => {
                        return { inventory_id: item?.rawMaterial, order_mode_id: item?.orderMode, quantity: item?.quantity };
                    });
                    let mySetData = []
                    setSubmitted(true)
                    let formData = new FormData();
                    formData.append('name', values.outlet_name.trim());
                    formData.append('name_ar', values.outlet_name_ar.trim());
                    formData.append('add_on_category_id', JSON.stringify(values.brand_id));
                    formData.append('tax_group_id', values.tax_id);
                    formData.append('brand_id', getBrandId());
                    formData.append('price', values.short_code);
                    formData.append('vat_information', values.vat_information);
                    formData.append('email', values.email);
                    formData.append('short_description', values.short_description);
                    formData.append('short_description_ar', values.short_description_ar);
                    formData.append('display_rank', values.display_rank);
                    formData.append('id', params.id);

                    // if (addOnDataList?.length > 0) {
                    //     addOnDataList?.map((item) => {
                    //         if (item.rawMaterial != "") {
                    //             mySetData.push({
                    //                 inventory_id: item.rawMaterial.id,
                    //                 quantity: item.quantity,
                    //                 order_mode_id: item.orderMode
                    //             })
                    //         }
                    //     })
                    // }

                    // formData.append('reciepes', JSON.stringify(mySetData));
                    formData.append('reciepes', JSON.stringify(recipeDataToSend));
                    formData.append('company_id', getCompany().company_id);
                    if (!searchParams.get('copy')) {
                        EditAddOn(formData).then(response => {
                            setSubmitting(false);
                            setSubmitted(false);
                            if (response.data.status === 1 || response.data.status === "1") {
                                Swal.fire({
                                    customClass: 'swal-wide',
                                    icon: 'success',
                                    title: response.data.message,
                                    showConfirmButton: false,
                                    timer: 1500,
                                    toast: true,
                                    position: 'top-right',
                                });
                                setTimeout(() => {
                                    navigate(`/company/menu/menu/add_ons/list/${1}`)
                                }, 1000);
                            } else {
                                Swal.fire({
                                    customClass: 'swal-wide',
                                    icon: 'error',
                                    title: response.data.message,
                                    showConfirmButton: false,
                                    timer: 5000,
                                    toast: true,
                                    position: 'top-right',
                                })
                                setSubmitted(false)
                            }
                        }).catch(error => {
                            console.log("error ====> ", error);
                        })
                    } else {
                        CreateAddOn(formData).then(response => {
                            setSubmitting(false);
                            setSubmitted(false);
                            if (response.data.status === 1 || response.data.status === "1") {
                                Swal.fire({
                                    customClass: 'swal-wide',
                                    icon: 'success',
                                    title: response.data.message,
                                    showConfirmButton: false,
                                    timer: 1500,
                                    toast: true,
                                    position: 'top-right',
                                });
                                setTimeout(() => {
                                    navigate(`/company/menu/menu/add_ons/list/${1}`)
                                }, 1000);
                            } else {
                                Swal.fire({
                                    customClass: 'swal-wide',
                                    icon: 'error',
                                    title: response.data.message,
                                    showConfirmButton: false,
                                    timer: 5000,
                                    toast: true,
                                    position: 'top-right',
                                })
                                setSubmitted(false)
                            }
                        }).catch(error => {
                            console.log("error ====> ", error);
                        })
                    }
                }}


            >{({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
            }) => (
                <form onSubmit={handleSubmit}>
                    <div className='steps'>
                        <ul>
                            <li className={`step step-1 ${activeStep >= 1 ? 'active' : ''}`} style={{ cursor: 'default' }} onClick={() => handleStepChange(1)}>
                                <span>1</span><p>Add-On</p>
                            </li>
                            <li className={`step step-2 ${activeStep >= 2 ? 'active' : 'tag_disabled'}`} style={{ cursor: 'default' }} onClick={() => handleStepChange(2)}>
                                <span>2</span><p>Recipe</p>
                            </li>
                            <li className={`step step-3 ${activeStep >= 3 ? 'active' : 'tag_disabled'}`} style={{ cursor: 'default' }} onClick={() => handleStepChange(3)}>
                                <span>3</span><p>Settings</p>
                            </li>

                        </ul>
                    </div>
                    <div className="card custom-card">
                        <div className="card-body">
                            <div className='row row-sm'>
                                <div className="step-1" id="checkout-progress" data-current-step="1" style={{ display: 'none' }}>
                                    <div className="progress-bar">
                                        <div className="step step-1 active"><span> 1</span>
                                            <div className="fa fa-check opaque"></div>
                                            <div className="step-label"> Add-On</div>
                                        </div>
                                        <div className="step step-2"><span> 2</span>
                                            <div className="fa fa-check opaque"></div>
                                            <div className="step-label"> Recipe</div>
                                        </div>
                                        <div className="step step-3"><span> 3</span>
                                            <div className="fa fa-check opaque"></div>
                                            <div className="step-label">Settings</div>
                                        </div>

                                    </div>
                                </div>
                                <section id="section1" className="section1" style={{ display: 'block' }}>
                                    <div className='row'>
                                        <div className='col-lg-6 text-center form-group'>
                                            <label htmlFor='outlet_name' className='text-left d-flex'>Name <span className="requirestar">*</span></label>

                                            <input name='outlet_name' id="outlet_name" type='text' onChange={handleChange} onBlur={handleBlur} value={values.outlet_name} className='form-control' autoComplete='off' placeholder='Enter Name' />
                                            <span className='text-danger d-flex text-left'>{errors.outlet_name && touched.outlet_name && errors.outlet_name}</span>
                                        </div>
                                        <div className='col-lg-6 text-center form-group'>
                                            <label htmlFor='outlet_name_ar' className='text-left d-flex'>Name (arabic) <span className="requirestar">*</span></label>
                                            <input name='outlet_name_ar' id="outlet_name_ar" type='text' onChange={handleChange} onBlur={handleBlur} value={values.outlet_name_ar} className='form-control' autoComplete='off' placeholder='Enter Name in Arabic' />
                                            <span className='text-danger d-flex text-left'>{errors.outlet_name_ar && touched.outlet_name_ar && errors.outlet_name_ar}</span>
                                        </div>
                                        <div className='col-lg-6 text-center form-group mt-3'>
                                            <label htmlFor='brand_id' className='text-left d-flex'>
                                                Category: <span className="requirestar">*</span>
                                            </label>
                                            <Dropdown

                                                className='form-control'
                                                id="brand_id"
                                                placeholder='Select category'
                                                search
                                                fluid
                                                selection
                                                options={brandOptions}
                                                value={values.brand_id}
                                                onChange={(event, data) => setFieldValue("brand_id", data.value)}
                                            />
                                            <span className='text-danger d-flex text-left'>
                                                {errors.brand_id && touched.brand_id && errors.brand_id}
                                            </span>
                                        </div>
                                        <div className='col-lg-6 text-center form-group mt-3'>
                                            <label htmlFor='short_code' className='text-left d-flex'>Price:<span className="requirestar">*</span></label>
                                            <input name='short_code' id="short_code" type='number' onChange={handleChange} onBlur={handleBlur} value={values.short_code} className='form-control' autoComplete='off' placeholder='Enter Price' />
                                            <span className='text-danger d-flex text-left'>{errors.short_code && touched.short_code && errors.short_code}</span>

                                        </div>
                                        <div className='col-lg-6 text-center form-group mt-3'>
                                            <label htmlFor='tax_id' className='d-flex'>Add on display rank :</label>
                                            <input id="display_rank" type='number' className='form-control' name='display_rank' onChange={handleChange} onBlur={handleBlur} value={values.display_rank} />
                                            <span className='text-danger d-flex'>{errors.display_rank && touched.display_rank && errors.display_rank}</span>
                                        </div>
                                        <div className='col-lg-6 text-center form-group mt-3'></div>

                                        <div className='col-lg-6 text-center form-group mt-3'>
                                            <label htmlFor='short_description' className='text-left d-flex'>Short description : </label>
                                            <textarea name='short_description' id="short_description" rows="2" type='text' onChange={handleChange} onBlur={handleBlur} value={values.short_description} className='form-control' />
                                            <span className='text-danger d-flex text-left'>{errors.short_description && touched.short_description && errors.short_description}</span>
                                        </div>
                                        <div className='col-lg-6 text-center form-group mt-3'>
                                            <label htmlFor='short_description_ar' className='text-left d-flex'>Short description (arabic) : </label>
                                            <textarea name='short_description_ar' id="short_description_ar" rows="2" type='text' onChange={handleChange} onBlur={handleBlur} value={values.short_description_ar} className='form-control' />
                                            <span className='text-danger d-flex text-left'>{errors.short_description_ar && touched.short_description_ar && errors.short_description_ar}</span>
                                        </div>
                                        <div className='row'>

                                        </div>
                                    </div>
                                </section>

                                <section id="section2" className="section2  " style={{ display: 'none' }}>
                                    <div className="tabler-wrap">
                                        <h2>Recipe List</h2>
                                        <table className="table table-custom">
                                            <thead>
                                                <tr>
                                                    <th>Raw Material</th>
                                                    <th>Usage UOM</th>
                                                    <th>Quantity</th>
                                                    <th>Price</th>
                                                    <th>Calories</th>
                                                    <th>Order Mode</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            {/* <tbody>
                                                {
                                                }
                                                {addOnDataList?.length > 0 && addOnDataList.map((recipe, index) => (
                                                    recipe.rawMaterial != "" &&
                                                    <tr key={index}>
                                                        <td>{recipe.rawMaterial.name}</td>
                                                        <td>{recipe.usageUOM}</td>
                                                        <td>{recipe.quantity}</td>
                                                        <td>{recipe.price}</td>
                                                        <td>{recipe.calories}</td>
                                                        <td>
                                                            {
                                                                recipe.orderMode.map((orderMode, index) => {
                                                                    const order = orderList.find((order) => order.key === orderMode);
                                                                    return order?.text;
                                                                }).filter(Boolean).join(', ')
                                                            }
                                                        </td>
                                                        <td>
                                                            <button className='btn btn-danger' type='button' onClick={(e) => handleRemoveItem(index)}>Delete</button>
                                                            <button className='btn btn-outline-primary ms-2' type='button' onClick={(e) => handleEditItem(index)}>Edit</button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody> */}
                                            <tbody>
                                                {addOnDataList && addOnDataList?.length ? (
                                                    addOnDataList?.map((recipe, index) => (
                                                        <tr key={index}>
                                                            <td>{capitalizeAllFirstLetters(getRecordFromList(inventoryList, recipe?.rawMaterial)?.item_name)}</td>
                                                            <td>{recipe?.usageUOM}</td>
                                                            <td>{recipe?.quantity}</td>
                                                            <td>{recipe?.price}</td>
                                                            <td>{recipe?.calories}</td>
                                                            {/* <td>{capitalizeAllFirstLetters(getRecordFromList(orderList, recipe?.orderMode)?.name)}</td> */}
                                                            <td>
                                                                {/* {
                                  recipe?.orderMode?.map((item, i)=>{
                                    return <span key={i}>{capitalizeAllFirstLetters(SpecialCharacter(getRecordFromList(orderList, item)?.name))}{i<(recipe?.orderMode?.length -1) ? ", ":""}</span>
                                  })
                                } */}
                                                                {
                                                                    recipe?.orderMode?.map((item, i) => {
                                                                        return <span key={i}>{capitalizeAllFirstLetters(SpecialCharacter(getRecordFromList(orderList, item)?.name))}{i < (recipe?.orderMode?.length - 1) ? ", " : ""}</span>
                                                                    })
                                                                }

                                                            </td>
                                                            <td className='d-flex'>
                                                                <button type='button' className='btn btn-primary me-3 mt-2' onClick={() => handleEditItem(index)}>Edit</button>
                                                                <button type='button' className='btn btn-danger mt-2' onClick={() => handleRemoveItem(index)}>Remove</button>
                                                            </td>
                                                        </tr>
                                                    ))
                                                ) : (
                                                    <tr>
                                                        <td colSpan="7" className="text-center">No recipes found</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>



                                    </div>
                                    <button className="btn btn-primary btn-lg" type="button" onClick={() => setShow(true)} style={{ marginTop: '10px' }}>
                                        Add New Recipe
                                    </button>
                                </section>
                                <section id="section3" className="section3" style={{ display: 'none' }}>

                                    <div className='row'>
                                        <div className='col-lg-8 form-group' style={{ width: '50%' }}>
                                            <div className='form-group mt-3'>
                                                <label htmlFor='tax_id' className='d-flex'>Tax Group :</label>
                                                <select id="tax_id" className='form-control' name='tax_id' onChange={handleChange} onBlur={handleBlur} value={values.tax_id}>

                                                    <option value="">Select Tax Group</option>
                                                    {taxList?.length ? taxList?.map((row) => (
                                                        <option key={row.id} value={row.id}>{row?.name}</option>
                                                    )) : ''}
                                                </select>
                                                <span className='text-danger d-flex'>{errors.tax_id && touched.tax_id && errors.tax_id}</span>
                                            </div>
                                        </div>
                                    </div>

                                </section>
                                <div className="button-container mt-4 p-10">
                                    <button className="btn btn-outline-primary btn-prev" type="button" onClick={prevStep} style={{ display: 'none' }}>Back</button>
                                    <button className="btn btn-next btn-primary ms-2" type="button" onClick={nextStep} > Next</button>
                                    <button className="btn btn-primary ms-2 btn-submit" onClick={() => { setBtnClicked(true) }} type="submit" style={{ display: 'none' }}>Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            )}
            </Formik>

            <ToastContainer
                position="top-right"
                hideProgressBar={false}
                autoClose={true | 1000}
                newestOnTop={true}
                closeOnClick={false}
                draggable={false}
                rtl={false}
            />
            {/* <Modal show={show} onHide={handleClose} >
                <Modal.Header closeButton>
                    <Modal.Title>Add New Recipe</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={(e) => saveData(e)}>

                        <div className='col-lg-6 text-center form-group mb-3 w-100' controlId="rawMaterial">
                            <label className='text-left d-flex'>Raw Material</label>
                            <select as="select" className='form-control' name="rawMaterial" value={rawList.id} onChange={handleChangeModel} >
                                <option value="">Select Raw Material</option>
                                {inventoryList.map((order) => (
                                    <option key={order.id} value={order.id}>
                                        {order.item_name}
                                    </option>
                                ))}

                            </select>
                        </div>
                        <Form.Group className="mb-3" controlId="usageUOM">
                            <Form.Label>Unit of Measure</Form.Label>
                            <Form.Control disabled type="text" name="usageUOM" placeholder="Enter Unit of Measure" value={formData.usageUOM} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="quantity">
                            <Form.Label>Quantity</Form.Label>
                            <Form.Control type="number" name="quantity" placeholder="Enter Quantity" value={formData.quantity} onChange={(e) => { onChangeQuantity(e.target.value) }} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="price">
                            <Form.Label>Price</Form.Label>
                            <Form.Control disabled type="number" name="price" placeholder="Enter Price" value={formData.price} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="calories">
                            <Form.Label>Calories</Form.Label>
                            <Form.Control disabled type="number" name="calories" placeholder="Enter Calories" value={formData.calories} />
                        </Form.Group>

                        <div className='col-lg-6 text-center form-group mb-3 w-100' controlId="orderMode">
                            <label className='text-left d-flex'>Order Mode</label>
                            <Dropdown
                                multiple
                                className='form-control'
                                id="orderMode"
                                placeholder='Select Order Mode'
                                search
                                fluid
                                selection
                                options={orderList}
                                value={orderMode}
                                onChange={(event, data) => { orderChange(data.value) }}
                            />
                        </div>

                        <Button variant="primary" onClick={resetForm} style={{ marginRight: '10px' }}> Cancel</Button>
                        <Button variant="primary" type='submit' disabled={formData.orderMode == '' || formData.calories == '' || formData.price == '' || formData.quantity == '' || formData.usageUOM == "" || formData.rawMaterial == ""}>
                            {editIndex == -1 ? 'Add Recipe' : 'Edit Reciepe'}
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal> */}
            <Modal show={show} onHide={handleClose} backdrop={'static'} >
                <Modal.Header closeButton>
                    <Modal.Title>{recipeEditableIndex === null ? "Add" : "Edit"} New Recipe</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* <Form onSubmit={(e) => saveData(e)}>
                     */}
                    <div>
                        <form onSubmit={formik.handleSubmit}>

                            <div className='col-lg-6 text-center form-group mb-3 w-100' controlId="rawMaterial">
                                <label className='text-left d-flex'>Raw Material</label>
                                <select as="select" className='form-control' name="rawMaterial" onBlur={formik?.handleBlur} value={formik?.values?.rawMaterial} onChange={handleChangeModel} >
                                    <option value="">Select Raw Material</option>
                                    {inventoryList.map((order) => {
                                        const isDisabled = addOnDataList.some(item => item.rawMaterial && Number(item.rawMaterial) === order.id);
                                        return (
                                            <option key={order.id} value={order.id} disabled={isDisabled}>
                                                {capitalizeAllFirstLetters(order.item_name)}
                                                {isExpired(order.created_at, order.self_life) && ' (Expired)'}
                                            </option>
                                        );
                                    })}

                                </select>
                            </div>
                            <Form.Group className="mb-3" controlId="usageUOM">
                                <Form.Label>Unit of Measure</Form.Label>
                                <Form.Control disabled type="text" name="usageUOM" placeholder="Enter Unit of Measure" value={formik?.values?.usageUOM} onChange={formik?.handleChange} />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="quantity">
                                <Form.Label>Quantity</Form.Label>
                                <Form.Control type="number" name="quantity" onBlur={formik?.handleBlur} placeholder="Enter Quantity" value={formik?.values?.quantity} onChange={(event) => { formik?.handleChange(event); onChangeQuantity(event.target.value, formik.values) }} />
                                <span className="text-danger d-flex text-left">{formik.errors.quantity && formik.touched.quantity && formik.errors.quantity} </span>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="price">
                                <Form.Label>Price</Form.Label>
                                <Form.Control disabled type="number" name="price" placeholder="Enter Price" value={formatToTwoDecimals(formik?.values?.price)} onChange={formik?.handleChange} />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="calories">
                                <Form.Label>Calories</Form.Label>
                                <Form.Control disabled type="number" name="calories" placeholder="Enter Calories" value={formatToTwoDecimals(formik?.values?.calories)} onChange={formik?.handleChange} />
                            </Form.Group>

                            <div className='col-lg-6 text-center form-group mb-3 w-100' controlId="orderMode">
                                <label className='text-left d-flex'>Order Mode</label>
                                <CustomMultiSelect data={orderList.map((item) => {
                                    return {
                                        label: capitalizeFirstLetter(SpecialCharacter(item.name)),
                                        value: item.id
                                    }
                                })} form={formik} name='orderMode' overFlowVisibleOptions={1} menuClassName="popup-select" />
                            </div>

                            <Button variant="primary" onClick={resetForm} style={{ marginRight: '10px' }}> Cancel</Button>
                            {/* <Button variant="primary" type='submit' disabled={formData.orderMode == '' || formData.calories == '' || formData.price == '' || formData.quantity == '' || formData.usageUOM == "" || formData.rawMaterial == ""}>
                            {recipeEditableIndex === null ? "Add" : "Edit"} Recipe
                        </Button> */}
                            <Button variant="primary" type='submit' disabled={!formik.dirty || !formik.isValid}>
                                {recipeEditableIndex === null ? "Add" : "Update"} Recipe
                            </Button>
                            {/* </Form> */}
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default AddOnsEdit
