import React, { useState, useEffect } from 'react'
import Layout from '../../layout'
import { useLocation, useNavigate, useParams, Link } from 'react-router-dom'
import { ChangeOnlineOrderApiActiveStatus, ChangeOnlineOrderApiCredentialName, ChangeOnlineOrderApiTokenExpiryStatus, DeleteOnlineOrderApiCreds, ERPConfigurationDataSaved, ERPDetails, GenerateOnlineOrderApiCreds, GetChangeStatus, GetCompanyList, GetDeleteCompany, OnlineOrderChangeApiWorkingStatus, RegenerateOnlineOrderApiCreds } from '../../services/companyService'
import Swal from 'sweetalert2'
import CustomPagination from '../../components/common/custompagination'
import { capitalizeAllFirstLetters, capitalizeFirstLetter, formateDate, getCompany, showLimitedElements, TrimText } from '../../helpers/commonFunctions';
import Sorting from '../../components/common/sorting';
import Loader from '../../components/common/loader'
import Breadcrums from '../../components/common/breadcrumbs'
import StatusFilter from '../../components/common/statusFilter'
import { showFilterlist } from '../../utils/Constants'
import CustomRangepicker from '../../components/common/rangepicker'
import $ from "jquery";
import { Modal, Button, Tabs, Tab } from 'react-bootstrap';
import { Table, Tooltip, Whisper } from 'rsuite'
import { compareAsc } from 'date-fns'
import { jwtDecode } from "jwt-decode";

const CompanyTable = () => {

    const breadcrumbs = [{ title: "Dashboard", url: "/admin/dashboard" }, { title: "Companies", url: "" },]
    const navigate = useNavigate()
    const params = useParams()
    const location = useLocation()
    const [list, setList] = useState([])
    const [statsUpdate, setStatusUpdate] = useState("false")
    const [dataLength, setDataLength] = useState()
    const [itemPerPage, setItemPerPage] = useState(10)
    const [sorting, setSorting] = useState({});
    const [defaultSorting, setDefaultSorting] = useState(true);
    const [page, setPage] = useState(1);
    const [loader, setLoader] = useState(true)
    const [search, setSearch] = useState({})
    const [globalSearch, setGlobalSearch] = useState("")
    const [resetdate, setResetDate] = useState(false)
    const [showOnlineOrderApiCredsModal, setShowOnlineOrderApiCredsModal] = useState(false);
    const [onlineOrderApiCredsData, setOnlineOrderApiCredsData] = useState(null);
    const [showOnlineOrderApiAccessibilityModal, setShowOnlineOrderApiAccessibilityModal] = useState(false);
    const [apiAccessibility, setApiAccessibility] = useState([]);

    const [companyID, setCompanyID] = useState({});
    const [showERPConfigurationModel, setShowERPConfigurationModel] = useState(false);


    useEffect(() => {
        if (search) {
            setLoader(true)
            const formData = new FormData()
            formData.append("page", params.id)
            formData.append("per_page", itemPerPage)
            formData.append("sort", JSON.stringify(sorting))
            formData.append("search", JSON.stringify(search))
            formData.append("global_search", globalSearch)
            GetCompanyList(formData).then(data => {
                setDataLength(data.data.response.count);
                setList(data && data.data && data.data.response ? data.data.response.rows : [])

                setLoader(false)
            }).catch(error => {
                console.log("error ====> ", error);
            })
        }
    }, [location, statsUpdate, sorting, search, globalSearch])

    const handleSort = (e, column) => {
        setDefaultSorting(false);
        let sort = { order: 0, column: column };
        if (e.target.classList.contains("assc")) {
            sort.order = -1;
        } else {
            sort.order = 1;
        }
        setSorting(sort);
    }

    // search or filter function
    const prepareSearch = (key, value) => {
        let sr = { ...search };
        if (String(value).length > 0) {
            sr[key] = value;
        } else {
            delete sr[key];
        }
        setSearch(sr);
    }
    // search or filer end


    const resetFilter = (e) => {
        e.preventDefault()
        setGlobalSearch("")
        setSearch({})
        // prepareSearch()
        setResetDate(!resetdate)
        $("#defaultstatus")[0].selectedIndex = 0
    }

    // Delete functionality
    function deleteFunction(row) {
        Swal.fire({
            customClass: 'swal-wide',
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#403fad',
            cancelButtonColor: '#f1388b',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                const formdata = new FormData()
                formdata.append("id", row)
                GetDeleteCompany(formdata).then(data => {
                    if (data.status === 200) {
                        Swal.fire({
                            customClass: 'swal-wide',
                            position: 'absolute',
                            icon: 'success',
                            title: 'Company deleted successfully',
                            showConfirmButton: false,
                            timer: 2000,
                            toast: true,
                            position: 'top-right',
                        })
                        setStatusUpdate(!statsUpdate)
                    }
                }).catch(error => {
                    console.log("deleteError")
                })
            }
        })
    }

    // Change Status functionality

    const ChangeStatus = (row, Num) => {
        const formData = new FormData()
        formData.append("id", row)
        formData.append("status", Num)
        Swal.fire({
            customClass: 'swal-wide',
            title: 'Are you sure?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#403fad',
            cancelButtonColor: '#f1388b',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                GetChangeStatus(formData).then(data => {
                    if (data.status === 200) {
                        Swal.fire({
                            customClass: 'swal-wide',
                            position: 'absolute',
                            icon: 'success',
                            title: data.data.message,
                            showConfirmButton: false,
                            timer: 2000,
                            toast: true,
                            position: 'top-right',
                        })
                        setStatusUpdate(!statsUpdate)
                    }
                }).catch(error => {
                    console.log("error===>")
                })
            }
        })
    }

    // view details functionality

    const viewFunction = (row) => {
        navigate(`/admin/company/view/${row}`)
    }

    // edit functionality

    const editFunction = (row) => {
        navigate(`/admin/company/${params.id}/edit/${row.id}`)
    }

    const openOnlineOrderApiCredsModel = (row) => {
        setShowOnlineOrderApiCredsModal(true);

        let credentialsList = row?.online_order_apis_credentials;
        credentialsList = credentialsList?.map(credentials => {
            let date = jwtDecode(credentials?.access_token)['exp'] * 1000;
            date = new Date(date);
            date = date.toDateString();
            return {
                ...credentials,
                jwt_expiry_date: date
            }
        })

        setOnlineOrderApiCredsData({
            company_id: row?.id,
            company_name: row.name,
            credentials_list: credentialsList,
        });
        // GetBrandsWithAggregators({ company_id: row?.id })
        // .then((response) => {
        //     if (response.status === 200) {
        //         console.log(response.data.response);
        //         let brandList = response?.data?.response;

        //         brandList = brandList?.map(brand =>{
        //             brand.aggregators_configurations = brand?.aggregators_configurations?.map(aggregator =>{
        //                 let date;
        //                 if(aggregator?.online_order_apis_access_token){
        //                     date = jwtDecode(aggregator?.online_order_apis_access_token)['exp'] * 1000;
        //                     date = new Date(date);
        //                     date = date.toDateString();
        //                 }
        //                 return {
        //                     ...aggregator,
        //                     jwt_expiry_date : date
        //                 }
        //             })
        //             return brand;            
        //         })
        //         setOnlineOrderApiCredsData({ 
        //             company_id : row?.id, 
        //             brandList
        //         });
        //     }
        // })
        // .catch((error) => {
        //     Swal.fire({
        //         customClass: 'swal-wide',
        //         position: 'absolute',
        //         icon: 'success',
        //         title: error.message,
        //         showConfirmButton: false,
        //         timer: 2000,
        //         toast: true,
        //         position: 'top-right',
        //     })
        // })

    }
    const closeOnlineOrderApiCredsModel = (row) => {
        setShowOnlineOrderApiCredsModal(false);
        setOnlineOrderApiCredsData(null);
        setStatusUpdate(!statsUpdate);
    }

    const generateOnlineOrderApiCreds = () => {
        GenerateOnlineOrderApiCreds({ company_id: onlineOrderApiCredsData?.company_id })
            .then((response) => {
                if (response.status === 200) {
                    Swal.fire({
                        customClass: 'swal-wide',
                        position: 'absolute',
                        icon: 'success',
                        title: response?.data?.message,
                        showConfirmButton: false,
                        timer: 2000,
                        toast: true,
                        position: 'top-right',
                    })

                    const credentialsGenerated = response?.data?.response;
                    let date = jwtDecode(credentialsGenerated?.access_token)['exp'] * 1000;
                    date = new Date(date);
                    date = date.toDateString();
                    credentialsGenerated.jwt_expiry_date = date;
                    const data = { ...onlineOrderApiCredsData };
                    if (!data.credentials_list) {
                        data.credentials_list = [credentialsGenerated]
                    } else {
                        data.credentials_list.push(credentialsGenerated);
                    }
                    setOnlineOrderApiCredsData(data);
                    setStatusUpdate(!statsUpdate);
                }
            })
            .catch((error) => {
                Swal.fire({
                    customClass: 'swal-wide',
                    position: 'absolute',
                    icon: 'success',
                    title: error.response.data.errors.message,
                    showConfirmButton: false,
                    timer: 2000,
                    toast: true,
                    position: 'top-right',
                })
            })
    }
    const regenerateOnlineOrderApiCreds = (credential_id) => {
        RegenerateOnlineOrderApiCreds({ company_id: onlineOrderApiCredsData?.company_id, credential_id })
            .then((response) => {
                if (response.status === 200) {
                    Swal.fire({
                        customClass: 'swal-wide',
                        position: 'absolute',
                        icon: 'success',
                        title: response?.data?.message,
                        showConfirmButton: false,
                        timer: 2000,
                        toast: true,
                        position: 'top-right',
                    })
                    // closeOnlineOrderApiCredsModel();
                    // setStatusUpdate(!statsUpdate)

                    const credentialsRegenerated = response?.data?.response;
                    let date = jwtDecode(credentialsRegenerated?.access_token)['exp'] * 1000;
                    date = new Date(date);
                    date = date.toDateString();

                    const data = { ...onlineOrderApiCredsData };
                    data.credentials_list = data.credentials_list.map(credentials => {
                        return credentials.credential_id != credential_id
                            ? credentials
                            : {
                                ...credentials,
                                access_token: credentialsRegenerated.access_token,
                                secret_key: credentialsRegenerated.secret_key,
                                jwt_expiry_date: date
                            }
                    });
                    setOnlineOrderApiCredsData(data);
                    setStatusUpdate(!statsUpdate);
                }
            })
            .catch((error) => {
                Swal.fire({
                    customClass: 'swal-wide',
                    position: 'absolute',
                    icon: 'success',
                    title: error.response.data.errors.message,
                    showConfirmButton: false,
                    timer: 2000,
                    toast: true,
                    position: 'top-right',
                })
            })
    }
    const deleteOnlineOrderApiCreds = (credential_id) => {
        DeleteOnlineOrderApiCreds({ company_id: onlineOrderApiCredsData?.company_id, credential_id })
            .then((response) => {
                if (response.status === 200) {
                    Swal.fire({
                        customClass: 'swal-wide',
                        position: 'absolute',
                        icon: 'success',
                        title: response?.data?.message,
                        showConfirmButton: false,
                        timer: 2000,
                        toast: true,
                        position: 'top-right',
                    })
                    // closeOnlineOrderApiCredsModel();
                    // setStatusUpdate(!statsUpdate)

                    const data = { ...onlineOrderApiCredsData };
                    data.credentials_list = data?.credentials_list?.filter(credentials => credentials.credential_id != credential_id);
                    setOnlineOrderApiCredsData(data);
                    setStatusUpdate(!statsUpdate);
                }
            })
            .catch((error) => {
                Swal.fire({
                    customClass: 'swal-wide',
                    position: 'absolute',
                    icon: 'success',
                    title: error.response.data.errors.message,
                    showConfirmButton: false,
                    timer: 2000,
                    toast: true,
                    position: 'top-right',
                })
            })
    }
    const changeOnlineOrderApiTokenExpiryStatus = (credential_id, checked) => {

        ChangeOnlineOrderApiTokenExpiryStatus({
            company_id: onlineOrderApiCredsData?.company_id,
            credential_id: credential_id,
            status: checked
        }).then(response => {
            Swal.fire({
                customClass: 'swal-wide',
                icon: 'success',
                title: response?.data?.message,
                showConfirmButton: false,
                timer: 2000,
                toast: true,
                position: 'top-right',
            });

            const data = { ...onlineOrderApiCredsData };
            data.credentials_list = data.credentials_list.map(credentials => {
                return credentials.credential_id != credential_id
                    ? credentials
                    : {
                        ...credentials,
                        token_expiry_enabled: checked ? true : false
                    }
            });
            setOnlineOrderApiCredsData(data);
            setStatusUpdate(!statsUpdate);
        }).catch((error) => {
            Swal.fire({
                customClass: 'swal-wide',
                position: 'absolute',
                icon: 'success',
                title: error.response.data.errors.message,
                showConfirmButton: false,
                timer: 2000,
                toast: true,
                position: 'top-right',
            })
        })
    }
    const changeOnlineOrderApiActiveStatus = (credential_id, checked) => {

        ChangeOnlineOrderApiActiveStatus({
            company_id: onlineOrderApiCredsData?.company_id,
            credential_id: credential_id,
            status: checked
        }).then(response => {
            Swal.fire({
                customClass: 'swal-wide',
                icon: 'success',
                title: response?.data?.message,
                showConfirmButton: false,
                timer: 2000,
                toast: true,
                position: 'top-right',
            });

            const data = { ...onlineOrderApiCredsData };
            data.credentials_list = data.credentials_list.map(credentials => {
                return credentials.credential_id != credential_id
                    ? credentials
                    : {
                        ...credentials,
                        active: checked ? true : false
                    }
            });
            setOnlineOrderApiCredsData(data);
            setStatusUpdate(!statsUpdate);
        }).catch((error) => {
            Swal.fire({
                customClass: 'swal-wide',
                position: 'absolute',
                icon: 'success',
                title: error.response.data.errors.message,
                showConfirmButton: false,
                timer: 2000,
                toast: true,
                position: 'top-right',
            })
        })
    }
    const changeOnlineOrderApiCredentialName = (credential_id, credential_name) => {

        ChangeOnlineOrderApiCredentialName({
            company_id: onlineOrderApiCredsData?.company_id,
            credential_id: credential_id,
            credential_name: credential_name
        }).then(response => {
            Swal.fire({
                customClass: 'swal-wide',
                icon: 'success',
                title: response?.data?.message,
                showConfirmButton: false,
                timer: 2000,
                toast: true,
                position: 'top-right',
            });

            const data = { ...onlineOrderApiCredsData };
            data.credentials_list = data.credentials_list.map(credentials => {
                return credentials.credential_id != credential_id
                    ? credentials
                    : {
                        ...credentials,
                        credential_name: credential_name
                    }
            });
            setOnlineOrderApiCredsData(data);
            setStatusUpdate(!statsUpdate);
        }).catch((error) => {
            Swal.fire({
                customClass: 'swal-wide',
                position: 'absolute',
                icon: 'success',
                title: error.response.data.errors.message,
                showConfirmButton: false,
                timer: 2000,
                toast: true,
                position: 'top-right',
            })
        })
    }

    function CopytoClipboard(text) {
        // Copy the text inside the text field
        navigator?.clipboard?.writeText(text);
        Swal.fire({
            customClass: 'swal-wide',
            icon: 'success',
            title: 'Copied to clipboard',
            showConfirmButton: false,
            timer: 2000,
            toast: true,
            position: 'top-right',
        });
    }

    const openOnlineOrderApiAccessibilityModel = (index) => {
        setShowOnlineOrderApiCredsModal(false);
        setApiAccessibility({
            credential_id: onlineOrderApiCredsData?.credentials_list[index]?.credential_id,
            credential_index: index
        });
        setShowOnlineOrderApiAccessibilityModal(true);
    }
    const closeOnlineOrderApiAccessibilityModel = () => {
        setShowOnlineOrderApiAccessibilityModal(false);
        setApiAccessibility(null);
        setShowOnlineOrderApiCredsModal(true);
    }

    const isAuthenticatedStatusChange = (api) => {
        const formData = new FormData();
        formData.append("company_id", onlineOrderApiCredsData.company_id);
        formData.append("credential_id", apiAccessibility?.credential_id);
        formData.append("api_slug", api.api_slug);
        formData.append("permission_allowed", !api.permission_allowed);
        OnlineOrderChangeApiWorkingStatus(formData)
            .then(data => {
                if (data.status === 200) {
                    Swal.fire({
                        customClass: 'swal-wide',
                        icon: 'success',
                        title: data.data.message,
                        showConfirmButton: false,
                        timer: 2000,
                        toast: true,
                        position: 'top-right',
                    });
                    let online_order_api_creds_data = { ...onlineOrderApiCredsData };
                    let credentials_list = online_order_api_creds_data.credentials_list;
                    credentials_list = credentials_list?.map(credentials => {
                        if (credentials.credential_id == apiAccessibility.credential_id) {
                            credentials.apis_accessibility = credentials?.apis_accessibility.map(apis_accessibility => {
                                return apis_accessibility.api_slug != api.api_slug
                                    ? apis_accessibility
                                    : { ...apis_accessibility, permission_allowed: !api.permission_allowed }
                            })
                        }
                        return credentials
                    })
                    online_order_api_creds_data.credentials_list = credentials_list;
                    setOnlineOrderApiCredsData(online_order_api_creds_data);
                    // setStatusUpdate(!statsUpdate);
                }
            })
            .catch(error => {
                console.log("Error while changing status", error);
            });
    };

    const [showDefault,setShowDefault] = useState([]);



    const openERPConfigurationModel = (row) => {
        setShowERPConfigurationModel(true);
        setCompanyID(row?.id)
        const formData = new FormData()
        formData.append("company_id", row.company_id)
        ERPDetails(formData).then(data => {
            setShowDefault(data && data.data && data.data.response ? data.data.response : [])
        }).catch(error => {
            console.log("error=====>", error)
        })
    }
    
    const [activeTab, setActiveTab] = useState("sales");

    useEffect(() => {
        if (showDefault?.sales_api_configuration ||showDefault?.payment_api_configuration || showDefault?.commissions_api_configuration ) {
            setFormData((prevFormData) => ({
                ...prevFormData,
                salesSelectERP: showDefault.sales_api_configuration.erp || "",
                salesDataAreaID: showDefault.sales_api_configuration.dataAreaID || "",
                salesMainAccountNumber1: showDefault.sales_api_configuration.mainAccountNumber1 || "",
                salesMainAccountNumber2: showDefault.sales_api_configuration.mainAccountNumber2 || "",
                SalesCurrencyCode: showDefault.sales_api_configuration.currencyCode || "",
                paymentDataAreaID:  showDefault.payment_api_configuration.dataAreaID || "",
                paymentJournalName: showDefault.payment_api_configuration.journalName || "",
                commissionsDataAreaID:  showDefault.commissions_api_configuration.dataAreaID || "",
                commissionsJournalName: showDefault.commissions_api_configuration.journalName || ""
            }));
        }
    }, [showDefault]);

    // Initial state now leaves salesSelectERP as an empty string.
    const [formData, setFormData] = useState({
        salesSelectERP: showDefault?.sales_api_configuration?.erp,
        salesDataAreaID: "",
        salesMainAccountNumber1: "",
        salesMainAccountNumber2: "",
        SalesCurrencyCode: "",
        paymentDataAreaID: "",
        paymentJournalName: "",
        commissionsDataAreaID: "",
        commissionsJournalName: ""
    });
    // Update validation to require a selection for sales tab.
    const validateForm = () => {
        let newErrors = {};
        if (activeTab === "sales") {
            if (!formData.salesSelectERP)
                newErrors.salesSelectERP = "This field is required";
            if (formData.salesSelectERP && !formData.salesDataAreaID.trim())
                newErrors.salesDataAreaID = "Data Area ID is required";
            if (formData.salesSelectERP && !formData.salesMainAccountNumber1)
                newErrors.salesMainAccountNumber1 = "Main Account Number 1 is required";
            if (formData.salesSelectERP && !formData.salesMainAccountNumber2)
                newErrors.salesMainAccountNumber2 = "Main Account Number 2 is required";
            if (formData.salesSelectERP && !formData.SalesCurrencyCode.trim())
                newErrors.SalesCurrencyCode = "Currency Code is required";
        } else if (activeTab === "payment") {
            if (!formData.paymentDataAreaID.trim())
                newErrors.paymentDataAreaID = "Data Area ID is required";
            if (!formData.paymentJournalName.trim())
                newErrors.paymentJournalName = "Journal Name is required";
        } else if (activeTab === "commissions") {
            if (!formData.commissionsDataAreaID.trim())
                newErrors.commissionsDataAreaID = "Data Area ID is required";
            if (!formData.commissionsJournalName.trim())
                newErrors.commissionsJournalName = "Journal Name is required";
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        const updatedValue = type === "checkbox" ? checked : value;

        setFormData((prevData) => ({
            ...prevData,
            [name]: updatedValue,
        }));

        // Clear error for the field as the user types
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: "",
        }));
    };
    const handleCloseModal = () => {
        setFormData({
            salesSelectERP: "",
            salesDataAreaID: "",
            salesMainAccountNumber1: "",
            salesMainAccountNumber2: "",
            SalesCurrencyCode: "",
            paymentDataAreaID: "",
            paymentJournalName: "",
            commissionsDataAreaID: "",
            commissionsJournalName: ""
        });
        setErrors({});
        setShowERPConfigurationModel(false);
    };
    const handleERPConfigurationDataSaved = () => {
        if (!validateForm()) return;

        const payload = new FormData();

        // Build the sales API configuration payload with new fields
        const salesConfig = {
            erp: formData.salesSelectERP,
            dataAreaID: formData.salesDataAreaID.trim(),
            mainAccountNumber1: formData.salesMainAccountNumber1,
            mainAccountNumber2: formData.salesMainAccountNumber2,
            currencyCode: formData.SalesCurrencyCode.trim(),
        };

        payload.append("sales_api_configuration", JSON.stringify(salesConfig));
        payload.append("payment_api_configuration", JSON.stringify({
            dataAreaID: formData.paymentDataAreaID.trim(),
            journalName: formData.paymentJournalName.trim()
        }));
        payload.append("commissions_api_configuration", JSON.stringify({
            dataAreaID: formData.commissionsDataAreaID.trim(),
            journalName: formData.commissionsJournalName.trim()
        }));
        payload.append("company_id", companyID);

        ERPConfigurationDataSaved(payload)
            .then(data => {
                if (data.status === 200) {
                    Swal.fire({
                        customClass: 'swal-wide',
                        icon: 'success',
                        title: 'ERP configuration saved successfully',
                        showConfirmButton: false,
                        timer: 2000,
                        toast: true,
                        position: 'top-right',
                    });
                }
                handleCloseModal();
            })
            .catch(error => {
                console.log("Error while saving configuration", error);
            });
    };


    return (
        <Layout>
            <Breadcrums data={breadcrumbs} />
            <div className="animation_fade">
                <div className="card custom-card overflow-hidden">
                    <div className="card-body">
                        <div className='d-flex justify-content-between align-items-center mb-4'>
                            <h6 className="main-content-label">COMPANY LIST</h6>
                            <div className='d-flex align-items-center'>
                                <div className='form-group mb-0 me-3'>
                                    <div className="form-group mb-0 rangepicker_container">
                                        <i className="fa fa-search calender_icon"></i>
                                        <input type="search" className="form-control" value={globalSearch} placeholder="Search" onChange={(e) => { setGlobalSearch(e.target.value) }} />
                                    </div>
                                </div>
                                <div className="me-3">
                                    <CustomRangepicker GetDateRange={(e) => { prepareSearch("created", e) }} resetdate={resetdate} />
                                </div>
                                <div className="me-3">
                                    <StatusFilter data={showFilterlist} prepareSearch={prepareSearch} />
                                </div>
                                <button type="reset" value='Reset' onClick={resetFilter} className="btn btn-warning float-right mr-2">Reset Filter</button>
                                <button className="btn ripple btn-main-primary signbtn" onClick={() => navigate(`/admin/company/add`)}>Add New</button>
                            </div>
                        </div>
                        <div className="table-responsive">
                            <table id="example-input" className="table table-bordered border-t0 key-buttons text-nowrap w-100">
                                <thead>
                                    <tr>
                                        <th className="sr_head">S.No</th>
                                        <th>
                                            <div className='sorting_column'>
                                                <span>Logo</span>

                                            </div>
                                        </th>
                                        <th>
                                            <div className='sorting_column'>
                                                <span>Name</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="name" />
                                            </div>
                                        </th>
                                        <th>
                                            <div className='sorting_column'>
                                                <span>Email</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="official_email" />
                                            </div>
                                        </th>
                                        <th>
                                            <div className='sorting_column'>
                                                <span>Licence number</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="licence_number" />
                                            </div>
                                        </th>
                                        {/* <th>
                                    <div className='sorting_column'>
                                        <span>Official address</span>
                                        <Sorting sort={sorting} handleSort={handleSort} column="official_address" />
                                    </div>
                                </th> */}
                                        {/* <th>
                                    <div className='sorting_column'>
                                        <span>Revenue</span>
                                    </div>
                                </th> */}
                                        <th>
                                            <div className='sorting_column'>
                                                <span>phone number</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="official_phone_number" />
                                            </div>
                                        </th>
                                        <th>
                                            <div className='sorting_column'>
                                                <span>Established year</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="established_year" />
                                            </div>
                                        </th>
                                        <th className="created_head">
                                            <div className='sorting_column'>
                                                <span>Created date</span>
                                                <Sorting sort={sorting} handleSort={handleSort} defaultSorting={defaultSorting} column="createdAt" />
                                            </div>
                                        </th>
                                        <th className='status_head'>Status</th>
                                        <th className='action_head'>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {loader ?
                                        <tr>
                                            <td colSpan={11}><Loader /></td>
                                        </tr> : <>
                                            {list.length ? list.map((row, i) => (
                                                <tr>
                                                    <td>{(params.id * itemPerPage) - (itemPerPage) + (++i)}</td>
                                                    <td>{row.logo ? <img src={row.logo} style={{ height: "50px" }} /> : ''}</td>
                                                    <td>{row.name}</td>
                                                    <td>{row.official_email}</td>
                                                    <td>{row.licence_number}</td>
                                                    {/* <td>{row.official_address}</td> */}
                                                    {/* <td></td> */}
                                                    <td>+{row.country_code} {row.official_phone_number}</td>
                                                    <td>{row.established_year}</td>
                                                    <td>{row.createdAt ? formateDate(row.createdAt) : "N/A"}</td>
                                                    <td>
                                                        {row.status == 1 ? <button className="btn ripple btn-main-primary signbtn" onClick={() => { ChangeStatus(row.id, 0) }}>Active</button>
                                                            : <button className="btn ripple btn-secondary" onClick={() => { ChangeStatus(row.id, 1) }}>Inactive</button>}
                                                    </td>
                                                    <td>
                                                        <div className="d-flex">
                                                            {/* <button className="btn btn-info" onClick={() => navigate(`/admin/brand/list/${row.id}?id=${1}`)}>Brands</button> */}
                                                            {/* <button className="btn btn-info mx-1" onClick={() => navigate(`/admin/outlet-management/list/${row.id}?id=${1}`)}> Outlets</button> */}
                                                            <button className="btn ripple btn-main-primary signbtn" onClick={() => { viewFunction(row.id) }}>View</button>
                                                            <button className="btn ripple btn-success mx-1" onClick={() => { editFunction(row) }}>Edit</button>
                                                            <button className="btn ripple btn-secondary" onClick={() => { deleteFunction(row.id) }}>Delete</button>
                                                            <button className="btn ripple btn-info float-right ms-1" onClick={() => { openOnlineOrderApiCredsModel(row) }}>API Credentials</button>
                                                            {/* <button className="btn ripple btn-danger float-right ms-1" onClick={() => { openOnlineOrderApiAccessibilityModel(row) }}>APIs Accessibility</button> */}
                                                            <button className="btn ripple btn-warning float-right ms-1" onClick={() => { openERPConfigurationModel(row) }}>ERP Configuration</button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )) : <tr><td colSpan={11} className="text-center">No records</td></tr>}</>}
                                </tbody>
                            </table>
                        </div>
                        <div className="" id="example1_info" role="status" aria-live="polite"><b>Total Records : {dataLength ? dataLength : "0"}</b></div>
                        {dataLength && dataLength > 0 ?
                            <CustomPagination datalength={dataLength} itemperpage={itemPerPage} setPage={setPage} currentPage={page} pageRoute={[{ name: "company", path: "/admin/company/list" }]} /> : ""}
                    </div>
                    <div>
                    </div>
                </div>
            </div>

            {/* Online order api creds model */}
            <Modal
                size="lg"
                show={showOnlineOrderApiCredsModal}
                onHide={() => { closeOnlineOrderApiCredsModel() }}
                backdrop="static"
                className="modal-height-auto"
            >
                <Modal.Header className="text-white">
                    <Modal.Title>{capitalizeAllFirstLetters(onlineOrderApiCredsData?.company_name)}</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ maxHeight: '80vh', overflowY: 'auto' }}>
                    <div className='d-flex justify-content-center mb-2'><h3>Online Order Apis Access Credentials</h3></div>
                    {
                        // onlineOrderApiCredsData?.brandList?.map((brand, brandIndex) => 
                        //     <div className="container mb-3">
                        //         <div className='d-flex justify-content-center'>
                        //             <h4 className="fw-bold mb-3">{capitalizeAllFirstLetters(brand.name)}</h4>
                        //         </div>
                        //         {
                        //             brand?.aggregators_configurations?.map((aggregator, aggregatorIndex) =>
                        //                 <div className="border rounded p-3 shadow-sm bg-light mb-3">
                        //                     <h4 className="fw-bold mb-3">{capitalizeAllFirstLetters(aggregator.name)}</h4>
                        //                     {
                        //                         aggregator?.online_order_apis_access_token && aggregator?.online_order_apis_secret_key 
                        //                         ? 
                        //                             <div className="mb-4">
                        //                                 <div>Access Credentials</div>

                        //                                 {/* Access Token Box */}
                        //                                 <div className="border rounded p-3 shadow-sm bg-light mb-3">
                        //                                     <div className="d-flex justify-content-between align-items-center mb-2">
                        //                                         <p className="mb-0">
                        //                                             <strong>Access Token:</strong>
                        //                                         </p>
                        //                                         <button
                        //                                             type="button"
                        //                                             className="btn btn-sm btn-outline-primary"
                        //                                             onClick={() => CopytoClipboard(aggregator?.online_order_apis_access_token)}
                        //                                         >
                        //                                             <i className="fa fa-clone"></i> <span className="d-none d-sm-inline">Copy</span>
                        //                                         </button>
                        //                                     </div>
                        //                                     <div
                        //                                         className="text-secondary large bg-white p-2 rounded"
                        //                                         style={{
                        //                                             wordBreak: "break-all",
                        //                                             overflowWrap: "break-word",
                        //                                             whiteSpace: "pre-wrap",
                        //                                             maxWidth: "100%",
                        //                                         }}
                        //                                     >
                        //                                         {aggregator?.online_order_apis_access_token}
                        //                                     </div>
                        //                                 </div>

                        //                                 {/* Secret Key Box */}
                        //                                 <div className="border rounded p-3 shadow-sm bg-light">
                        //                                     <div className="d-flex justify-content-between align-items-center mb-2">
                        //                                         <p className="mb-0">
                        //                                             <strong>Secret Key:</strong>
                        //                                         </p>
                        //                                         <button
                        //                                             type="button"
                        //                                             className="btn btn-sm btn-outline-primary"
                        //                                             onClick={() => CopytoClipboard(aggregator?.online_order_apis_secret_key)}
                        //                                         >
                        //                                             <i className="fa fa-clone"></i> <span className="d-none d-sm-inline">Copy</span>
                        //                                         </button>
                        //                                     </div>
                        //                                     <div
                        //                                         className="text-secondary large bg-white p-2 rounded"
                        //                                         style={{
                        //                                             wordBreak: "break-all",
                        //                                             overflowWrap: "break-word",
                        //                                             whiteSpace: "pre-wrap",
                        //                                             maxWidth: "100%",
                        //                                         }}
                        //                                     >
                        //                                         {aggregator?.online_order_apis_secret_key}
                        //                                     </div>
                        //                                 </div>

                        //                                 {/* Expiry date enabled-disabled checkbox */}
                        //                                 <div className="d-flex justify-content-between align-items-center my-4 p-3 rounded bg-light">
                        //                                     <p className={`mb-0 ${!aggregator?.token_expiry_enabled ? 'text-muted' : ''}`}>
                        //                                         <strong>Expiry Date: </strong>
                        //                                         <span>{aggregator?.jwt_expiry_date}</span>
                        //                                     </p>
                        //                                     <div className="d-flex">
                        //                                         <label className="mb-0 me-2">
                        //                                             { aggregator?.token_expiry_enabled == true ? 'Disable Expiry Date' : 'Enable Expiry Date'}
                        //                                         </label>
                        //                                         <input 
                        //                                             type="checkbox" 
                        //                                             checked={aggregator?.token_expiry_enabled} 
                        //                                             onChange={(event) => { changeOnlineOrderApiTokenExpiry(brandIndex, aggregatorIndex, event.target.checked) }} 
                        //                                             className="form-check-input custom-checkbox" 
                        //                                         />
                        //                                     </div>
                        //                                 </div>

                        //                                 {/* credentials regenerate button */}
                        //                                 <div className="d-flex justify-content-right mt-4">
                        //                                     <Button
                        //                                         variant="primary"
                        //                                         className="px-4 bg-primary text-white"
                        //                                         onClick={() => generateOnlineOrderApiCreds(aggregator.id, brandIndex, aggregatorIndex)}
                        //                                     >
                        //                                         Regenerate Online Order API Credentials    
                        //                                     </Button>
                        //                                 </div>
                        //                             </div>
                        //                         :   
                        //                             <div className="d-flex justify-content-right mt-4">
                        //                                     <Button
                        //                                         variant="primary"
                        //                                         className="px-4 bg-primary text-white"
                        //                                         onClick={() => generateOnlineOrderApiCreds(aggregator.id, brandIndex, aggregatorIndex)}
                        //                                     >
                        //                                         Generate Online Order API Credentials
                        //                                     </Button>
                        //                             </div>
                        //                     }
                        //                 </div>
                        //             )
                        //         }   
                        //     </div>
                        // )
                        onlineOrderApiCredsData?.credentials_list?.map((credentials, index) =>
                            <div className="container mb-3">
                                <div className="border rounded p-3 shadow-sm bg-light mb-3">
                                    <div className='d-flex justify-content-between align-items-center mb-2'>
                                        {/* <div className="fw-bold mb-3">Id : {credentials.credential_id}</div> */}
                                        <div className="col-lg-4 d-flex align-items-center">
                                            <label htmlFor="name" className="text-left me-2"><strong>Name:</strong></label>
                                            <input name="name" id="name" type="text" value={credentials.credential_name} className="form-control"
                                                onChange={(event) => {
                                                    const data = { ...onlineOrderApiCredsData };
                                                    data.credentials_list[index].credential_name = event.target.value;
                                                    setOnlineOrderApiCredsData(data);
                                                }}
                                            />
                                            <button
                                                type="button"
                                                className="btn btn-md btn-outline-primary"
                                                onClick={() => changeOnlineOrderApiCredentialName(credentials.credential_id, credentials.credential_name)}
                                            >
                                                <i className="fa fa-check"></i>
                                            </button>
                                        </div>
                                        <div className="d-flex">
                                            {/* <label className="mb-0 me-2">
                                                {credentials?.active == true ? 'In-Active' : 'Active'}
                                            </label> */}
                                            <span>{credentials?.active ? 'Enabled' : 'Disabled'}</span>
                                            <div className="form-check form-switch w-50 ms-4">
                                                <input
                                                    className="form-check-input" type="checkbox" id="status-change" name="status"
                                                    checked={credentials?.active}
                                                    onClick={(event) => { changeOnlineOrderApiActiveStatus(credentials.credential_id, event.target.checked) }}
                                                />
                                                {/* <label className="form-check-label" htmlFor="status-change">
                                                    { credentials?.active == true ? <span>Disable</span> }
                                                </label> */}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mb-4">
                                        {/* Access Token Box */}
                                        <div className="border rounded p-3 shadow-sm bg-light mb-3">
                                            <div className="d-flex justify-content-between align-items-center mb-2">
                                                <p className="mb-0">
                                                    <strong>Access Token:</strong>
                                                </p>
                                                <button
                                                    type="button"
                                                    className="btn btn-sm btn-outline-primary"
                                                    onClick={() => CopytoClipboard(credentials?.access_token)}
                                                >
                                                    <i className="fa fa-clone"></i> <span className="d-none d-sm-inline">Copy</span>
                                                </button>
                                            </div>
                                            <div
                                                className="text-secondary large bg-white p-2 rounded"
                                                style={{
                                                    wordBreak: "break-all",
                                                    overflowWrap: "break-word",
                                                    whiteSpace: "pre-wrap",
                                                    maxWidth: "100%",
                                                }}
                                            >
                                                {credentials?.access_token}
                                            </div>
                                        </div>

                                        {/* Secret Key Box */}
                                        <div className="border rounded p-3 shadow-sm bg-light">
                                            <div className="d-flex justify-content-between align-items-center mb-2">
                                                <p className="mb-0">
                                                    <strong>Secret Key:</strong>
                                                </p>
                                                <button
                                                    type="button"
                                                    className="btn btn-sm btn-outline-primary"
                                                    onClick={() => CopytoClipboard(credentials?.secret_key)}
                                                >
                                                    <i className="fa fa-clone"></i> <span className="d-none d-sm-inline">Copy</span>
                                                </button>
                                            </div>
                                            <div
                                                className="text-secondary large bg-white p-2 rounded"
                                                style={{
                                                    wordBreak: "break-all",
                                                    overflowWrap: "break-word",
                                                    whiteSpace: "pre-wrap",
                                                    maxWidth: "100%",
                                                }}
                                            >
                                                {credentials?.secret_key}
                                            </div>
                                        </div>

                                        {/* Expiry date enabled-disabled checkbox */}
                                        <div className="d-flex justify-content-between align-items-center my-4 p-3 rounded bg-light">
                                            <p className={`mb-0 ${!credentials?.token_expiry_enabled ? 'text-muted' : ''}`}>
                                                <strong>Expiry Date: </strong>
                                                <span>{credentials?.jwt_expiry_date}</span>
                                            </p>
                                            <div className="d-flex">
                                                <label className="mb-0 me-2">
                                                    {credentials?.token_expiry_enabled == true ? 'Disable Expiry Date' : 'Enable Expiry Date'}
                                                </label>
                                                <input
                                                    type="checkbox"
                                                    checked={credentials?.token_expiry_enabled}
                                                    onChange={(event) => { changeOnlineOrderApiTokenExpiryStatus(credentials.credential_id, event.target.checked) }}
                                                    className="form-check-input custom-checkbox"
                                                />
                                            </div>
                                        </div>

                                        {/* Remove button & credentials regenerate button */}
                                        <div className="d-flex justify-content-end mt-4">
                                            <Button
                                                // variant="danger"
                                                className="px-4 text-white bg-success me-auto"
                                                onClick={() => openOnlineOrderApiAccessibilityModel(index)}
                                            >
                                                APIs Accessibility
                                            </Button>
                                            <Button
                                                // variant="danger"
                                                className="px-4 text-white bg-danger"
                                                onClick={() => deleteOnlineOrderApiCreds(credentials.credential_id)}
                                            >
                                                Delete
                                            </Button>
                                            <Button
                                                className="px-4 text-white ms-1 btn ripple btn-main-primary signbtn"
                                                onClick={() => regenerateOnlineOrderApiCreds(credentials.credential_id)}
                                            >
                                                Regenerate
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }

                    <div className="d-flex justify-content-end mt-4">
                        <Button
                            variant="primary"
                            className="px-4 text-white ms-1 btn ripple btn-main-primary signbtn"
                            onClick={() => generateOnlineOrderApiCreds()}
                        >
                            {
                                !onlineOrderApiCredsData?.credentials_list || !onlineOrderApiCredsData?.credentials_list?.length
                                    ? 'Generate Credentials'
                                    : 'Add More'
                            }
                        </Button>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => { closeOnlineOrderApiCredsModel() }}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Online order api access model */}
            <Modal
                size="lg"
                show={showOnlineOrderApiAccessibilityModal}
                onHide={() => { closeOnlineOrderApiAccessibilityModel() }}
                backdrop="static"
                className="modal-height-auto"
            >
                <Modal.Header className="text-white">
                    <Modal.Title>{capitalizeAllFirstLetters(onlineOrderApiCredsData?.company_name)}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='d-flex justify-content-center mb-2'>
                        {/* <h3>Online Order Apis Accessibility</h3> */}
                        <h3>{capitalizeFirstLetter(onlineOrderApiCredsData?.credentials_list[apiAccessibility?.credential_index]?.credential_name)}</h3>
                    </div>
                    {onlineOrderApiCredsData?.credentials_list[apiAccessibility?.credential_index]?.apis_accessibility?.length > 0 ?
                        <div className="container overflow-auto" style={{ maxHeight: "70vh" }}>
                            {/* <h4 className="fw-bold mb-3">Access Details</h4> */}
                            <div className="border rounded p-3 bg-light">
                                <div className="d-flex fw-bold border-bottom pb-2">
                                    <div className="w-75">API Name</div>
                                    <div className="w-25 text-center">Allow Permission</div>
                                </div>
                                {onlineOrderApiCredsData?.credentials_list[apiAccessibility?.credential_index]?.apis_accessibility?.map((api, index) => (
                                    <div key={index} className="d-flex border-bottom py-2 align-items-center">
                                        <div className="w-75">{capitalizeFirstLetter(api.api_name)}</div>
                                        <div className="w-25 text-center">
                                            <input type="checkbox" checked={api.permission_allowed} onChange={() => isAuthenticatedStatusChange(api)} />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        :
                        <p className='text-center'>No Data Found</p>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => { closeOnlineOrderApiAccessibilityModel() }}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* ERP Configuration Model */}
            <Modal
                size="lg"
                show={showERPConfigurationModel}
                onHide={handleCloseModal}
                backdrop="static"
                className="modal-height-auto"
            >
                <Modal.Header className="text-white">
                    <Modal.Title>ERP Configuration</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Tabs
                        activeKey={activeTab}
                        onSelect={(k) => setActiveTab(k)}
                        id="erp-configuration-tabs"
                        className="mb-3"
                    >
                        {/* Sales API Configuration Tab */}
                        <Tab eventKey="sales" title="Sales API Configuration">
                            <div className="row row-sm">
                                <div className="col-lg-6 text-center form-group mb-3">
                                    <label htmlFor="salesSelectERP" className="text-left d-flex">
                                        Select ERP<span className="requirestar">*</span>
                                        {/* <Whisper
                                            placement="top"
                                            controlId="control-id-hover"
                                            trigger="hover"
                                            speaker={
                                                <Tooltip>
                                                    Please Select ERP.
                                                </Tooltip>
                                            }
                                        >
                                            <span className="field-more-info ms-1 cp">?</span>
                                        </Whisper> */}
                                    </label>
                                    <select
                                        name="salesSelectERP"
                                        id="salesSelectERP"
                                        className="form-control select-arrow"
                                        value={formData.salesSelectERP}
                                        onChange={handleChange}
                                    >
                                        <option value="">Select ERP</option>
                                        <option value="dynamic_365">Dynamic 365</option>
                                    </select>
                                    {errors.salesSelectERP && (
                                        <span className="text-danger text-start d-block">
                                            {errors.salesSelectERP}
                                        </span>
                                    )}
                                </div>

                                {/* Conditionally render other fields only if an ERP is selected */}
                                {formData.salesSelectERP === "dynamic_365" && (
                                    <>
                                        <div className="col-lg-6 text-center form-group mb-3">
                                            <label htmlFor="salesDataAreaID" className="text-left d-flex">
                                                Data Area ID<span className="requirestar">*</span>
                                                {/* <Whisper
                                                    placement="top"
                                                    controlId="control-id-hover"
                                                    trigger="hover"
                                                    speaker={
                                                        <Tooltip>
                                                            Please Enter data area ID as per the erp requirement.
                                                        </Tooltip>
                                                    }
                                                >
                                                    <span className="field-more-info ms-1 cp">?</span>
                                                </Whisper> */}
                                            </label>
                                            <input
                                                name="salesDataAreaID"
                                                id="salesDataAreaID"
                                                type="text"
                                                placeholder="Enter data area id"
                                                className="form-control"
                                                value={formData.salesDataAreaID}
                                                onChange={handleChange}
                                            />
                                            {errors.salesDataAreaID && (
                                                <span className="text-danger text-start d-block">
                                                    {errors.salesDataAreaID}
                                                </span>
                                            )}
                                        </div>
                                        <div className="col-lg-6 text-center form-group mb-3">
                                            <label htmlFor="salesMainAccountNumber1" className="text-left d-flex">
                                                Main Account Number 1<span className="requirestar">*</span>
                                                {/* <Whisper
                                                    placement="top"
                                                    controlId="control-id-hover"
                                                    trigger="hover"
                                                    speaker={
                                                        <Tooltip>
                                                            Please Enter main account number 1 as per the erp requirement.
                                                        </Tooltip>
                                                    }
                                                >
                                                    <span className="field-more-info ms-1 cp">?</span>
                                                </Whisper> */}
                                            </label>
                                            <input
                                                name="salesMainAccountNumber1"
                                                id="salesMainAccountNumber1"
                                                type="number"
                                                placeholder="Enter main account number 1"
                                                className="form-control"
                                                value={formData.salesMainAccountNumber1}
                                                onChange={handleChange}
                                            />
                                            {errors.salesMainAccountNumber1 && (
                                                <span className="text-danger text-start d-block">
                                                    {errors.salesMainAccountNumber1}
                                                </span>
                                            )}
                                        </div>
                                        <div className="col-lg-6 text-center form-group mb-3">
                                            <label htmlFor="salesMainAccountNumber2" className="text-left d-flex">
                                                Main Account Number 2<span className="requirestar">*</span>
                                                {/* <Whisper
                                                    placement="top"
                                                    controlId="control-id-hover"
                                                    trigger="hover"
                                                    speaker={
                                                        <Tooltip>
                                                            Please Enter main account number 2 as per the erp requirement.
                                                        </Tooltip>
                                                    }
                                                >
                                                    <span className="field-more-info ms-1 cp">?</span>
                                                </Whisper> */}
                                            </label>
                                            <input
                                                name="salesMainAccountNumber2"
                                                id="salesMainAccountNumber2"
                                                type="number"
                                                placeholder="Enter main account number 2"
                                                className="form-control"
                                                value={formData.salesMainAccountNumber2}
                                                onChange={handleChange}
                                            />
                                            {errors.salesMainAccountNumber2 && (
                                                <span className="text-danger text-start d-block">
                                                    {errors.salesMainAccountNumber2}
                                                </span>
                                            )}
                                        </div>
                                        <div className="col-lg-6 text-center form-group mb-3">
                                            <label htmlFor="SalesCurrencyCode" className="text-left d-flex">
                                                Currency Code<span className="requirestar">*</span>
                                                {/* <Whisper
                                                    placement="top"
                                                    controlId="control-id-hover"
                                                    trigger="hover"
                                                    speaker={
                                                        <Tooltip>
                                                            Please Enter currency code as per the erp requirement.
                                                        </Tooltip>
                                                    }
                                                >
                                                    <span className="field-more-info ms-1 cp">?</span>
                                                </Whisper> */}
                                            </label>
                                            <input
                                                name="SalesCurrencyCode"
                                                id="SalesCurrencyCode"
                                                type="text"
                                                placeholder="Enter currency code"
                                                className="form-control"
                                                value={formData.SalesCurrencyCode}
                                                onChange={handleChange}
                                            />
                                            {errors.SalesCurrencyCode && (
                                                <span className="text-danger text-start d-block">
                                                    {errors.SalesCurrencyCode}
                                                </span>
                                            )}
                                        </div>
                                    </>
                                )}
                            </div>
                        </Tab>

                        {/* Payment API Configuration Tab */}
                        <Tab eventKey="payment" title="Payment API Configuration">
                            <div className="row row-sm">
                                <div className="col-lg-6 text-center form-group mb-3">
                                    <label htmlFor="paymentDataAreaID" className="text-left d-flex">
                                        Data Area ID<span className="requirestar">*</span>
                                    </label>
                                    <input
                                        name="paymentDataAreaID"
                                        id="paymentDataAreaID"
                                        type="text"
                                        placeholder="Enter data area id"
                                        className="form-control"
                                        value={formData.paymentDataAreaID}
                                        onChange={handleChange}
                                    />
                                    {errors.paymentDataAreaID && (
                                        <span className="text-danger text-start d-block">
                                            {errors.paymentDataAreaID}
                                        </span>
                                    )}
                                </div>
                                <div className="col-lg-6 text-center form-group mb-3">
                                    <label htmlFor="paymentJournalName" className="text-left d-flex">
                                        Journal Name<span className="requirestar">*</span>
                                    </label>
                                    <input
                                        name="paymentJournalName"
                                        id="paymentJournalName"
                                        type="text"
                                        placeholder="Enter Payment journal name"
                                        className="form-control"
                                        value={formData.paymentJournalName}
                                        onChange={handleChange}
                                    />
                                    {errors.paymentJournalName && (
                                        <span className="text-danger text-start d-block">
                                            {errors.paymentJournalName}
                                        </span>
                                    )}
                                </div>
                            </div>
                        </Tab>

                        {/* Commissions API Configuration Tab */}
                        <Tab eventKey="commissions" title="Commissions API Configuration">
                            <div className="row row-sm">
                                <div className="col-lg-6 text-center form-group mb-3">
                                    <label htmlFor="commissionsDataAreaID" className="text-left d-flex">
                                        Data Area ID<span className="requirestar">*</span>
                                    </label>
                                    <input
                                        name="commissionsDataAreaID"
                                        id="commissionsDataAreaID"
                                        type="text"
                                        placeholder="Enter data area id"
                                        className="form-control"
                                        value={formData.commissionsDataAreaID}
                                        onChange={handleChange}
                                    />
                                    {errors.commissionsDataAreaID && (
                                        <span className="text-danger text-start d-block">
                                            {errors.commissionsDataAreaID}
                                        </span>
                                    )}
                                </div>
                                <div className="col-lg-6 text-center form-group mb-3">
                                    <label htmlFor="commissionsJournalName" className="text-left d-flex">
                                        Journal Name<span className="requirestar">*</span>
                                    </label>
                                    <input
                                        name="commissionsJournalName"
                                        id="commissionsJournalName"
                                        type="text"
                                        placeholder="Enter commission journal name"
                                        className="form-control"
                                        value={formData.commissionsJournalName}
                                        onChange={handleChange}
                                    />
                                    {errors.commissionsJournalName && (
                                        <span className="text-danger text-start d-block">
                                            {errors.commissionsJournalName}
                                        </span>
                                    )}
                                </div>
                            </div>
                        </Tab>
                    </Tabs>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Close
                    </Button>
                    <Button variant="primary" className="bg-primary" onClick={handleERPConfigurationDataSaved}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>

        </Layout>
    );
}

export default CompanyTable