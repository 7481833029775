import axiosInstance from "../utils/axios";

export const CreateCompany = async (values) => {
    return await axiosInstance().post("admin/save-company", values);
}

export const GetCompanyList = async (values) => {
    return await axiosInstance().post("admin/list-company", values);
}

//without pagination
export const GetAllCompanies = async () => {
    return await axiosInstance().get("admin/get-all-companies");
}

export const GetEditCompany  = async (values) => {
    return await axiosInstance().post("admin/edit-company", values);
}

export const GetChangeStatus = async (values) => {
    return await axiosInstance().post("admin/change-status", values)
}

export const GetDeleteCompany = async (values) => {
    return await axiosInstance().post("admin/delete-company", values)
}

export const GetCompanyDetail = async (id) => {
    return await axiosInstance().get(`admin/get-edit-company?id=${id}`);
};

// export const GetBrandsWithAggregators = async (values) => {
//     return await axiosInstance().post(`admin/get-brands-with-aggregators`, values);
// };

export const GenerateOnlineOrderApiCreds = async (values) => {
    return await axiosInstance().post(`admin/generate-online-order-api-creds`, values);
};
export const RegenerateOnlineOrderApiCreds = async (values) => {
    return await axiosInstance().post(`admin/regenerate-online-order-api-creds`, values);
};
export const DeleteOnlineOrderApiCreds = async (values) => {
    return await axiosInstance().post(`admin/delete-online-order-api-creds`, values);
};
export const ChangeOnlineOrderApiTokenExpiryStatus = async (values) => {
    return await axiosInstance().post(`admin/change-online-order-api-token-expiry-status`, values);
};
export const ChangeOnlineOrderApiActiveStatus = async (values) => {
    return await axiosInstance().post(`admin/change-online-order-api-active-status`, values);
};
export const ChangeOnlineOrderApiCredentialName = async (values) => {
    return await axiosInstance().post(`admin/change-online-order-api-credential-name`, values);
};
export const OnlineOrderChangeApiWorkingStatus = async (values) => {
    return await axiosInstance().post(`admin/online-order-change-api-working-status`, values);
};

export const executeMigrationsChangesForCompany= async () => {
    return await axiosInstance().post("admin/execute-migrations-changes-for-companies");
}

export const ERPConfigurationDataSaved = async (values) => {
    return await axiosInstance().post(`admin/erp-configuration-save`, values);
};

export const ERPDetails = async (values) => {
    return await axiosInstance().post(`admin/get-erp-configuration`,values);
};


