import React, { useState, useEffect, useMemo } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import CustomPagination from '../../components/company_panel/common/custompagination'
import { capitalizeFirstLetter, checkPermission, formateDate, getOutletId, removeHyphens, TrimText } from '../../helpers/commonFunctions';
import Sorting from '../../components/company_panel/common/sorting';
import Loader from '../../components/company_panel/common/loader'
import StatusFilter from '../../components/company_panel/common/statusFilter'
import { LOADER_TIME, showFilterlist } from '../../utils/Constants'
import CustomRangepicker from '../../components/company_panel/common/rangepicker'
import $ from "jquery";
import { getCompany } from '../../helpers/commonFunctions';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import notFound from '../../assets/company/img/no-record-found.png'
import { ListCustomer } from '../../services/company_panel/customer.services';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { ListRefund } from '../../services/company_panel/refund.services';
import QRCode from "react-qr-code";
import { Modal, Button, Form } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { reportToZatcaAgain } from '../../services/company_panel/orderService';


const RefundList = () => {

    const navigate = useNavigate()
    const params = useParams()
    const location = useLocation()
    const [list, setList] = useState([])
    const [statsUpdate, setStatusUpdate] = useState("false")
    const [dataLength, setDataLength] = useState()
    const [itemPerPage, setItemPerPage] = useState(10)
    const [sorting, setSorting] = useState({});
    const [defaultSorting, setDefaultSorting] = useState(true);
    const [page, setPage] = useState(1);
    const [loader, setLoader] = useState(true)
    const [search, setSearch] = useState({})
    const [globalSearch, setGlobalSearch] = useState("")
    const [resetdate, setResetDate] = useState(false);
    const [isBrandChanged, setIsBrandChanged] = useState(false);
    const [isOutletChanged, setIsOutletChanged] = useState(false);
    const [showZatcaDetailsModel, setShowZatcaDetailsModel] = useState(false);
    const [loaderNew, setLoaderNew] = useState(false)
    const [zatcaModelData, setZatcaModelData] = useState([]);

    useMemo(() => {
        document.addEventListener('brand-changed', function () {
            setIsBrandChanged(!isBrandChanged);
        });
        document.addEventListener('outlet-changed', function () {
            setIsOutletChanged(!isOutletChanged);
        });
    });

    useEffect(() => {
        if (search) {
            setLoader(true)
            setTimeout(() => {
                const formData = new FormData()
                formData.append("page", params.id)
                formData.append("per_page", itemPerPage)
                formData.append("sort", JSON.stringify(sorting))
                formData.append("search", JSON.stringify(search))
                formData.append("global_search", globalSearch)
                formData.append('outlet_id', getOutletId());
                ListRefund(formData).then(data => {
                    setDataLength(data.data.response.count);
                    setList(data && data.data && data.data.response ? data.data.response.rows : [])
                    setLoader(false);
                }).catch(error => {
                    console.log("error ====> ", error);
                    setLoader(false);
                })
            }, 500);
        }
    }, [location, statsUpdate, sorting, search, globalSearch, isBrandChanged, isOutletChanged])


    const resetFilter = (e) => {
        e.preventDefault()
        setGlobalSearch("")
        prepareSearch()
        setSearch({})
        setResetDate(!resetdate)
    }

    const viewFunction = (row) => {
        navigate(`/company/transaction/refund/view/${row}`)
    }

    const handleSort = (e, column) => {
        setDefaultSorting(false);
        let sort = { order: 0, column: column };
        if (e.target.classList.contains("assc")) {
            sort.order = -1;
        } else {
            sort.order = 1;
        }
        setSorting(sort);
    }

    const prepareSearch = (key, value) => {
        let sr = { ...search };
        if (String(value).length > 0) {
            sr[key] = value;
        } else {
            delete sr[key];
        }
        setSearch(sr);
    }

    const headerMapping = {
        "id": "Order ID",
        "name": "Order Type",
        "outlet_name": "Outlet Name",
        "customer_name": "Customer Name",
        "amount": "Amount",
        "refund_reason": "Reason For Refund",
        "payment_status": "Payment Status",
    };

    const formatValue = (value) => {
        if (value === null || value === undefined || value === "") {
            return 'N/A';
        }
        return value.toString().replace(/_/g, ' ');
    };

    const handleExport = () => {
        const formData = new FormData()
        formData.append("page", 1)
        formData.append("per_page", dataLength)
        formData.append("sort", JSON.stringify(sorting))
        formData.append("search", JSON.stringify(search))
        formData.append("global_search", globalSearch)
        formData.append('outlet_id', getOutletId());
        ListRefund(formData).then(data => {
            setDataLength(data.data.response.count);
            const listData = data && data.data && data.data.response ? data.data.response.rows : [];
            const printData = listData.map((item) => {
                const transformedItem = {};
                // Extract fields from nested objects
                const orderID = item.order_id || 'N/A';
                const orderType = item.order_mode_configuration?.name || 'N/A';
                const outletName = item.outlet?.outlet_name || 'N/A';
                const customerName = item.customer?.customer_name || 'N/A';
                const amount = item.transaction?.amount || 'N/A';
                const refundReason = item.transaction?.refund_reason || 'N/A';
                const paymentStatus = item.transaction?.payment_status || 'N/A';

                // Map fields according to headerMapping
                for (const key in headerMapping) {
                    if (headerMapping.hasOwnProperty(key)) {
                        const newKey = headerMapping[key];
                        switch (key) {
                            case "id":
                                transformedItem[newKey] = ((orderID));
                                break;
                            case "name":
                                transformedItem[newKey] = capitalizeFirstLetter(removeHyphens(orderType));
                                break;
                            case "outlet_name":
                                transformedItem[newKey] = capitalizeFirstLetter(outletName);
                                break;
                            case "customer_name":
                                transformedItem[newKey] = capitalizeFirstLetter(customerName);
                                break;
                            case "amount":
                                transformedItem[newKey] = amount + " " + "SAR";
                                break;
                            case "refund_reason":
                                transformedItem[newKey] = capitalizeFirstLetter(refundReason);
                                break;
                            case "payment_status":
                                transformedItem[newKey] = capitalizeFirstLetter(paymentStatus);
                                break;
                            default:
                                // Handle any unexpected keys
                                console.warn(`Unexpected key in headerMapping: ${key}`);
                                transformedItem[newKey] = 'N/A';
                                break;
                        }
                    }
                }
                return transformedItem;
            });

            const ws = XLSX.utils.json_to_sheet(printData, { header: Object.values(headerMapping) });
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
            saveAs(blob, 'Refund.xlsx');
            setTimeout(() => {
                setLoader(false);
            }, LOADER_TIME);
        }).catch(error => {
            setTimeout(() => {
                setLoader(false);
            }, LOADER_TIME);
            console.log("error ====> ", error);
        })
    };

    const showReason = (row) => {
        const transaction = row?.transaction;

        if (transaction?.refund_reason_id) {
            return TrimText(capitalizeFirstLetter(transaction?.refundReason?.reason),20);
        } else if (transaction?.refund_reason) {
            return TrimText(capitalizeFirstLetter(transaction.refund_reason),20);
        }

        return 'N/A';
    };


    const generateOrderId = (orderId) => {
        let id = orderId;
        let countOfDigits = 0;
        while (id) {
            id = Math.floor(id / 10);
            countOfDigits += 1;
        }

        if (countOfDigits >= 4) {
            return orderId
        }
        let zerosCount = 4 - countOfDigits;
        let generatedZeros = '';
        while (zerosCount) {
            generatedZeros += '0';
            zerosCount -= 1;
        }
        return `${generatedZeros}${orderId}`;
    }

    function CopytoClipboard(text) {
        // Copy the text inside the text field
        navigator?.clipboard?.writeText(text);
        Swal.fire({
            customClass: 'swal-wide',
            icon: 'success',
            title: 'Copied to clipboard',
            showConfirmButton: false,
            timer: 2000,
            toast: true,
            position: 'top-right',
        });
    }

    const reportToZatca = (orderID) => {
        setLoaderNew(true);
        try {
            let formData = new FormData();
            formData.append("order_id", orderID);
            formData.append("report_type", 'refund');
            reportToZatcaAgain(formData)
                .then((response) => {
                    console.log("respone", response)
                    if (response?.status === 200) {
                        Swal.fire({
                            customClass: 'swal-wide',
                            icon: 'success',
                            title: response?.data?.message,
                            showConfirmButton: false,
                            timer: 2000,
                            toast: true,
                            position: 'top-right',
                        });
                        setLoaderNew(false);
                        setStatusUpdate(!statsUpdate)
                    }
                    else {
                        Swal.fire({
                            customClass: 'swal-wide',
                            icon: 'error',
                            title: "Unable to send to the zatca",
                            showConfirmButton: false,
                            timer: 2000,
                            toast: true,
                            position: 'top-right',
                        });
                        setLoaderNew(false);
                    }
                })
                .catch((err) => {
                    console.error("Error in API call:", err);
                    setLoaderNew(false);
                });
        } catch (error) {
            console.error("Unexpected error:", error);
            setLoaderNew(false);
        }
    };

    return (
        <>
            <div className="animation_fade">
                <div className='page-header d-flex align-items-center'>
                    <div className='page-header-left'>
                        <h4>Refund</h4>
                    </div>
                    <div className='page-header-right ms-auto'>
                    {checkPermission('store_refund', 'add') ?
                        <button className="btn btn-primary ms-2" onClick={() => navigate(`/company/transaction/refund/configuration`)}>Configuration</button>
                        :''}
                        <button onClick={handleExport} className="btn btn-primary ms-2">Export Refund</button>
                    </div>
                </div>
                <div className="card custom-card overflow-hidden">
                    <div className='gap-20'>
                        <div className='filter-header d-flex justify-content-between align-items-center'>
                            <div className='d-flex gap-3 align-items-center'>
                                <div className="form-group mb-0 rangepicker_container">
                                    <input type="search" className="form-control" value={globalSearch} placeholder="Search" onChange={(e) => { setGlobalSearch(e.target.value) }} />
                                </div>
                            </div>
                            <div className='d-flex gap-3 align-items-center'>
                                <div className="date-input">
                                    <CustomRangepicker GetDateRange={(e) => { prepareSearch("created", e) }} resetdate={resetdate} />
                                </div>
                                <button type="reset" value='Reset' onClick={resetFilter} className="btn btn-primary mr-2">Reset Filter</button>
                            </div>
                        </div>
                    </div>
                    <div className="tabler-wrap">
                        <div className="table-responsive">
                            <table className="table table-custom">
                            {loaderNew && (
                                    <div className="loader-overlay">
                                        <div className="spinner-border text-primary" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    </div>
                                )}
                                <thead>
                                    <tr>
                                        <th width="10%">
                                            <div className='sorting_column'>
                                                <span>Order ID</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="order_id" />
                                            </div>
                                        </th>
                                        <th width="15%">
                                            <div className='sorting_column'>
                                                <span>Order Mode</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="order_mode" />
                                            </div>
                                        </th>
                                        <th width="15%">
                                            <div className='sorting_column'>
                                                <span>Aggregator Name</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="aggregator_name" />
                                            </div>
                                        </th>
                                        <th width="15%">
                                            <div className='sorting_column'>
                                                <span>Ordered By</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="user_id" />
                                            </div>
                                        </th>
                                        <th width="15%">
                                            <div className='sorting_column'>
                                                <span>Outlet Name</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="outlet_name" />
                                            </div>
                                        </th>
                                        <th width="15%">
                                            <div className='sorting_column'>
                                                <span>Customer Name </span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="customer_name" />
                                            </div>
                                        </th>
                                        <th className="created_head" width="10%">
                                            <div className='sorting_column'>
                                                <span>Refunded Amount</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="refunded_amount" />
                                            </div>
                                        </th>
                                        {/* <th className="created_head" width="25%">
                                            <div className='sorting_column'>
                                                <span>Reason For Refund</span>
                                                
                                            </div>
                                        </th> */}
                                        <th className="created_head" width="10%">
                                            <div className='sorting_column'>
                                                <span>Payment Status</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="payment_status" />
                                            </div>
                                        </th>
                                        <th className="created_head" width="20%">
                                            <div className='sorting_column'>
                                                <span>Created Date</span>
                                                <Sorting sort={sorting} handleSort={handleSort} defaultSorting={defaultSorting} column="created_at" />
                                            </div>
                                        </th>
                                        <th className="created_head" width="20%">
                                            <div className='sorting_column'>
                                                <span>Zatca Status</span>
                                                <Sorting sort={sorting} handleSort={handleSort} defaultSorting={defaultSorting} column="zatca_status" />
                                            </div>
                                        </th>
                                        <th className="created_head text-end" width="5%">
                                            <span>Action</span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {loader ?
                                        <tr>
                                            <td colSpan={12}>
                                                <Loader />
                                            </td>
                                        </tr> : <>
                                            {list.length ? list.map((row, i) => (
                                                <tr>
                                                    <td>{`#order ${generateOrderId(row?.id)}`}</td>
                                                    <td>{row?.order_mode_configuration?.name ? capitalizeFirstLetter(removeHyphens(row?.order_mode_configuration.name)) : 'N/A'}</td>
                                                    <td>{row?.aggregators_configuration?.name ? capitalizeFirstLetter(removeHyphens(row?.aggregators_configuration.name)) : 'N/A'}</td>
                                                    <td>{row?.user ? `${capitalizeFirstLetter(row?.user?.first_name)} ${capitalizeFirstLetter(row?.user?.last_name)}` : 'N/A'}</td>
                                                    <td>{row?.outlet?.outlet_name ? capitalizeFirstLetter(row?.outlet.outlet_name) : 'N/A'}</td>
                                                    <td>{row?.customer?.customer_name ? row?.customer.customer_name : 'N/A'}</td>
                                                    <td>{row.transaction?.refunded_amount ? (row?.transaction?.refunded_amount + " " + "SAR") : "N/A"}</td>
                                                    {/* <td>{row.transaction?.refund_reason ? capitalizeFirstLetter(TrimText(row?.transaction?.refund_reason,25)) : "N/A"}</td> */}
                                                    {/* <td>{showReason(row)}</td> */}
                                                    {/* <td>{row.transaction?.payment_status ? capitalizeFirstLetter(row?.transaction?.payment_status) : "N/A"}</td> */}
                                                    <td>
                                                        {
                                                            row?.transaction?.payment_status ? 
                                                                row?.transaction?.amount == row?.transaction?.refunded_amount ?
                                                                capitalizeFirstLetter(row?.transaction?.payment_status) : 
                                                                'Partial refunded'
                                                            : "N/A"
                                                        }
                                                    </td>
                                                    <td>{row?.created_at ? formateDate(row?.created_at) : 'N/A' }</td>
                                                    <td>
                                                        {
                                                            !row?.transaction?.zatca_details || row?.transaction?.zatca_details?.success == false ?
                                                            <span className='text-danger'>False</span> :
                                                            <button className="btn btn-primary" onClick={() => { setShowZatcaDetailsModel(true); setZatcaModelData(row?.transaction?.zatca_details) }}>Cleared</button>
                                                        }
                                                    </td>
                                                    <td className='text-end'>
                                                        <div className="dropdown  d-inline-block">
                                                            <button className="btn btn-icon btn-secondary" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                <i className='ti ti-dots-vertical'></i>
                                                            </button>

                                                            <ul className="dropdown-menu">
                                                                <li><div className='dropdown-item' onClick={() => { viewFunction(row.id) }}><i className="ti ti-eye" aria-hidden="true"></i> View details</div></li>
                                                                {
                                                                    !row?.transaction?.zatca_details || row?.transaction?.zatca_details?.success == false ?
                                                                    <li><div className='dropdown-item' onClick={() => { reportToZatca(row?.id) }}> <i className="ti ti-receipt-refund sidemenu-icon"></i> Report to zatca again</div></li> :
                                                                    null
                                                                }
                                                              
                                                            </ul>
                                                                    
                                                            
                                                        </div>
                                                    </td>
                                                </tr>
                                            )) : <tr>
                                                  <td colSpan={12} className="text-center">
                                                    <img src={notFound} style={{ width: "5%" }} alt="Not Found" />
                                                    <p>No records</p>
                                                </td>
                                            </tr>}
                                        </>}
                                </tbody>
                            </table>
                        </div>
                        <div className='tabler-footer'>
                            <div className='d-flex align-items-center'>
                                <div id="example1_info" role="status" className="table-result" aria-live="polite"><b>Total Records : {dataLength ? dataLength : "0"}</b></div>
                                {dataLength && dataLength > 0 ?
                                    <CustomPagination datalength={dataLength} itemperpage={itemPerPage} setPage={setPage} currentPage={page} pageRoute={[{ name: "customer", path: "/company/transaction/refund/list" }]} /> : ""}
                            </div>
                        </div>
                    </div>
                    <div>
                    </div>
                </div>
            </div>

            <Modal
                size="lg"
                show={showZatcaDetailsModel}
                onHide={() => setShowZatcaDetailsModel(false)}
                backdrop={true}
                className="modal-height-auto"
            >
                <Modal.Header className="text-white">
                    <Modal.Title>ZATCA Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container overflow-auto" style={{ maxHeight: "70vh" }}>
                        {/* Header Details */}
                        <div className="mb-4">
                            <h3 className="fw-bold">Header Details</h3> {/* Main Heading */}
                            <div className="row g-3">
                                <div className="col-md-4">
                                    <p>
                                        <strong>Order Date:</strong>{" "}
                                        <span className="text-secondary">
                                            {formateDate(zatcaModelData?.order_created_at)}
                                        </span>
                                    </p>
                                </div>
                                <div className="col-md-4">
                                    <p>
                                        <strong>Transaction Date:</strong>{" "}
                                        <span className="text-secondary">
                                            {formateDate(zatcaModelData?.order_transaction_at)}
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <hr className="my-4" /> {/* Separator Line */}

                        {/* Document Type and Status */}
                        <div className="mb-4">
                            <h3 className="fw-bold">Document Type & Status</h3> {/* Main Heading */}
                            <p>
                                <strong>Document Type:</strong>{" "}
                                <span className="text-secondary">Sales Invoice</span>
                            </p>
                            <p>
                                <strong>ZATCA Status:</strong>{" "}
                                <span className="text-secondary">Cleared</span>
                            </p>
                        </div>
                        <hr className="my-4" /> {/* Separator Line */}

                        {/* QR Code and Validation Results */}
                        <div className="mb-4">
                            <h3 className="fw-bold">QR Code & Validation Results</h3> {/* Main Heading */}
                            <div className="row g-4 align-items-center">
                                <div className="col-md-6">
                                    <h5 className="fw-semibold">Validation Results</h5> {/* Subheading */}
                                    <div
                                        className="bg-light p-3 rounded shadow-sm h-100 d-flex flex-column justify-content-center"
                                        style={{ minHeight: "200px" }} /* Ensures uniform height */
                                    >
                                        <p>
                                            <strong>Status:</strong>{" "}
                                            <span className="text-secondary">202 Accepted</span>
                                        </p>
                                        <p>
                                            <strong>Validation Result:</strong>{" "}
                                            <span className="text-secondary">Accepted</span>
                                        </p>
                                        <p>
                                            <strong>InfoMessages:</strong>{" "}
                                            <span className="text-secondary">
                                                {zatcaModelData?.validation_results?.infoMessages?.[0]?.message ||
                                                    "N/A"}
                                            </span>
                                        </p>
                                    </div>
                                </div>
                                <div className="col-md-6 text-center">
                                    <h5 className="fw-semibold">QR Code</h5> 
                                    <div
                                        className="bg-light p-4 rounded shadow-sm h-100 d-flex justify-content-center align-items-center"
                                        style={{ minHeight: "200px" }} 
                                    >
                                        <QRCode
                                            size={256}
                                            style={{
                                                height: "auto",
                                                maxWidth: "50%",
                                                width: "50%",
                                            }}
                                            value={zatcaModelData?.qr ? zatcaModelData?.qr : 'Sample Qr'}
                                            viewBox={`0 0 256 256`}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* QR Code */}
                        <div className="mb-4">
                            
                            <div className='d-flex justify-content-between align-items-center'>
                                <h3 className="fw-bold text-dark">QR Code</h3>
                                <button
                                    type="button"
                                    className="btn btn-sm btn-main-primary ripple ms-3"
                                    onClick={() => { CopytoClipboard(zatcaModelData?.qr) }}
                                >
                                    <i className="fa fa-clone" aria-hidden="true"></i> 
                                    <span className="d-none d-sm-inline"> Copy</span> {/* Add tooltip or label */}
                                </button>
                            </div>
                            <div className="row g-4 align-items-center">
                                <div className="col-md-12 text-center">
                                    <div
                                        className="bg-light p-4 rounded shadow-sm h-100 d-flex justify-content-center align-items-center"
                                        style={{ minHeight: "150" }}
                                    >
                                        <textarea
                                            className="form-control"
                                            rows={20} 
                                            style={{
                                                width: "100%", 
                                                minHeight: "200px", 
                                                resize: "vertical",
                                            }}
                                            value={zatcaModelData?.qr || ''}
                                            readOnly
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                         {/* Invoice XML */}
                         <div className="mb-4">
                            
                            <div className='d-flex justify-content-between align-items-center'>
                                <h3 className="fw-bold text-dark">Invoice XML</h3>
                                <button
                                    type="button"
                                    className="btn btn-sm btn-main-primary ripple ms-3"
                                    onClick={() => { CopytoClipboard(zatcaModelData?.invoice_xml) }}
                                >
                                    <i className="fa fa-clone" aria-hidden="true"></i> 
                                    <span className="d-none d-sm-inline"> Copy</span> {/* Add tooltip or label */}
                                </button>
                            </div>
                            <div className="row g-4 align-items-center">
                                <div className="col-md-12 text-center">
                                    <div
                                        className="bg-light p-4 rounded shadow-sm h-100 d-flex justify-content-center align-items-center"
                                        style={{ minHeight: "250" }}
                                    >
                                        <textarea
                                            className="form-control"
                                            rows={20} 
                                            style={{
                                                width: "100%", 
                                                minHeight: "300px", 
                                                resize: "vertical",
                                            }}
                                            value={zatcaModelData?.invoice_xml || ''}
                                            readOnly
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowZatcaDetailsModel(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            <ToastContainer
                position="top-right"
                hideProgressBar={false}
                autoClose={true | 1000}
                newestOnTop={true}
                closeOnClick={false}
                draggable={false}
                rtl={false}
            />

        </>
    );
}

export default RefundList