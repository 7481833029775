import React, { useState, useEffect, useMemo } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ConfigurationReportTab from './configurationTab';
import Sorting from '../../components/company_panel/common/sorting';
import {
    capitalizeAllFirstLetters,
    capitalizeFirstLetter,
    formateDate,
    formateDateWithTime,
    formatToTwoDecimals,
    getBrandId,
    getCompany,
    getOutletId
} from '../../helpers/commonFunctions';
import CustomPagination from '../../components/company_panel/common/custompagination';
import Loader from '../../components/company_panel/common/loader';
import notFound from '../../../src/assets/company/img/no-record-found.png'
import CustomRangepicker from '../../components/company_panel/common/rangepicker';
import { erpChangeStatus, erpList, erpRegenerateShortCode, InventoryEnd, ListInventoryReport, StockMovementReport } from '../../services/company_panel/report.services';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { LOADER_TIME } from '../../utils/Constants';
import $ from "jquery";
import { GetParentCategories } from '../../services/company_panel/categoryService';
import { GetAllSuppliers } from '../../services/company_panel/supplierService';
import Swal from 'sweetalert2';
import { Tooltip, Whisper } from "rsuite";

const ERPConfigurationList = () => {

    const breadcrumbs = [{ title: "Dashboard", url: "/company/dashboard" }, { title: "Pages", url: "" }];
    const [list, setList] = useState([]);
    const [dataLength, setDataLength] = useState();
    const [itemPerPage, setItemPerPage] = useState(10);
    const [page, setPage] = useState(1);
    const [businessDate, setBusinessDate] = useState("");
    const [invoiceLineData, setInvoiceLineData] = useState([]);
    const [statsUpdate, setStatusUpdate] = useState(false);
    const [loader, setLoader] = useState(true);
    const [isOutletChanged, setIsOutletChanged] = useState(false);
    const [outletName, setOutletName] = useState("");
    const [selectedRecords, setSelectedRecords] = useState([]);
    const [status, setStatus] = useState(false);
    const params = useParams();
    const [isReverseSync, setIsReverseSync] = useState();
    const [isReversed, setIsReversed] = useState(false);
    const [reportType, setReportType] = useState('sales');
    const [globalSearch, setGlobalSearch] = useState("");
    const [search, setSearch] = useState({});
    const [ERPEnabled, setERPEnabled] = useState(null);
    const [paymentLineData, setPaymentLineData] = useState([]);
    const [commissionsLineData, setCommissionsLineData] = useState([]);

    const handleReportChange = (type) => {
        setReportType(type);
    };

    useMemo(() => {
        document.addEventListener('outlet-changed', function () {
            setIsOutletChanged(prev => !prev);
        })
    }, []);

    const resetFilter = (e) => {
        e.preventDefault()
        setGlobalSearch("")
        prepareSearch()
    }

    const prepareSearch = (key, value) => {
        let sr = { ...search };
        if (String(value).length > 0) {
            sr[key] = value;
        } else {
            delete sr[key];
        }
        setSearch(sr);
    }

    useEffect(() => {
        const getData = setTimeout(() => {
            setLoader(true);
            const formData = new FormData();
            formData.append("page", params.id)
            formData.append("per_page", itemPerPage)
            formData.append('outlet_id', getOutletId());
            formData.append('report_type', reportType);
            formData.append("global_search", globalSearch)
            erpList(formData).then(data => {
                const response = data && data.data && data.data.response;
                setBusinessDate(response ? response.business_date : "");
                setOutletName(response ? response.outlet_name : "");
                setInvoiceLineData(response ? response.invoice_lines_list : []);
                setPaymentLineData(response ? response.payments_line_list : []);
                setCommissionsLineData(response ? response.commissions_line_list : []);
                setDataLength(response ? response.count : 0);
                setSelectedRecords([]);
                setERPEnabled(response.erp_enabled);
                setTimeout(() => {
                    setLoader(false)
                }, LOADER_TIME);
            }).catch(error => {
                console.log("error ====> ", error);
                setTimeout(() => {
                    setLoader(false)
                }, LOADER_TIME);
            })
        }, 300);
        return () => clearTimeout(getData);
    }, [isOutletChanged, page, statsUpdate, globalSearch])

    // Toggle individual checkbox selection using array index as identifier
    const handleCheckboxChange = (index) => {
        setSelectedRecords(prev => {
            if (prev.includes(index)) {
                return prev.filter(id => id !== index);
            } else {
                return [...prev, index];
            }
        });
    };

    // Select or deselect all checkboxes
    const handleSelectAll = (e) => {
        if (e.target.checked) {
            const allIndices = invoiceLineData.flat().map((_, index) => index);
            setSelectedRecords(allIndices);
        } else {
            setSelectedRecords([]);
        }
    };

    const handleSyncToERP = async (isReverseSync, singleRecord = null) => {
        console.log("isReverseSync", isReverseSync);
        if (!(isReverseSync === true || isReverseSync === "true") && selectedRecords.length === 0) {
            Swal.fire({
                customClass: 'swal-wide',
                icon: 'warning',
                title: "Please select at least one record to sync",
                showConfirmButton: false,
                timer: 2000,
                toast: true,
                position: 'top-right',
            });
            return;
        }

        setLoader(true);
        const formData = new FormData();
        formData.append('outlet_id', getOutletId());

        const flatData = invoiceLineData.flat();
        let selectedOrderModeIds = [];

        if (isReverseSync === true || isReverseSync === "true") {
            selectedOrderModeIds = [{
                order_mode_id: singleRecord.orderModeID,
                business_date: singleRecord.business_date
            }];
        } else {
            // Normal sync: Multiple records selected
            selectedOrderModeIds = selectedRecords.map(index => ({
                order_mode_id: flatData[index].orderModeID,
                business_date: flatData[index].business_date
            }));
        }
        formData.append('order_mode_ids', JSON.stringify(selectedOrderModeIds));
        if (isReverseSync === true || isReverseSync === "true") {
            formData.append('is_reverse_sync', isReverseSync);
        }
        try {
            const data = await InventoryEnd(formData);
            console.log(data);
            if (data?.status === 200) {
                Swal.fire({
                    customClass: 'swal-wide',
                    icon: data.data.statuscode === 400 ? 'error' : 'success',
                    title: data?.data?.error?.message || data?.data?.message,
                    showConfirmButton: false,
                    timer: 2000,
                    toast: true,
                    position: 'top-right',
                });

                if (data.data.statuscode !== 400) {
                    setIsReversed(true);
                }

                setStatus(true);
                setSelectedRecords([]);
                setStatusUpdate(!statsUpdate);
            }
            setLoader(false);
        } catch (error) {
            console.log("error ====> ", error);
            setLoader(false);
            setStatus(false);
        }
    };

    const handleERPRegenrate = async (singleRecord = null) => {
        setLoader(true);
        const formData = new FormData();
        if (singleRecord.aggregator_id) {
            formData.append('aggregator_id', singleRecord.aggregator_id);
        } else {
            formData.append('order_mode_id', singleRecord.orderModeID);
        }
        try {
            const data = await erpRegenerateShortCode(formData);
            if (data?.status === 200) {
                Swal.fire({
                    customClass: 'swal-wide',
                    icon: data.data.statuscode === 400 ? 'error' : 'success',
                    title: data?.data?.error?.message || data?.data?.message,
                    showConfirmButton: false,
                    timer: 2000,
                    toast: true,
                    position: 'top-right',
                });
                setStatusUpdate(!statsUpdate);
            }
            setLoader(false);
        } catch (error) {
            console.log("error ====> ", error);
            setLoader(false);
        }
    };


    const handleERPUploadAgain = async (singleRecord = null) => {
        setLoader(true);
        const formData = new FormData();
        let selectedOrderModeIds = [];
        selectedOrderModeIds = [{
            order_mode_id: singleRecord.orderModeID,
            business_date: singleRecord.business_date
        }];
        formData.append('outlet_id', getOutletId());
        formData.append('order_mode_ids', JSON.stringify(selectedOrderModeIds));
        try {
            const data = await InventoryEnd(formData);
            if (data?.status === 200) {
                Swal.fire({
                    customClass: 'swal-wide',
                    icon: data.data.statuscode === 400 ? 'error' : 'success',
                    title: data?.data?.error?.message || data?.data?.message,
                    showConfirmButton: false,
                    timer: 2000,
                    toast: true,
                    position: 'top-right',
                });
                setStatusUpdate(!statsUpdate);
            }
            setLoader(false);
        } catch (error) {
            console.log("error ====> ", error);
            setLoader(false);
        }
    };

    const ChangeStatus = () => {
        Swal.fire({
            customClass: 'swal-wide',
            title: 'Are you sure?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#2f9bff',
            cancelButtonColor: '#dc3545',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                erpChangeStatus().then(data => {
                    if (data.status === 200) {
                        Swal.fire({
                            customClass: 'swal-wide',
                            position: 'absolute',
                            icon: 'success',
                            title: data.data.message,
                            showConfirmButton: false,
                            timer: 2000,
                            toast: true,
                            position: 'top-right',
                        })

                        setStatusUpdate(!statsUpdate)
                    }
                }).catch(error => {
                    console.log("error===>", error);
                })
            }
        })
    }

    return (
        <>
            <div className="animation_fade">
                <div className="page-header d-flex align-items-center">
                    <div className="page-header-left">
                        <h4>Report</h4>
                    </div>
                </div>
                <div className="card custom-card overflow-hidden">
                    <div className="gap-20 border-bottom">
                        <div className="col-12">
                            <div className="d-flex justify-content-between align-items-center">
                                <ConfigurationReportTab tab="erp" />
                            </div>
                        </div>
                    </div>
                    {reportType == 'sales' ?
                        <div className='gap-20 pb-2 pe-0 border-bottom'>
                            <div className="form-check form-switch mx-auto d-flex justify-content-start">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="status-change"
                                    name="status"
                                    checked={ERPEnabled ? "checked" : ""}
                                    onClick={() => { ChangeStatus() }}
                                />
                                <label
                                    className="form-check-label"
                                    for="status-change"
                                >Enable</label>
                            </div>
                        </div>
                        : null}
                    <div className="gap-20">
                        <div className='page-header d-flex align-items-center mb-0'>
                            <div className='d-flex gap-3 align-items-center'>
                                <div className="form-group mb-0 rangepicker_container">
                                    <input type="search" className="form-control" value={globalSearch} placeholder="Search" onChange={(e) => { setGlobalSearch(e.target.value) }} />
                                </div>
                            </div>
                            <button type="reset" value='Reset' onClick={resetFilter} className="btn btn-primary mr-2 ms-5">Reset Filter</button>
                        </div>
                    </div>
                    <div className='gap-20 ps-0 pb-0 pe-0'>
                        <ul className='d-flex setting submenu-list border-bottom mb-0 p-0'>
                            <li className={reportType === 'sales' ? 'active' : ''} onClick={() => handleReportChange('sales')}>Sales</li>
                            <li className={reportType === 'payments' ? 'active' : ''} onClick={() => handleReportChange('payments')}>Payments</li>
                            <li className={reportType === 'commissions' ? 'active' : ''} onClick={() => handleReportChange('commissions')}>Commissions</li>
                        </ul>
                    </div>
                    {reportType == 'sales' ?
                        <div className="sync-container my-3 ms-2 d-flex justify-content-end me-2">
                            <button
                                className="btn btn-primary"
                                // disabled={!businessDate}
                                type="button"
                                onClick={handleSyncToERP}
                            >
                                Sync To ERP
                            </button>
                        </div>
                        : null}
                    {reportType === 'sales' ? (
                            <div className="tabler-wrap">
                                <div className="table-responsive">
                                    <table className="table table-custom">
                                        <thead>
                                            <tr>
                                                <th width="5%"></th>
                                                <th width="15%">Business Date</th>
                                                <th width="15%">Outlet Name</th>
                                                <th width="15%">Main Account Display Value</th>
                                                <th width="15%">General Number</th>
                                                <th width="15%">Description</th>
                                                <th width="15%">Unit Price</th>
                                                <th width="15%">Status</th>
                                                <th width="15%">Error</th>
                                                <th width="15%">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {loader ? (
                                                <tr>
                                                    <td colSpan={10}>
                                                        <Loader />
                                                    </td>
                                                </tr>
                                            ) : (
                                                <>
                                                    {invoiceLineData.flat().length > 0 ? (
                                                        invoiceLineData.flat().map((line, index) => (
                                                            <tr
                                                                key={index}
                                                                style={{
                                                                    backgroundColor: line?.erp_details?.erp_reversed
                                                                        ? '#ADD8E6'
                                                                        : '',
                                                                }}
                                                            >
                                                                <td>
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={selectedRecords.includes(index)}
                                                                        onChange={() => handleCheckboxChange(index)}
                                                                        disabled={line?.erp_details?.erp_synced || line?.erp_details?.erp_reversed}
                                                                    />
                                                                </td>
                                                                <td>{formateDate(line.business_date)}</td>
                                                                <td>{capitalizeFirstLetter(outletName)}</td>
                                                                <td>{line.MainAccountDisplayValue}</td>
                                                                <td>{line?.erp_details?.invoice_identifier || '-'}</td>
                                                                <td>{line.Description}</td>
                                                                <td>{line.UnitPrice.toFixed(2) + ' SAR'}</td>
                                                                <td>
                                                                    {!line?.erp_details || line?.erp_details?.error ? (
                                                                        <span className="text-danger">Not Uploaded</span>
                                                                    ) : line?.erp_details?.erp_synced === true && !line?.erp_details?.erp_reversed ? (
                                                                        <span className="text-success">Uploaded</span>
                                                                    ) : (
                                                                        <span className="text-warning">Reversed</span>
                                                                    )}
                                                                </td>
                                                                {line?.erp_details?.error ? (
                                                                    <td>
                                                                        <Whisper
                                                                            placement="top"
                                                                            controlId="control-id-hover"
                                                                            trigger="hover"
                                                                            speaker={
                                                                                <Tooltip>
                                                                                    Unable to create free text invoice header. {line?.erp_details?.error?.message}
                                                                                </Tooltip>
                                                                            }
                                                                        >
                                                                            <span className="field-more-info ms-1 cp">
                                                                                <i className="fas fa-exclamation-circle text-danger"></i>
                                                                            </span>
                                                                        </Whisper>
                                                                    </td>
                                                                ) : (
                                                                    <td>-</td>
                                                                )}
                                                                <td className="text-end">
                                                                    <div className="dropdown d-inline-block">
                                                                        <button
                                                                            className="btn btn-icon btn-secondary"
                                                                            type="button"
                                                                            data-bs-toggle="dropdown"
                                                                            aria-expanded="false"
                                                                        >
                                                                            <i className="ti ti-dots-vertical"></i>
                                                                        </button>
                                                                        <ul className="dropdown-menu">
                                                                            {(line?.erp_details?.error || (line?.erp_details?.erp_synced === true && !line?.erp_details?.erp_reversed)) && (
                                                                                <li>
                                                                                    <div
                                                                                        className="dropdown-item"
                                                                                        onClick={() => handleERPUploadAgain(line)}
                                                                                    >
                                                                                        <i className="ti ti-upload" aria-hidden="true"></i> Upload Again
                                                                                    </div>
                                                                                </li>
                                                                            )}
                                                                            {line?.erp_details?.erp_synced === true && !line?.erp_details?.is_reversed && !line?.erp_details?.error && (
                                                                                <li>
                                                                                    <div
                                                                                        className="dropdown-item"
                                                                                        onClick={() => {
                                                                                            handleSyncToERP(true, line);
                                                                                            setIsReverseSync(true);
                                                                                        }}
                                                                                    >
                                                                                        <i className="ti ti-sort-descending" aria-hidden="true"></i> Reverse Order
                                                                                    </div>
                                                                                </li>
                                                                            )}
                                                                            {!line?.erp_details?.erp_synced && !line?.erp_details?.erp_reversed && !line?.erp_details?.error && (
                                                                                <li>
                                                                                    <div
                                                                                        className="dropdown-item"
                                                                                        onClick={() => handleERPRegenrate(line)}
                                                                                    >
                                                                                        <i className="ti ti-recycle" aria-hidden="true"></i> Regenerate
                                                                                    </div>
                                                                                </li>
                                                                            )}
                                                                        </ul>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    ) : (
                                                        <tr>
                                                            <td colSpan={10} className="text-center">
                                                                <img src={notFound} style={{ width: '5%' }} alt="Not Found" />
                                                                <p>No record found</p>
                                                            </td>
                                                        </tr>
                                                    )}
                                                </>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="tabler-footer">
                                    <div className="d-flex align-items-center">
                                        <div id="example1_info" role="status" className="table-result" aria-live="polite">
                                            <b>Total Records: {dataLength ? dataLength : '0'}</b>
                                        </div>
                                        {dataLength && dataLength > 0 ? (
                                            <CustomPagination
                                                datalength={dataLength}
                                                itemperpage={itemPerPage}
                                                setPage={setPage}
                                                currentPage={page}
                                                pageRoute={[{ name: 'report', path: '/company/report/erp/list' }]}
                                            />
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                </div>
                            </div>
                        ) : reportType === 'payments' ? (
                            <div className="tabler-wrap">
                                <div className="table-responsive">
                                    <table className="table table-custom">
                                        <thead>
                                            <tr>
                                                <th width="15%">Outlet Name</th>
                                                <th width="15%">Journal Batch Number </th>
                                                <th width="15%">Payment Method</th>
                                                <th width="15%">Payment Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {loader ? (
                                                <tr>
                                                    <td colSpan={4}>
                                                        <Loader />
                                                    </td>
                                                </tr>
                                            ) : (
                                                <>
                                                    {paymentLineData.flat().length > 0 ? (
                                                        paymentLineData.flat().map((payment, index) => (
                                                            <tr key={index}>
                                                                <td>{capitalizeFirstLetter(outletName)}</td>
                                                                <td>{(payment?.journal_batch_number || "-")}</td>
                                                                <td>{capitalizeFirstLetter(payment?.payment_method)}</td>
                                                                <td>{formateDate(payment?.payment_time)}</td>
                                                            </tr>
                                                        ))
                                                    ) : (
                                                        <tr>
                                                            <td colSpan={4} className="text-center">
                                                                <img src={notFound} style={{ width: '5%' }} alt="Not Found" />
                                                                <p>No record found</p>
                                                            </td>
                                                        </tr>
                                                    )}
                                                </>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="tabler-footer">
                                    <div className="d-flex align-items-center">
                                        <div id="example1_info" role="status" className="table-result" aria-live="polite">
                                            <b>Total Records: {dataLength ? dataLength : '0'}</b>
                                        </div>
                                        {dataLength && dataLength > 0 ? (
                                            <CustomPagination
                                                datalength={dataLength}
                                                itemperpage={itemPerPage}
                                                setPage={setPage}
                                                currentPage={page}
                                                pageRoute={[{ name: 'report', path: '/company/report/erp/list' }]}
                                            />
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                </div>
                            </div>
                        ) : reportType === 'commissions' ? (
                            <div className="tabler-wrap">
                                <div className="table-responsive">
                                    <table className="table table-custom">
                                        <thead>
                                            <tr>
                                                <th width="15%">Outlet Name</th>
                                                <th width="15%">Journal Batch Number </th>
                                                <th width="15%">Payment Method</th>
                                                <th width="15%">Payment Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {loader ? (
                                                <tr>
                                                    <td colSpan={2}>
                                                        <Loader />
                                                    </td>
                                                </tr>
                                            ) : (
                                                <>
                                                    {commissionsLineData.flat().length > 0 ? (
                                                        commissionsLineData.flat().map((commissions, index) => (
                                                            <tr key={index}>
                                                                <td>{capitalizeFirstLetter(outletName)}</td>
                                                                <td>{(commissions?.journal_batch_number || "-")}</td>
                                                                <td>{capitalizeFirstLetter(commissions?.payment_method)}</td>
                                                                <td>{formateDate(commissions?.payment_time)}</td>
                                                            </tr>
                                                        ))
                                                    ) : (
                                                        <tr>
                                                            <td colSpan={2} className="text-center">
                                                                <img src={notFound} style={{ width: '5%' }} alt="Not Found" />
                                                                <p>No record found</p>
                                                            </td>
                                                        </tr>
                                                    )}
                                                </>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="tabler-footer">
                                    <div className="d-flex align-items-center">
                                        <div id="example1_info" role="status" className="table-result" aria-live="polite">
                                            <b>Total Records: {dataLength ? dataLength : '0'}</b>
                                        </div>
                                        {dataLength && dataLength > 0 ? (
                                            <CustomPagination
                                                datalength={dataLength}
                                                itemperpage={itemPerPage}
                                                setPage={setPage}
                                                currentPage={page}
                                                pageRoute={[{ name: 'report', path: '/company/report/erp/list' }]}
                                            />
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                </div>
                            </div>
                        ) : null
                    }

                </div>
            </div>
        </>
    );
}

export default ERPConfigurationList;
