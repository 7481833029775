import React, { useState, useEffect, useMemo } from 'react'
import { useFormik } from 'formik';
import { CreateOutlet, GetRoles } from '../../../services/company_panel/platform_user/platformUserService';
import { GetOutletAllList } from '../../../services/company_panel/outletService';
import { useNavigate } from 'react-router-dom';
import Swal from "sweetalert2"
import Breadcrums from '../../../components/company_panel/common/breadcrumbs';
import { countries } from 'countries-list';
import { Dropdown } from 'semantic-ui-react';
import { blockInvalidChar, capitalizeAllFirstLetters, companyDetail, getBrandId, getCompany, getMobileLength } from '../../../helpers/commonFunctions';
import * as yup from 'yup';
import { ToastContainer } from 'react-toastify';
import ReactCountryFlag from "react-country-flag";
import 'react-phone-input-2/lib/style.css'
import { SelectPicker } from 'rsuite';
import defaultImage from '../../../../src/assets/company/img/default_img.png'
import { Form, InputGroup } from 'react-bootstrap';
import { getAllAreas } from '../../../services/company_panel/setting/settingAreaServices';
import CustomMultiSelect from '../../../components/common/CustomMultiSelect';
import OtpInput from 'react-otp-input';

const OutletUserAdd = () => {

  const breadcrumbs = [
    {
      title: "Platform User ",
      url: "/company/business/platform-User/outlet-user/list/1"
    },
    {
      title: "Outlet Users",
      url: "/company/business/platform-User/outlet-user/list/1"
    },
    {
      title: "Create Platform User",
      url: ""
    },
  ]
  const navigate = useNavigate()
  const [previewImage, setPreviewImage] = useState("");
  const [submitted, setSubmitted] = useState(false)
  const [selectedCountryCode, setSelectedCountryCode] = useState([]);
  const [outletList, setOutletList] = useState([]);
  const [areaList, setAreaList] = useState([]);
  const [roles, setRoles] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [roleSlug, setRoleSlug] = useState(null);
  const [isBrandChanged, setIsBrandChanged] = useState(false);
  const [phoneNoLength, setPhoneNoLength] = useState(getMobileLength(companyDetail().country_code));
  const [selectedTime, setSelectedTime] = useState({
    sunday_opening_time: null,
    monday_opening_time: null,
    tuesday_opening_time: null,
    wednesday_opening_time: null,
    thursday_opening_time: null,
    friday_opening_time: null,
    saturday_opening_time: null,
    sunday_closing_time: null,
    monday_closing_time: null,
    tuesday_closing_time: null,
    wednesday_closing_time: null,
    thursday_closing_time: null,
    friday_closing_time: null,
    saturday_closing_time: null,
  });
  const storeTime = [
    { value: 'monday', label: 'Monday' },
    { value: 'tuesday', label: 'Tuesday' },
    { value: 'wednesday', label: 'Wednesday' },
    { value: 'thursday', label: 'Thursday' },
    { value: 'friday', label: 'Friday' },
    { value: 'saturday', label: 'Saturday' },
    { value: 'sunday', label: 'Sunday' },
  ];

  useMemo(() => {
    document.addEventListener('brand-changed', function () {
      setIsBrandChanged(!isBrandChanged);
    });
  });

  useEffect(() => {
    const options = Object.keys(countries).map(countryCode => ({
      label: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <ReactCountryFlag
            countryCode={countryCode}
            style={{ width: '1.5em', height: '1.5em', marginRight: '0.5em' }}
            aria-label={countryCode}
          />
          {` + ${countries[countryCode].phone}`}
        </div>
      ),
      value: countries[countryCode].phone
    }));
    setSelectedCountryCode(options);
    GetRoles(getCompany().company_id).then(data => {
      const allRoles = data && data.data && data.data.response ? data.data.response : []
      setRoles(allRoles);
      const roleDropdown = allRoles.map((res) => ({
        key: res.id,
        text: capitalizeAllFirstLetters(res.name),
        value: res.id,
      }))
      setRoleList(roleDropdown)
    }).catch(error => {
      console.log("error=====>", error)
    })
  }, [])

  useEffect(() => {
    GetOutletAllList(getCompany().company_id).then(data => {
      setOutletList(data && data.data && data.data.response ? data.data.response : []);
    }).catch(error => {
      console.log("error=====>", error)
    })
  }, [])

  useEffect(() => {
    getAllAreas({ brand_id: getBrandId() }).then(data => {
      setAreaList(data && data.data && data.data.response ? data.data.response : []);
    }).catch(error => {
      console.log("error=====>", error)
    })
  }, [isBrandChanged])


  const handleTimeChange = (flag, time) => {
    setSelectedTime(prevState => ({
      ...prevState,
      [flag]: time
    }));

  };
  const addMinutes = (time, minsToAdd) => {
    const [hours, minutes] = time.split(':').map(Number);
    const date = new Date();
    date.setHours(hours, minutes + minsToAdd);
    const newHours = date.getHours().toString().padStart(2, '0');
    const newMinutes = date.getMinutes().toString().padStart(2, '0');
    return `${newHours}:${newMinutes}`;
  };

  const outListData =
    outletList &&
    outletList.map((item) => ({ label: capitalizeAllFirstLetters(item?.outlet_name), value: item?.id }));

  const areaListData =
    areaList &&
    areaList.map((item) => ({ label: capitalizeAllFirstLetters(item?.name), value: item?.id }));


  const validationSchema = yup.object().shape({
    first_name: yup.string().trim().required('First Name is required').min(3, "First Name must be at least 3 digits").max(30, 'First Name cannot exceed 30 digits'),
    first_name_ar: yup.string().trim().required('First Name (Arabic) is required').min(3, "First Name (Arabic) must be at least 3 digits").max(30, 'First Name (Arabic) cannot exceed 30 digits'),
    last_name: yup.string().trim().required('Last Name is required').min(3, "Last Name must be at least 3 digits").max(30, 'Last Name cannot exceed 30 digits'),
    last_name_ar: yup.string().trim().required('Last Name (Arabic) is required').min(3, "Last Name (Arabic) must be at least 3 digits").max(30, 'Last Name (Arabic) cannot exceed 30 digits'),
    country_code: yup.array().required('Country code is required'),
    mobile: yup
      .string()
      .trim()
      .matches(/^[0-9]+$/, "Phone number must contain only digits")
      .length(phoneNoLength,`Phone number must be of length ${phoneNoLength}`)
      .required('Phone number is required'),
    role_id: yup.string().trim().required('Role is required'),
    employment_type: yup.string().trim().required('Employment type is required'),
    shift: yup.string().trim().required('Shift is required'),
    email: yup.string()
      .email('Invalid email address')
      .required('Email is required')
      .matches(/^[^\s]+[^\s]+\.[^\s]+$/, 'Invalid email address')
      .matches(/^[^\s#$%&*?]+$/, {
        excludeEmptyString: true,
        message: 'Email should not contain special characters like #$%&*?',
      }),
    user_login_pin: yup
      .string()
      .min(4, 'PIN must be at least 4 digits')
      .max(4, 'PIN must be exactly 4 digits')
      .required('This field is required'),
    type_of_user: roleSlug && roleSlug == 'other' ? yup.string().trim().required('Type of user is required') : null,
    outlet_id: roleSlug && roleSlug == 'other' ? yup.array().min(1, 'At least one outlet must be selected').required('Outlet is required') : null,
    area_id: roleSlug && roleSlug == 'area_manager' ? yup.array().min(1, 'At least one area must be selected').required('Area is required') : null,
  });


  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      logo: "",
      first_name: "",
      first_name_ar: "",
      last_name: "",
      last_name_ar: "",
      email: "",
      country_code: [Number(companyDetail().country_code)],
      mobile: "",
      role_id: "",
      type_of_user: "",
      outlet_id: [],
      employment_type: "",
      shift: "",
      user_login_pin: "",
      area_id: [],
    },
    validationSchema: validationSchema,
    onSubmit: values => {

      setSubmitted(true)
      let formData = new FormData();
      formData.append('profile_image', values.logo);
      formData.append('first_name', values.first_name);
      formData.append('first_name_ar', values.first_name_ar);
      formData.append('last_name', values.last_name);
      formData.append('last_name_ar', values.last_name_ar);
      formData.append('email', values.email);
      formData.append('country_code', values.country_code);
      formData.append('mobile', values.mobile);
      formData.append('role_id', values.role_id);
      formData.append('employment_type', values.employment_type);
      formData.append('shift', values.shift);
      formData.append('is_sunday', values.is_sunday ? values.is_sunday : '');
      formData.append('is_monday', values.is_monday ? values.is_monday : '');
      formData.append('is_tuesday', values.is_tuesday ? values.is_tuesday : '');
      formData.append('is_wednesday', values.is_wednesday ? values.is_wednesday : '');
      formData.append('is_thursday', values.is_thursday ? values.is_thursday : '');
      formData.append('is_friday', values.is_friday ? values.is_friday : '');
      formData.append('is_saturday', values.is_saturday ? values.is_saturday : '');
      formData.append('sunday_opening_time', selectedTime.sunday_opening_time ? selectedTime.sunday_opening_time : '');
      formData.append('monday_opening_time', selectedTime.monday_opening_time ? selectedTime.monday_opening_time : '');
      formData.append('tuesday_opening_time', selectedTime.tuesday_opening_time ? selectedTime.tuesday_opening_time : '');
      formData.append('wednesday_opening_time', selectedTime.wednesday_opening_time ? selectedTime.wednesday_opening_time : '');
      formData.append('thursday_opening_time', selectedTime.thursday_opening_time ? selectedTime.thursday_opening_time : '');
      formData.append('friday_opening_time', selectedTime.friday_opening_time ? selectedTime.friday_opening_time : '');
      formData.append('saturday_opening_time', selectedTime.saturday_opening_time ? selectedTime.saturday_opening_time : '');
      formData.append('sunday_closing_time', selectedTime.sunday_closing_time ? selectedTime.sunday_closing_time : '');
      formData.append('monday_closing_time', selectedTime.monday_closing_time ? selectedTime.monday_closing_time : '');
      formData.append('tuesday_closing_time', selectedTime.tuesday_closing_time ? selectedTime.tuesday_closing_time : '');
      formData.append('wednesday_closing_time', selectedTime.wednesday_closing_time ? selectedTime.wednesday_closing_time : '');
      formData.append('thursday_closing_time', selectedTime.thursday_closing_time ? selectedTime.thursday_closing_time : '');
      formData.append('friday_closing_time', selectedTime.friday_closing_time ? selectedTime.friday_closing_time : '');
      formData.append('saturday_closing_time', selectedTime.saturday_closing_time ? selectedTime.saturday_closing_time : '');
      formData.append('user_login_pin', values.user_login_pin);
      formData.append('company_id', getCompany().company_id);

      let role = 2;
      // if (roleSlug == 'area_manager') {
      //   role = 3;
      // } else {
      //   role = 2;
      // }
      formData.append('role', role);

      if (roleSlug == 'area_manager') {
        formData.append('area_id', JSON.stringify(values.area_id));
      }else{
        // roleSlug == 'other'
        formData.append('type_of_user', values.type_of_user);
        formData.append('outlet_id', JSON.stringify(values.outlet_id));
      }

      CreateOutlet(formData).then(response => {
        if (response?.data?.status === 1 || response?.data?.status === "1") {
          Swal.fire({
            customClass: 'swal-wide',
            icon: 'success',
            title: response.data.message,
            showConfirmButton: false,
            timer: 1500,
            toast: true,
            position: 'top-right',
          });
          setTimeout(() => {
            navigate(`/company/business/platform-User/outlet-user/list/${1}`)
          }, 1000);
        } else {
          setSubmitted(false)
        }
      }).catch(error => {
        console.log("error ====> ", error);
      })
    }
  });

  var otp_inputs = document.querySelectorAll(".otp__digit")
  var mykey = "0123456789".split("")
  otp_inputs.forEach((_) => {
    _.addEventListener("keyup", handle_next_input)
  })
  function handle_next_input(event) {
    let current = event.target
    let index = parseInt(current.classList[1].split("__")[2])
    current.value = event.key

    if (event.keyCode == 8 && index > 1) {
      current.previousElementSibling.focus()
    }
    if (index < 4 && mykey.indexOf("" + event.key + "") != -1) {
      var next = current.nextElementSibling;
      next.focus()
    }
    var _finalKey = ""
    for (let { value } of otp_inputs) {
      _finalKey += value
    }
    if (_finalKey.length == 4) {

      formik.setFieldValue("user_login_pin", _finalKey);
    } else {
    }
  }

  const onChangeRole = (roleId) => {
    const roleData = roles.find((role) => role.id === roleId);
    if (roleData.slug) {
      setRoleSlug(roleData.slug);
    } else {
      setRoleSlug('other');
    }
  };


  return (
    <>
      <div className="page-header d-flex align-items-center">
        <div className="page-header-left">
          <Breadcrums data={breadcrumbs} />
          <h4>Platform User</h4>
        </div>
        <div className="page-header-right ms-auto">
        </div>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="row row-sm">
          <div className="card custom-card">
            <div className="card-body">
              <div className="row row-sm">

                <div className='col-md-12'>
                  <div className='upload-logo d-flex align-items-center gap-3 mb-3'>
                    <div className="avatar avatar-xl">
                      {previewImage ?
                        <img alt="avatar" src={previewImage} className={previewImage ? "" : "d-none"} />
                        :
                        <img alt="avatar" src={defaultImage} />
                      }
                    </div>
                    <div className='btn btn-black btn-file-type' style={{ cursor: 'pointer' }}>Upload logo <input style={{ cursor: 'pointer' }} id="logo" name="logo" accept="image/*" type="file" onChange={(event) => {
                      formik.setFieldValue("logo", event.currentTarget.files[0] || "");
                      { event.currentTarget.files.length == 1 ? (setPreviewImage(URL.createObjectURL(event.currentTarget.files[0]))) : (setPreviewImage("")) }
                    }}
                    /></div>
                  </div>
                  <span className='text-danger d-flex text-left' id='errortext'>
                    {formik.errors.logo && formik.touched.logo && formik.errors.logo}
                  </span>
                </div>
                <div className='col-lg-6 text-center form-group mt-3'>
                  <label htmlFor='first_name' className='text-left d-flex'>First Name <span className="requirestar">*</span></label>
                  <input name='first_name' id="first_name" type='text' placeholder='Enter first name' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.first_name} className='form-control' />
                  <span className='text-danger d-flex text-left'>{formik.errors.first_name && formik.touched.first_name && formik.errors.first_name}</span>
                </div>
                <div className='col-lg-6 text-center form-group mt-3'>
                  <label htmlFor='first_name_ar' className='text-left d-flex'>First Name (Arabic) <span className="requirestar">*</span></label>
                  <input name='first_name_ar' id="first_name_ar" type='text' placeholder='Enter first name (Arabic)' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.first_name_ar} className='form-control' />
                  <span className='text-danger d-flex text-left'>{formik.errors.first_name_ar && formik.touched.first_name_ar && formik.errors.first_name_ar}</span>
                </div>
                <div className='col-lg-6 text-center form-group mt-3'>
                  <label htmlFor='last_name' className='text-left d-flex'>Last Name <span className="requirestar">*</span></label>
                  <input name='last_name' id="last_name" type='text' placeholder='Enter last name' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.last_name} className='form-control' />
                  <span className='text-danger d-flex text-left'>{formik.errors.last_name && formik.touched.last_name && formik.errors.last_name}</span>
                </div>
                <div className='col-lg-6 text-center form-group mt-3'>
                  <label htmlFor='last_name_ar' className='text-left d-flex'>Last Name (Arabic) <span className="requirestar">*</span></label>
                  <input name='last_name_ar' id="last_name_ar" type='text' placeholder='Enter last name (Arabic)' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.last_name_ar} className='form-control' />
                  <span className='text-danger d-flex text-left'>{formik.errors.last_name_ar && formik.touched.last_name_ar && formik.errors.last_name_ar}</span>
                </div>
                <div className='col-lg-6 text-center form-group mt-3'>
                  <label htmlFor='email' className='text-left d-flex'>Email <span className="requirestar">*</span></label>
                  <input name='email' id="email" type='email' placeholder='Enter email' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.email} className='form-control' />
                  <span className='text-danger d-flex text-left'>{formik.errors.email && formik.touched.email && formik.errors.email}</span>
                </div>
                <div className='col-lg-6 text-center form-group mt-3'>
                  <label htmlFor='name' className='text-left d-flex'>Phone number <span className="requirestar">*</span></label>
                  <div className="input-group">
                    <span className=''>
                      <SelectPicker
                        style={{ border: 'none', paddingLeft: '0', paddingRight: '0' }}
                        data={selectedCountryCode}
                        name='country_code'
                        className='form-control'
                        id='country_code'
                        onChange={(value) => {formik.setFieldValue("country_code", value); setPhoneNoLength(() => getMobileLength(value));}}
                        onBlur={formik.handleBlur}
                        value={formik.values.country_code}
                        placeholder="Code"
                      />
                    </span>
                    <input
                      type="text"
                      name='mobile'
                      id="mobile"
                      className="form-control"
                      placeholder="Enter phone number"
                      value={formik.values.mobile}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      onKeyDown={blockInvalidChar}
                    />
                  </div>
                  <span className='text-danger d-flex text-left'>{formik.errors.mobile && formik.touched.mobile && formik.errors.mobile}</span>
                  <span className='text-danger d-flex text-left'>{formik.errors.country_code && formik.touched.country_code && formik.errors.country_code}</span>
                </div>
                <div className='col-lg-6 text-center form-group mt-3'>
                  <label htmlFor='role_id' className='text-left d-flex'>Role <span className="requirestar">*</span></label>
                  <Dropdown
                    className='form-control'
                    id="role_id"
                    placeholder='Select role'
                    search
                    fluid
                    selection
                    options={roleList}
                    value={formik.values.role_id}
                    onChange={(event, data) => { formik.setFieldValue("role_id", data.value); onChangeRole(data.value) }}
                  />
                  <span className='text-danger d-flex text-left'>{formik.errors.role_id && formik.touched.role_id && formik.errors.role_id}</span>
                </div>

                {
                  roleSlug && roleSlug == 'other' ?
                    <div className='col-lg-6 text-center form-group mt-3'>
                      <label htmlFor='type_of_user' className='text-left d-flex'>Type of user  <span className="requirestar">*</span></label>
                      <select id="type_of_user" className='form-control' name='type_of_user' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.type_of_user}>
                        <option value="">Select type</option>
                        <option value="store_manager">Store manager</option>
                        <option value="pos">POS</option>
                        <option value="both">Both (Store manager and POS)</option>
                      </select>
                      <span className='text-danger d-flex text-left'>{formik.errors.type_of_user && formik.touched.type_of_user && formik.errors.type_of_user}</span>
                    </div> : null
                }

                {
                  roleSlug && roleSlug == 'area_manager' ?
                    <div className='col-lg-6 text-center form-group mt-3'>
                      <label htmlFor='area_id' className='text-left d-flex'>
                        Area <span className="requirestar">*</span>
                      </label>
                      <CustomMultiSelect data={areaListData} overFlowVisibleOptions={1} form={formik} name='area_id' />
                      <span className='text-danger d-flex text-left'>
                        {formik.errors.area_id && formik.touched.area_id && formik.errors.area_id}
                      </span>
                    </div> : null
                }

                {
                  roleSlug && roleSlug == 'other' ?
                    <div className='col-lg-6 text-center form-group mt-3'>
                      <label htmlFor='outlet_id' className='text-left d-flex'>
                        Outlet <span className="requirestar">*</span>
                      </label>
                      <CustomMultiSelect data={outListData} overFlowVisibleOptions={1} form={formik} name='outlet_id' />
                      <span className='text-danger d-flex text-left'>
                        {formik.errors.outlet_id && formik.touched.outlet_id && formik.errors.outlet_id}
                      </span>
                    </div> : null
                }

                <div className='col-lg-6 text-center form-group mt-3'>
                  <label for="employment_type" className="text-left d-flex">Employment Type<span className="requirestar">*</span></label>
                  <div className='row ps-3 mt-3'>
                    <div className="form-check w-25 text-start">
                      <input className="form-check-input" style={{ cursor: 'pointer' }} type="radio" name="employment_type" id="part-time" value="Part-time" autoComplete='off' onChange={formik.handleChange} onBlur={formik.handleBlur} />
                      <label className="form-check-label" style={{ cursor: 'pointer' }} for="part-time">Part-time</label>
                    </div>
                    <div className="form-check w-25 text-start">
                      <input className="form-check-input" style={{ cursor: 'pointer' }} type="radio" name="employment_type" id="full-time" value="Full-time" autoComplete='off' onChange={formik.handleChange} onBlur={formik.handleBlur} />
                      <label className="form-check-label" style={{ cursor: 'pointer' }} for="full-time">Full-time</label>
                    </div>
                  </div>
                  <span className='text-danger d-flex text-left'>{formik.errors.employment_type && formik.touched.employment_type && formik.errors.employment_type}</span>
                </div>

                <div className='col-lg-12 text-center form-group mt-3'>
                  <label for="order_mode" className="text-left d-flex" style={{ cursor: 'pointer' }}>Shift<span className="requirestar">*</span></label>
                  <div className='row ps-3 mt-3'>
                    <div className="form-check w-25 text-start">
                      <input className="form-check-input" type="radio" style={{ cursor: 'pointer' }} name="shift" id="Morning" value="Morning" autoComplete='off' onChange={formik.handleChange} onBlur={formik.handleBlur} />
                      <label className="form-check-label" for="Morning" style={{ cursor: 'pointer' }}>Morning</label>
                    </div>
                    <div className="form-check w-25 text-start">
                      <input className="form-check-input" type="radio" style={{ cursor: 'pointer' }} name="shift" id="Evening" value="Evening" autoComplete='off' onChange={formik.handleChange} onBlur={formik.handleBlur} />
                      <label className="form-check-label" for="Evening" style={{ cursor: 'pointer' }}  >Evening</label>
                    </div>
                    <div className="form-check w-25 text-start">
                      <input className="form-check-input" type="radio" style={{ cursor: 'pointer' }} name="shift" id="Afternoon" value="Afternoon" autoComplete='off' onChange={formik.handleChange} onBlur={formik.handleBlur} />
                      <label className="form-check-label" style={{ cursor: 'pointer' }} for="Afternoon">Afternoon</label>
                    </div>
                  </div>
                  <span className='text-danger d-flex text-left'>{formik.errors.shift && formik.touched.shift && formik.errors.shift}</span>
                </div>
                <div className='col-lg-6 text-center form-group mt-3'>
                  <label htmlFor='name' className='text-left d-flex mb-2'>Working Days & Time</label>
                  <table width='100%'>
                    {storeTime.map((row, i) => (
                      <tr key={i}>
                        <td className='px-2 py-3'>
                          <div className="form-check w-25">
                            <input
                              style={{ cursor: 'pointer' }}
                              className="form-check-input"
                              type="checkbox"
                              name={`is_${row.value}`}
                              id={`is_${row.value}`}
                              value="1"
                              onChange={(e) => {
                                formik.handleChange(e);
                                formik.setFieldValue(`is_${row.value}`, e.target.checked);
                              }}
                              onBlur={formik.handleBlur}
                              autoComplete='off'
                            />
                            <label className="form-check-label" htmlFor={`is_${row.value}`} style={{ cursor: 'pointer' }}>{row.label}</label>
                          </div>
                        </td>
                        <td className='p-2'>
                          {formik.values[`is_${row.value}`] && (
                            <InputGroup>
                              <Form.Control
                                type="time"
                                placeholder="Opening Time"
                                onChange={(event) => {
                                  const time = event.target.value;
                                  handleTimeChange(row.value + '_opening_time', time)
                                }}
                              />
                            </InputGroup>
                          )}
                        </td>
                        <td className='p-2 pe-0'>
                          {formik.values[`is_${row.value}`] && (
                            <InputGroup>
                              <Form.Control
                                type="time"
                                placeholder="Closing Time"
                                min={selectedTime[row.value + '_opening_time'] ? addMinutes(selectedTime[row.value + '_opening_time'], 5) : undefined}
                                onChange={(event) => {
                                  const time = event.target.value;
                                  handleTimeChange(row.value + '_closing_time', time)
                                }}
                              />
                            </InputGroup>
                          )}
                        </td>
                      </tr>
                    ))}
                  </table>

                </div>
                <div className='col-lg-6 text-center form-group mt-3'></div>
                <div className='col-lg-6 text-center form-group mt-3'>
                  <label htmlFor='user_login_pin' className='text-left d-flex'>
                    Create Login PIN<span className="requirestar">*</span>
                  </label>
                  <div className='row mt-2'>
                    <OtpInput
                      inputStyle={{
                        width: "114px",
                        height: "1.5em",
                        padding: "22px",
                        fontSize: "20px",
                        borderRadius: 4,
                        marginRight: '20px',
                        border: "1px solid rgba(232, 232, 232, 1)"
                      }}
                      renderInput={(props) => <input {...props} />}
                      inputType='number'
                      numInputs={4}
                      separator={<span>-</span>}
                      value={formik.values.user_login_pin}
                      onChange={(value) => formik.setFieldValue('user_login_pin', value)}
                    />
                    <div className='col-lg-4'></div>
                  </div>
                  <span className='text-danger d-flex text-left'>
                    {formik.errors.user_login_pin && formik.touched.user_login_pin && formik.errors.user_login_pin}
                  </span>
                </div>

                <div className="mt-4">
                  <button className="btn btn-primary mr-2" type="submit" >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form >
      <ToastContainer
        position="top-right"
        hideProgressBar={false}
        autoClose={true | 1000}
        newestOnTop={true}
        closeOnClick={false}
        draggable={false}
        rtl={false}
      />
    </>
  );
}

export default OutletUserAdd