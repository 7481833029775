import React, { useState, useEffect, useMemo } from 'react'
import { useFormik } from 'formik';
import { Link, useNavigate } from 'react-router-dom';
import Swal from "sweetalert2"
import { Dropdown } from 'semantic-ui-react';
import * as yup from 'yup';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { capitalizeAllFirstLetters, capitalizeFirstLetter, getBrandId, getCompany } from '../../../helpers/commonFunctions';
import { GetBrandOutletsList, GetOutletAllList } from '../../../services/company_panel/outletService';
import Breadcrums from '../../../components/company_panel/common/breadcrumbs';
import { TagPicker } from 'rsuite';
import { AddPromo } from '../../../services/company_panel/coupons.service';
import { BUY, CHOOSE_BUY_ITEMS, CHOOSE_GET_ITEMS, DURATION, GET, USER, WEEK } from '../../../utils/Constants';
import CustomDatetimepicker from '../../../helpers/customDatepicker';
import { globalLoader } from '../../../helpers/global'
import { GetActiveOrderModes } from '../../../services/company_panel/setting/orderModeConfigurationService';
import { AllUsers } from '../../../services/company_panel/platform_user/platformUserService';
import { getAllMenuItems } from '../../../services/company_panel/mealService'
import CustomMultiSelect from '../../../components/common/CustomMultiSelect';

const PromosAdd = () => {

    const breadcrumbs = [
        {
            title: "Coupons ",
            url: "/company/crm/coupons/coupon/list/1"
        },
        {
            title: "Promos ",
            url: "/company/crm/coupons/promos/list/1"
        },
        {
            title: "Create Promos",
            url: ""
        },
    ]
    const navigate = useNavigate()
    const [outletList, setOutletList] = useState([]);
    const [data, setData] = useState([]);
    const [orderModeData, setOrderModesData] = useState([]);
    // const [menuItemData, setMenuItemsData] = useState([]);
    const [loader, setLoader] = useState(true);
    const [isBrandChanged, setIsBrandChanged] = useState(false);
    const [currentBrand] = useState(getBrandId());

    useMemo(() => {
        document.addEventListener('brand-changed', function () {
            setIsBrandChanged(!isBrandChanged);
        });
        if (currentBrand != getBrandId()) {
            navigate('/company/crm/coupons/promos/list/1')
        }
    });

    useEffect(() => {
        GetBrandOutletsList({ brand_id: getBrandId() }).then(data => {
            setOutletList(data && data.data && data.data.response ? data.data.response : []);
            setLoader(false);
        }).catch(error => {
            console.log("error=====>", error)
        })
    }, [isBrandChanged])

    useEffect(() => {
        AllUsers({ company_id: getCompany().company_id }).then(data => {
            setData(data && data.data && data.data.response ? data.data.response : []);
            setLoader(false);
        }).catch(error => {
            console.log("error=====>", error)
        })
    }, [])

    useEffect(() => {
        GetActiveOrderModes(getBrandId()).then(data => {
            setOrderModesData(data && data.data && data.data.response ? data.data.response : []);
            setLoader(false);
        }).catch(error => {
            console.log("error=====>", error)
        })
    }, [isBrandChanged])

    // useEffect(() => {
    //     getAllMenuItems({ brand_id: getBrandId() }).then(data => {
    //         if (data && data.data && data.data.response) {
    //             setMenuItemsData(data.data.response.rows);
    //         }
    //         setLoader(false);
    //     }).catch(error => {
    //         console.log("error=====>", error);
    //     });
    // }, [isBrandChanged]);


    const validationSchema = yup.object().shape({
        name: yup.string().trim().required('Name is required'),
        promo_code: yup.string().trim().required('Promo code is required'),
        discountItemRestriction: yup.string().trim().required('This is required'),
        usageCondition: yup.string().trim().required('Usage condition is required'),
        // usagePerCustomer: yup.string().trim().required('Usage per customer is required'),
        customerType: yup.string().trim().required('Customer type is required'),
        authenticationType: yup.string().trim().required('Authentication type is required'),
        offerType: yup.string().trim().required('Offer type is required'),
        start_date: yup.date()
            .required("Start date is required")
            .test({
                name: 'start-date-check',
                message: 'Start date cannot be after end date',
                test: function (value) {
                    const { end_date } = this.parent;
                    if (value && end_date) {
                        return new Date(value) <= new Date(end_date);
                    }
                    return true;
                },
            }),
        end_date: yup.date()
            .required("End date is required")
            .test({
                name: 'end-date-check',
                message: 'End date cannot be before start date',
                test: function (value) {
                    const { start_date } = this.parent;
                    if (value && start_date) {
                        return new Date(value) >= new Date(start_date);
                    }
                    return true;
                },
            }),
        start_time: yup.string().trim().required('Start time is required'),
        end_time: yup.string().trim().required('End time is required'),
    });

    const validateNumber = (value) => {
        if (value === undefined || value === null || value === '') {
            return 'This field is required';
        }
        const number = parseInt(value, 10);
        if (isNaN(number) || number.toString() !== value.toString()) {
            return 'This field must be a whole number';
        }
        // if (number < 1 || number > 9999) {
        //     return 'This field must be between 1 and 9999';
        // }
        return null;
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: "",
            promo_code: "",
            buy: "",
            // buy_items: [],
            get: "",
            // choose_get_items: [],
            offerType: "",
            discountItemRestriction: "",
            applicable_on: [],
            outlet_id: [],
            usageCondition: "",
            usagePerCustomer: "",
            customerType: "",
            authenticationType: "",
            authenticateUser: [],
            offer_amount: '',
            discount_percentage: '',
            usage_condition_number: '',
            usage_per_customer_number: '',
            start_date: '',
            end_date: '',
            start_time: '',
            end_time: '',
            week: [],
        },
        validationSchema: validationSchema,
        validate: values => {
            const errors = {};
            // if (values?.usageCondition == "number_of_times") {
            //     if (!values.usage_condition_number) errors.usage_condition_number = "This is required";
            // }
            // if (values?.usagePerCustomer == "number_of_times") {
            //     if (!values.usage_per_customer_number) errors.usage_per_customer_number = "This is required";
            // }
            if (values?.usageCondition === "number_of_times") {
                const usageConditionError = validateNumber(values.usage_condition_number);
                if (usageConditionError) {
                    errors.usage_condition_number = usageConditionError;
                }
            }
            if (values?.usagePerCustomer === "number_of_times") {
                const usagePerCustomerError = validateNumber(values.usage_per_customer_number);
                if (usagePerCustomerError) {
                    errors.usage_per_customer_number = usagePerCustomerError;
                }
            }
            if (values?.offerType == "flat_amount") {
                if (!values.offer_amount) errors.offer_amount = "This filed is required";
            }
            // if (values.offerType == "discount_type") {
            //     if (!values.discount_percentage) errors.discount_percentage = "This filed is required";
            // }
            if (values.offerType == "discount_percentage") {
                if (!values.discount_percentage) {
                    errors.discount_percentage = "This field is required";
                } else if (values.discount_percentage <= 0) {
                    errors.discount_percentage = "Discount percentage must be greater than 0";
                } else if (values.discount_percentage > 100) {
                    errors.discount_percentage = "Discount percentage cannot be greater than 100";
                } else if (values.discount_percentage % 1 !== 0) {
                    errors.discount_percentage = "Discount percentage must be an integer (no decimals allowed)";
                }
            }
            if (values?.authenticationType == "authentication_approval") {
                if (!values.authenticateUser || values.authenticateUser.length === 0) errors.authenticateUser = "This field is required";
            }
            if (!values?.applicable_on || values.applicable_on.length === 0) {
                errors.applicable_on = "This field is required";
            }
            if (!values?.week || values.week.length === 0) {
                errors.week = "This field is required";
            }
            if (!values?.outlet_id || values.outlet_id.length === 0) {
                errors.outlet_id = "This field is required";
            }
            // if (!values?.buy_items || values.buy_items.length === 0) {
            //     errors.buy_items = "This field is required";
            // }
            // if (!values?.choose_get_items || values.choose_get_items.length === 0) {
            //     errors.choose_get_items = "This field is required";
            // }
            if (!values?.get || values.get.length === 0) {
                errors.get = "This field is required";
            }
            if (!values?.buy || values.buy.length === 0) {
                errors.buy = "This field is required";
            }
            if (values?.get && values?.get > values?.buy) {
                errors.get = "Get cannot be greater than buy"
            }
            // if(values?.buy_items && values?.buy_items?.length != Number(formik?.values?.buy)){
            //     errors.buy_items = `Selected buy items should be equal to ${formik?.values?.buy}`
            // }
            // if(values?.choose_get_items && values?.choose_get_items?.length != Number(formik?.values?.get)){
            //     errors.choose_get_items = `Selected get items should be equal to ${formik?.values?.get}`
            // }
            if (values?.usageCondition !== "one_time") {
                if (!values.usagePerCustomer) errors.usagePerCustomer = "This field is required";
            }
            return errors;
        },
        onSubmit: values => {
            // globalLoader(true);
            let formData = new FormData();
            formData.append('name', values.name);
            formData.append('promo_code', values.promo_code);
            formData.append('buy_count', values.buy);
            // formData.append('buy_items', JSON.stringify(values.buy_items));
            formData.append('get_count', values.get);
            // formData.append('get_items', JSON.stringify(values?.choose_get_items));
            formData.append('offer_type', values.offerType);
            formData.append('discount_item_restriction', values.discountItemRestriction);
            formData.append('applicable_on', JSON.stringify(values.applicable_on));
            formData.append('outlet', JSON.stringify(values?.outlet_id));
            formData.append('usage_condition', values.usageCondition);
            if(values.usageCondition == "number_of_times"){
                formData.append('usage_per_customer', values.usagePerCustomer);
            }
            formData.append('customer_type', values.customerType);
            formData.append('authentication_type', values.authenticationType);
            if (values?.authenticationType == "authentication_approval") {
                formData.append('authenticate_user', JSON.stringify(values.authenticateUser));
            }
            if (values.offerType == "flat_amount") {
                formData.append('offer_amount', values.offer_amount);
            }
            if (values.offerType == "discount_percentage") {
                formData.append('discount_percentage', values.discount_percentage);
            }
            if (values?.usageCondition == 'number_of_times') {
                formData.append('usage_condition_number', values.usage_condition_number);
            }
            if (values?.usageCondition == 'number_of_times' && values?.usagePerCustomer == 'number_of_times') {
                formData.append('usage_per_customer_number', values.usage_per_customer_number);
            }
            formData.append("start_date", values.start_date);
            formData.append("end_date", values.end_date);
            formData.append("start_time", values.start_time);
            formData.append("end_time", values.end_time);
            formData.append("week", JSON.stringify(values.week));
            formData.append('company_id', getCompany().company_id);
            formData.append('brand_id', getBrandId());
            AddPromo(formData).then(response => {
                if (response?.data?.status === 1 || response?.data?.status === "1") {
                    Swal.fire({
                        customClass: 'swal-wide',
                        icon: 'success',
                        title: response.data.message,
                        showConfirmButton: false,
                        timer: 1500,
                        toast: true,
                        position: 'top-right',
                    });
                    globalLoader(false);
                    setTimeout(() => {
                        navigate(`/company/crm/coupons/promos/list/${1}`)
                    }, 1000);
                } else {
                    Swal.fire({
                        customClass: 'swal-wide',
                        icon: 'error',
                        title: response.data.message,
                        showConfirmButton: false,
                        timer: 5000,
                        toast: true,
                        position: 'top-right',
                    })
                    globalLoader(false);
                }
            }).catch(error => {
                console.log("error ====> ", error);
            })
        }
    });

    const handleCheckboxChange = (e, setFieldValue, values) => {
        const { checked, value } = e.target;
        if (checked) {
            setFieldValue('applicable_on', [...values.applicable_on, value]);
        } else {
            setFieldValue(
                'applicable_on',
                values.applicable_on.filter(item => item !== value)
            );
        }
    };

    const weekData = [{ value: "sunday", label: "Sunday" }, { value: "monday", label: "Monday" }, { value: "tuesday", label: "Tuesday" },
    { value: "wednesday", label: "Wednesday" }, { value: "thursday", label: "Thursday" },
    { value: "friday", label: "Friday" }, { value: "saturday", label: "Saturday" }]


    const outListData =
        outletList &&
        outletList.map((item) => ({ label: capitalizeAllFirstLetters(item?.outlet_name), value: item?.id }));

    // const UserData = data && data.map((item) => ({ label: capitalizeAllFirstLetters(item?.first_name + " " + item.last_name), value: item?.id }));


    // const menuData = menuItemData && menuItemData.map((item) => ({
    //     label: capitalizeAllFirstLetters(item?.item_name),
    //     value: item?.id
    // }));

    // const getItemFilteredData = menuData && menuData.filter((item) => {
    //     return formik?.values?.buy_items.includes(item.value)
    // });

    return (
        <>

            <div className="page-header d-flex align-items-center">
                <div className="page-header-left">
                    <Breadcrums data={breadcrumbs} />
                    <h4>Promos</h4>
                </div>
                <div className="page-header-right ms-auto">

                </div>
            </div>
            <form onSubmit={formik.handleSubmit}>
                <div className="">
                    <div className="card custom-card">
                        <div className="card-body">
                            <div className="row">
                                <div className='col-lg-6 text-center form-group mb-3'>
                                    <label htmlFor='name' className='text-left d-flex'>Name :<span className="requirestar">*</span></label>
                                    <input name='name' id="name" type='text' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.name} placeholder='Enter name' className='form-control' />
                                    <span className='text-danger d-flex text-left'>{formik.errors.name && formik.touched.name && formik.errors.name}</span>
                                </div>
                                <div className='col-lg-6 text-center form-group mb-3'>
                                    <label htmlFor='promo_code' className='text-left d-flex'>Promo Code:<span className="requirestar">*</span></label>
                                    <input name='promo_code' id="promo_code" type='text' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.promo_code} placeholder='Enter promo code' className='form-control' />
                                    <span className='text-danger d-flex text-left'>{formik.errors.promo_code && formik.touched.promo_code && formik.errors.promo_code}</span>
                                </div>
                                <div className='col-lg-6 text-center form-group mb-3'>
                                    <label htmlFor='buy' className='text-left d-flex'>Buy :<span className="requirestar">*</span></label>
                                    <select
                                        className="form-control"
                                        onChange={(e) => { formik.setFieldValue("buy", e?.target?.value) }}
                                        id="buy"
                                        value={formik.values.buy}
                                    >
                                        <option value="">Choose no of items</option>
                                        {BUY.length > 0 &&
                                            BUY.map((option, i) => {
                                                return (
                                                    <option key={i} value={option.key}>
                                                        {option.label}
                                                    </option>
                                                );
                                            })}
                                    </select>
                                    <span className='text-danger d-flex text-left'>{formik.errors.buy && formik.touched.buy && formik.errors.buy}</span>
                                </div>
                                {/* <div className='col-lg-6 text-center form-group mb-3'>
                                </div> */}

                                {/* <div className='col-lg-6 text-center form-group mb-3'>
                                    {menuData?.length === 0 && (loader == false) ?
                                        <div className="alert alert-warning">
                                            There is no menu item created yet. Please{' '}
                                            <Link to="/company/menu/menu/menu_items/add">
                                                create a menu item
                                            </Link>{' '}
                                            to assign buy items.
                                        </div>
                                        : null}
                                    <label htmlFor='buy_items' className='text-left d-flex'>Choose Buy Items :<span className="requirestar">*</span></label>

                                    <CustomMultiSelect data={menuData} overFlowVisibleOptions={1} form={formik} name='buy_items' />
                                    <span className='text-danger d-flex text-left'>{formik.errors.buy_items && formik.touched.buy_items && formik.errors.buy_items}</span>
                                </div>
                                <div className='col-lg-6 text-center form-group mb-3'>
                                </div> */}


                                <div className='col-lg-6 text-center form-group mb-3'>
                                    <label htmlFor='get' className='text-left d-flex'>Get :<span className="requirestar">*</span></label>
                                    <select
                                        className="form-control"
                                        onChange={(e) => { formik.setFieldValue("get", e?.target?.value) }}
                                        id="get"
                                        value={formik.values.get}
                                    >
                                        <option value="">Choose no of items</option>
                                        {GET.length > 0 &&
                                            GET.map((option, i) => {
                                                return (
                                                    <option key={i} value={option.key}>
                                                        {option.label}
                                                    </option>
                                                );
                                            })}
                                    </select>
                                    <span className='text-danger d-flex text-left'>{formik.errors.get && formik.touched.get && formik.errors.get}</span>
                                </div>
                                <div className='col-lg-6 text-center form-group mb-3'>
                                </div>
                                {/* <div className='col-lg-6 text-center form-group mb-3'>
                                    {menuData?.length === 0 && (loader == false) ?
                                        <div className="alert alert-warning">
                                            There is no menu item created yet. Please{' '}
                                            <Link to="/company/menu/menu/menu_items/add">
                                                create a menu item
                                            </Link>{' '}
                                            to assign get items.
                                        </div>
                                        : null}
                                    <label htmlFor='choose_get_items' className='text-left d-flex'>Choose Get Items :<span className="requirestar">*</span></label>
                                    <CustomMultiSelect data={menuData} overFlowVisibleOptions={1} form={formik} name='choose_get_items' />
                                    <span className='text-danger d-flex text-left'>{formik.errors.choose_get_items && formik.touched.choose_get_items && formik.errors.choose_get_items}</span>
                                </div> */}
                                <div className='col-lg-6 text-center form-group mb-3'>
                                </div>
                                <div className='col-lg-6 text-center form-group mb-3'>
                                    <label className="text-left d-flex">Offer Type:<span className="requirestar">*</span></label>
                                    <div className='row ps-3 mb-3'>
                                        <div className="form-check w-25 text-start">
                                            <input className="form-check-input cp" type="radio" name="offerType" id="Free" value="free" autoComplete='off' onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                            <label className="form-check-label cp" htmlFor="Free">Free</label>
                                        </div>
                                        <div className="form-check w-25 text-start cp">
                                            <input className="form-check-input cp" type="radio" name="offerType" id="FlatAmount" value="flat_amount" autoComplete='off' onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                            <label className="form-check-label cp" htmlFor="FlatAmount">Flat Amount</label>
                                        </div>
                                        <div className="form-check w-25 text-start cp">
                                            <input className="form-check-input cp" type="radio" name="offerType" id="DiscountPercentage" value="discount_percentage" autoComplete='off' onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                            <label className="form-check-label cp" htmlFor="DiscountPercentage">Discount Percentage</label>
                                        </div>
                                    </div>
                                    {formik.values.offerType === 'flat_amount' && (
                                        <div className='col-lg-6 text-center form-group mb-3'>
                                            <label htmlFor='offer_amount' className='text-left d-flex'></label>
                                            <input name='offer_amount' type='offer_amount' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.offer_amount} placeholder='Enter discount' className='form-control' />
                                            <span className='text-danger d-flex text-left'>{formik.errors.offer_amount && formik.touched.offer_amount && formik.errors.offer_amount}</span>
                                        </div>
                                    )}
                                    {formik.values.offerType === 'discount_percentage' && (
                                        <div className='col-lg-6 text-center form-group mb-3'>
                                            <label htmlFor='discount_percentage' className='text-left d-flex'></label>
                                            <input name='discount_percentage' type='discount_percentage' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.discount_percentage} placeholder='Enter discount percentage' className='form-control' />
                                            <span className='text-danger d-flex text-left'>{formik.errors.discount_percentage && formik.touched.discount_percentage && formik.errors.discount_percentage}</span>
                                        </div>
                                    )}
                                    <span className='text-danger d-flex text-left'>{formik.errors.offerType && formik.touched.offerType && formik.errors.offerType}</span>
                                </div>
                                <div className='col-lg-6 text-center form-group mb-3'>
                                </div>
                                <div className='col-lg-6 text-center form-group mb-3'>
                                    <label for="order_mode" className="text-left d-flex">Discount Item Restriction:<span className="requirestar">*</span></label>
                                    <div className='row ps-3 mb-3'>
                                        <div className="form-check w-25 text-start">
                                            <input className="form-check-input cp" type="radio" name="discountItemRestriction" id="None" value="none" autoComplete='off' onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                            <label className="form-check-label cp" for="None">None</label>
                                        </div>
                                        <div className="form-check w-25 text-start">
                                            <input className="form-check-input cp" type="radio" name="discountItemRestriction" id="EqualLesser" value="equal_lesser" autoComplete='off' onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                            <label className="form-check-label cp" for="EqualLesser">Equal or lesser</label>
                                        </div>
                                        <div className="form-check w-25 text-start">
                                            <input className="form-check-input cp" type="radio" name="discountItemRestriction" id="EqualHigher" value="equal_higher" autoComplete='off' onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                            <label className="form-check-label cp" for="EqualHigher">Equal or higher</label>
                                        </div>
                                        <div className="form-check w-25 text-start">
                                            <input className="form-check-input cp" type="radio" name="discountItemRestriction" id="Lowest" value="lowest" autoComplete='off' onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                            <label className="form-check-label cp" for="Lowest">Lowest</label>
                                        </div>
                                    </div>
                                    <span className='text-danger d-flex text-left'>{formik.errors.discountItemRestriction && formik.touched.discountItemRestriction && formik.errors.discountItemRestriction}</span>
                                </div>
                                <div className='col-lg-6 text-center form-group mb-3'>
                                </div>
                                <div className='col-lg-6 text-center form-group mt-3'>
                                    {orderModeData?.length === 0 && (loader == false || loader == 'false') ?
                                        <div className="alert alert-warning">
                                            There is no order mode created yet. Please{' '}
                                            <Link to="/company/setting/order-mode-configuration/list/1">
                                                create a order mode
                                            </Link>{' '}
                                            to assign to the applicable.
                                        </div>
                                        : null}
                                    <label htmlFor="order_mode" className="text-left d-flex">
                                        Applicable On<span className="requirestar">*</span>
                                    </label>
                                    <div className='row ps-3 mt-2'>
                                        {orderModeData.map(mode => (
                                            <div key={mode.id} className="form-check w-25 text-start">
                                                <input
                                                    className="form-check-input cp"
                                                    type="checkbox"
                                                    name="applicable_on"
                                                    id={`mode-${mode.id}`}
                                                    value={mode.id}
                                                    autoComplete='off'
                                                    checked={formik.values.applicable_on.includes(mode.id.toString())}
                                                    // checked={formik.values.applicable_on.map(Number).includes(Number(mode.id))}
                                                    onChange={(e) => handleCheckboxChange(e, formik.setFieldValue, formik.values)}
                                                    onBlur={formik.handleBlur}
                                                />
                                                <label className="form-check-label cp" htmlFor={`mode-${mode.id}`}>
                                                    {capitalizeAllFirstLetters(mode.name)}
                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                    <span className='text-danger d-flex text-left'>
                                        {formik.errors.applicable_on && formik.touched.applicable_on && formik.errors.applicable_on}
                                    </span>
                                </div>
                                <div className='col-lg-6 text-center form-group mb-3'></div>

                                <div className='col-lg-6 text-center form-group mb-3 mt-3'>
                                    <h2 className='text-left d-flex mb-2'>Choose Outlet</h2>
                                    {outListData?.length === 0 && (loader == false || loader == 'false') ?
                                        <div className="alert alert-warning">
                                            There is no outlet created yet. Please{' '}
                                            <Link to="/company/business/outlet/add">
                                                create a outlet
                                            </Link>{' '}
                                        </div>
                                        : null}
                                    <CustomMultiSelect data={outListData} overFlowVisibleOptions={1} form={formik} name='outlet_id' />
                                    <span className='text-danger d-flex text-left'>{formik.errors.outlet_id && formik.touched.outlet_id && formik.errors.outlet_id}</span>
                                </div>
                                <div className='col-lg-6 text-center form-group mb-3'>
                                </div>
                                <div className='col-lg-6 text-center form-group mb-3'>
                                    <label htmlFor='start_date' className='text-left d-flex'>Start Date :<span className="requirestar">*</span></label>
                                    <CustomDatetimepicker
                                        placeholder={"Start date"}
                                        setField={formik.setFieldValue}
                                        fieldname={`start_date`}
                                        setFieldTouched={formik.setFieldTouched}
                                        value={formik.values.start_date}
                                    />
                                    <span className='text-danger d-flex text-left'>{formik.errors.start_date && formik.touched.start_date && formik.errors.start_date}</span>
                                </div>
                                <div className='col-lg-6 text-center form-group mb-3'>
                                    <label htmlFor='end_date' className='text-left d-flex'>End Date :<span className="requirestar">*</span></label>
                                    <CustomDatetimepicker
                                        placeholder={"End date"}
                                        setField={formik.setFieldValue}
                                        fieldname={`end_date`}
                                        setFieldTouched={formik.setFieldTouched}
                                        value={formik.values.end_date}
                                    />
                                    <span className='text-danger d-flex text-left'>{formik.errors.end_date && formik.touched.end_date && formik.errors.end_date}</span>
                                </div>
                                <div className='col-lg-6 text-center form-group mb-3'>
                                    <label htmlFor='start_time' className='text-left d-flex'>Start Time :<span className="requirestar">*</span></label>
                                    <input
                                        placeholder={"Select"}
                                        fieldname={`start_time`}
                                        value={formik.values.start_time}
                                        type="time"
                                        className='form-control'
                                        onChange={(e) => { formik.setFieldValue("start_time", e?.target?.value) }}
                                        onClick={(e) => e.target.showPicker()}
                                    />
                                    <span className='text-danger d-flex text-left'>{formik.errors.start_time && formik.touched.start_time && formik.errors.start_time}</span>
                                </div>
                                <div className='col-lg-6 text-center form-group mb-3'>
                                    <label htmlFor='end_time' className='text-left d-flex'>End Time :<span className="requirestar">*</span></label>
                                    <input
                                        placeholder={"Select"}
                                        fieldname={`end_time`}
                                        value={formik.values.end_time}
                                        type="time"
                                        className='form-control'
                                        onChange={(e) => { formik.setFieldValue("end_time", e?.target?.value) }}
                                        onClick={(e) => e.target.showPicker()}
                                    />
                                    <span className='text-danger d-flex text-left'>{formik.errors.end_time && formik.touched.end_time && formik.errors.end_time}</span>
                                </div>
                                <div className='col-lg-6 text-center form-group mb-3'>
                                    <label htmlFor='week' className='text-left d-flex'>Week Selection :<span className="requirestar">*</span></label>

                                    <CustomMultiSelect data={weekData} form={formik} name='week' />
                                    <span className='text-danger d-flex text-left'>{formik.errors.week && formik.touched.week && formik.errors.week}</span>
                                </div>
                                <div className='col-lg-6 text-center form-group mb-3'>
                                </div>
                                <div className='row'>
                                    <div className='col-lg-6 text-center form-group mb-3'>
                                        <label className="text-left d-flex">Usage Condition:<span className="requirestar">*</span></label>
                                        <div className='row ps-3 mb-3'>
                                            <div className="form-check w-25 text-start">
                                                <input className="form-check-input cp" type="radio" name="usageCondition" id="OneTime" value="one_time" autoComplete='off' onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                                <label className="form-check-label cp" htmlFor="OneTime">One time</label>
                                            </div>
                                            <div className="form-check w-25 text-start">
                                                <input className="form-check-input cp" type="radio" name="usageCondition" id="NumberOfTimes" value="number_of_times" autoComplete='off' onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                                <label className="form-check-label cp" htmlFor="NumberOfTimes">Number of times</label>
                                            </div>
                                        </div>
                                        {formik.values.usageCondition === 'number_of_times' && (
                                            <div className='col-lg-6 text-center form-group mb-3'>
                                                <label htmlFor='usage_condition_number' className='text-left d-flex'></label>
                                                <input name='usage_condition_number' type='usage_condition_number' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.usage_condition_number} placeholder='Enter number' className='form-control' />
                                                <span className='text-danger d-flex text-left'>{formik.errors.usage_condition_number && formik.touched.usage_condition_number && formik.errors.usage_condition_number}</span>
                                            </div>
                                        )}
                                        <span className='text-danger d-flex text-left'>{formik.errors.usageCondition && formik.touched.usageCondition && formik.errors.usageCondition}</span>
                                    </div>
                                    {formik.values.usageCondition == 'number_of_times' && (
                                        <div className='col-lg-6 text-center form-group mb-3'>
                                            <label className="text-left d-flex">Usage Per Customer:<span className="requirestar">*</span></label>
                                            <div className='row ps-3 mt-2'>
                                                <div className="form-check w-25 text-start">
                                                    <input className="form-check-input cp" type="radio" name="usagePerCustomer" id="OneTimeCustomer" value="one_time" autoComplete='off' onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                                    <label className="form-check-label cp" htmlFor="OneTimeCustomer">One time</label>
                                                </div>
                                                <div className="form-check w-25 text-start">
                                                    <input className="form-check-input cp" type="radio" name="usagePerCustomer" id="NumberOfTimesCustomer" value="number_of_times" autoComplete='off' onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                                    <label className="form-check-label cp" htmlFor="NumberOfTimesCustomer">Number of times</label>
                                                </div>
                                            </div>
                                            {formik.values.usagePerCustomer === 'number_of_times' && (
                                                <div className='col-lg-6 text-center form-group mt-2'>
                                                    <label htmlFor='usage_per_customer_number' className='text-left d-flex'></label>
                                                    <input name='usage_per_customer_number' type='usage_per_customer_number' onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.usage_per_customer_number} placeholder='Enter number' className='form-control' />
                                                    <span className='text-danger d-flex text-left'>{formik.errors.usage_per_customer_number && formik.touched.usage_per_customer_number && formik.errors.usage_per_customer_number}</span>
                                                </div>
                                            )}
                                            <span className='text-danger d-flex text-left'>{formik.errors.usagePerCustomer && formik.touched.usagePerCustomer && formik.errors.usagePerCustomer}</span>
                                        </div>
                                    )}
                                </div>

                                <div className='col-lg-6 text-center form-group mb-3'>
                                    <label for="order_mode" className="text-left d-flex">Customer Type:<span className="requirestar">*</span></label>
                                    <div className='row ps-3 mb-3'>
                                        <div className="form-check w-25 text-start">
                                            <input className="form-check-input cp" type="radio" name="customerType" id="All" value="all" autoComplete='off' onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                            <label className="form-check-label cp" for="All">All</label>
                                        </div>
                                        <div className="form-check w-25 text-start">
                                            <input className="form-check-input cp" type="radio" name="customerType" id="New" value="new" autoComplete='off' onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                            <label className="form-check-label cp" for="New">New</label>
                                        </div>
                                        <div className="form-check w-25 text-start">
                                            <input className="form-check-input cp" type="radio" name="customerType" id="Existing" value="existing" autoComplete='off' onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                            <label className="form-check-label cp" for="Existing">Existing</label>
                                        </div>
                                    </div>
                                    <span className='text-danger d-flex text-left'>{formik.errors.customerType && formik.touched.customerType && formik.errors.customerType}</span>
                                </div>
                                <div className='col-lg-6 text-center form-group mb-3'>
                                </div>
                                <div className='col-lg-6 text-center form-group mb-3'>
                                    <label for="order_mode" className="text-left d-flex">Authentication Type:<span className="requirestar">*</span></label>
                                    <div className='row ps-3 mb-3'>
                                        <div className="form-check w-25 text-start">
                                            <input className="form-check-input cp" type="radio" name="authenticationType" id="NoAuthentication" value="no-authentication" autoComplete='off' onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                            <label className="form-check-label cp" for="NoAuthentication">No authentication</label>
                                        </div>
                                        <div className="form-check w-25 text-start">
                                            <input className="form-check-input cp" type="radio" name="authenticationType" id="PhoneOtpAuthentication" value="phone_otp_authentication" autoComplete='off' onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                            <label className="form-check-label cp" for="PhoneOtpAuthentication">Customer phone otp authentication</label>
                                        </div>
                                        <div className="form-check w-25 text-start">
                                            <input className="form-check-input cp" type="radio" name="authenticationType" id="AuthenticationApproval" value="authentication_approval" autoComplete='off' onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                            <label className="form-check-label cp" for="AuthenticationApproval">Authentication Approval</label>
                                        </div>
                                    </div>
                                    {formik.values.authenticationType === 'authentication_approval' && (
                                        <div className='col-lg-6 text-center form-group mt-2'>
                                            <label htmlFor='authenticateUser' className='text-left d-flex'></label>

                                            {/* <CustomMultiSelect data={UserData} overFlowVisibleOptions = {1} form={formik} name='authenticateUser'/> */}
                                            <select
                                                className={`form-control cp ${data && data.length > 5 ? 'scrollable-select' : ''}`}
                                                onChange={(e) => {
                                                    const selectedValue = e.target.value;
                                                    formik.setFieldValue("authenticateUser", selectedValue ? [selectedValue] : []);
                                                }}
                                                id="authenticateUser"
                                                value={formik?.values?.authenticateUser}
                                            >
                                                <option value="">Select User</option>
                                                {data &&
                                                    data.length > 0 &&
                                                    data.map((option, i) => (
                                                        <option key={i} value={option?.id}>
                                                            {capitalizeFirstLetter(option?.first_name + " " + option?.last_name)}
                                                        </option>
                                                    ))}
                                            </select>
                                            <span className='text-danger d-flex text-left'>{formik.errors.authenticateUser && formik.touched.authenticateUser && formik.errors.authenticateUser}</span>
                                        </div>
                                    )}
                                    <span className='text-danger d-flex text-left'>{formik.errors.authenticationType && formik.touched.authenticationType && formik.errors.authenticationType}</span>
                                </div>
                                <div className="mt-2">
                                    <button className="btn btn-primary mr-2" type="submit" >
                                        Create
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <ToastContainer
                position="top-right"
                hideProgressBar={false}
                autoClose={true | 1000}
                newestOnTop={true}
                closeOnClick={false}
                draggable={false}
                rtl={false}
            />
        </>
    );
}

export default PromosAdd