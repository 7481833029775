import React, { useState, useEffect, useRef, useMemo } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ConfigurationReportTab from './configurationTab';
import Sorting from '../../components/company_panel/common/sorting';
import { capitalizeAllFirstLetters, capitalizeFirstLetter, formateDateWithTime, formateIdArray, getBrandId, getCompany, getOutletId, TrimText } from '../../helpers/commonFunctions';
import CustomPagination from '../../components/company_panel/common/custompagination';
import Loader from '../../components/company_panel/common/loader';
import notFound from '../../../src/assets/company/img/no-record-found.png'
import CustomRangepicker from '../../components/company_panel/common/rangepicker';
import { ListSalesReport } from '../../services/company_panel/report.services';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { LOADER_TIME } from '../../utils/Constants';
import { GetActiveOrderModes } from '../../services/company_panel/setting/orderModeConfigurationService';
import $ from "jquery";
import CustomModal from '../../components/company_panel/common/customModal';


const SalesReportList = () => {

    const breadcrumbs = [{ title: "Dashboard", url: "/company/dashboard" }, { title: "Pages", url: "" },];
    const [list, setList] = useState([]);
    const [dataLength, setDataLength] = useState();
    const [itemPerPage, setItemPerPage] = useState(10);
    const [sorting, setSorting] = useState({});
    const [defaultSorting, setDefaultSorting] = useState(true);
    const [page, setPage] = useState(1);
    const [loader, setLoader] = useState(true);
    const [search, setSearch] = useState({});
    const [globalSearch, setGlobalSearch] = useState("");
    const [resetdate, setResetDate] = useState(false);
    const params = useParams();
    const selectRef = useRef(null);
    const [orderType, setOrderType] = useState();
    const [modalData, setModalData] = useState([]);

    const [isBrandChanged, setIsBrandChanged] = useState(false);
    const [isOutletChanged, setIsOutletChanged] = useState(false);

    useMemo(() => {
        document.addEventListener('brand-changed', function () {
            setIsBrandChanged(!isBrandChanged);
        });
        document.addEventListener('outlet-changed', function () {
            setIsOutletChanged(!isOutletChanged);
        })
    });

    useEffect(() => {
        const getData = setTimeout(() => {
            if (search) {
                setLoader(true)
                setTimeout(() => {
                    const formData = new FormData()
                    formData.append("page", params.id)
                    formData.append("per_page", itemPerPage)
                    formData.append("sort", JSON.stringify(sorting))
                    formData.append("search", JSON.stringify(search))
                    formData.append("global_search", globalSearch)
                    formData.append('company_id', getCompany().company_id);
                    formData.append('brand_id', getBrandId());
                    formData.append('outlet_id', getOutletId());
                    ListSalesReport(formData).then(data => {
                        setDataLength(data.data.response.count);
                        setList(data && data.data && data.data.response ? data.data.response.rows : [])
                        setLoader(false);
                    }).catch(error => {
                        console.log("error ====> ", error);
                        setLoader(false);
                    })
                }, 500);
            }
        }, 300);
        return () => clearTimeout(getData);
    }, [sorting, search, globalSearch, page])

    const handleSort = (e, column) => {
        setDefaultSorting(false);
        let sort = { order: 0, column: column };
        if (e.target.classList.contains("assc")) {
            sort.order = -1;
        } else {
            sort.order = 1;
        }
        setSorting(sort);
    }

    const prepareSearch = (key, value) => {
        let sr = { ...search };
        if (String(value).length > 0) {
            sr[key] = value;
        } else {
            delete sr[key];
        }
        setSearch(sr);
    }

    const resetFilter = (e) => {
        e.preventDefault()
        setGlobalSearch("")
        prepareSearch()
        setSearch({})
        setResetDate(!resetdate)
        // if (selectRef.current) {
        //     selectRef.current.selectedIndex = 0;
        // }
        $("#resetFilter")[0].selectedIndex = 0
    }


    const generateOrderId = (orderId) => {
        let id = orderId;
        let countOfDigits = 0;
        while (id) {
            id = Math.floor(id / 10);
            countOfDigits += 1;
        }

        if (countOfDigits >= 4) {
            return orderId
        }
        let zerosCount = 4 - countOfDigits;
        let generatedZeros = '';
        while (zerosCount) {
            generatedZeros += '0';
            zerosCount -= 1;
        }
        return `${generatedZeros}${orderId}`;
    }


    const getOrderItemsNames1 = (data) => {
        return data && data.menu_items
            ? data.menu_items.join(', ') // Join all menu items into a single comma-separated string
            : 'N/A'; // Return 'N/A' if there are no menu items
    };



    const headerMapping = {
        "order_id": "Order ID",
        "customer_name": "Customer Name",
        "customer_number": "Customer Number",
        "customer_id": "Customer ID",
        "order_items": "Order Items",
        "order_by": "Order By",
        "amount": "Total Price",
        "payment_status": "Payment Status",
        "payment_method": "Payment Method",
        "order_type": "Order Mode",
        "brand_name": "Brand Name",
        "outlet_name": "Outlet Name",
        "aggregator_name": "Aggregator Name",
        "date_time": "Date and Time"
    };

    const handleExport = () => {
        const formData = new FormData()
        formData.append("page", 1)
        formData.append("per_page", dataLength)
        formData.append("sort", JSON.stringify(sorting))
        formData.append("search", JSON.stringify(search))
        formData.append("global_search", globalSearch)
        formData.append('company_id', getCompany().company_id);
        formData.append('brand_id', getBrandId());
        formData.append('outlet_id', getOutletId());
        ListSalesReport(formData).then(data => {
            setDataLength(data.data.response.count);
            const listData = data && data.data && data.data.response ? data.data.response.rows : [];
            const printData = listData?.map((item) => {
                const transformedItem = {};
                // Extract fields from nested objects
                const OrderID = item?.order_id || 'N/A';
                const customerName = item?.customer_name || 'N/A';
                const customerNumber = item?.customer_number || 'N/A';
                const customerID = item?.customer_id || 'N/A';
                const orderItems = getOrderItemsNames1(item);
                const orderBy = (item?.order_by_first_name + " " + item?.order_by_last_name) || 'N/A';
                const amount = item?.amount + " " + "SAR" || 'N/A';
                const paymentStatus = item?.payment_status || 'N/A';
                const paymentMethod = item?.payment_method || 'N/A';
                const orderType = item?.order_mode_name || 'N/A';
                const brandName = capitalizeFirstLetter(item?.brand_name) || 'N/A';
                const outletName = capitalizeFirstLetter(item?.outlet_name) || 'N/A';
                const aggregatorName = capitalizeFirstLetter(item?.aggregator_name) || 'N/A';
                const dateTime = formateDateWithTime(item?.created_at) || 'N/A';

                // Map fields according to headerMapping
                for (const key in headerMapping) {
                    if (headerMapping.hasOwnProperty(key)) {
                        const newKey = headerMapping[key];
                        switch (key) {
                            case "order_id":
                                transformedItem[newKey] = '#' + (generateOrderId(OrderID));
                                break;
                            case "customer_name":
                                transformedItem[newKey] = capitalizeFirstLetter(customerName);
                                break;
                            case "customer_number":
                                transformedItem[newKey] = capitalizeFirstLetter(customerNumber);
                                break;
                            case "customer_id":
                                transformedItem[newKey] = '#' + (customerID);
                                break;
                            case "order_items":
                                transformedItem[newKey] = capitalizeFirstLetter(orderItems);
                                break;
                            case "order_by":
                                transformedItem[newKey] = capitalizeFirstLetter(orderBy);
                                break;
                            case "amount":
                                transformedItem[newKey] = (amount);
                                break;
                            case "payment_status":
                                transformedItem[newKey] = capitalizeFirstLetter(paymentStatus);
                                break;
                            case "payment_method":
                                transformedItem[newKey] = capitalizeFirstLetter(paymentMethod);
                                break;
                            case "order_type":
                                transformedItem[newKey] = capitalizeFirstLetter(orderType);
                                break;
                            case "brand_name":
                                transformedItem[newKey] = (brandName);
                                break;
                            case "outlet_name":
                                transformedItem[newKey] = (outletName);
                                break;
                            case "aggregator_name":
                                transformedItem[newKey] = (aggregatorName);
                                break;
                            case "date_time":
                                transformedItem[newKey] = (dateTime);
                                break;
                            default:
                                // Handle any unexpected keys
                                console.warn(`Unexpected key in headerMapping: ${key}`);
                                transformedItem[newKey] = 'N/A';
                                break;
                        }
                    }
                }
                return transformedItem;
            });

            const ws = XLSX.utils.json_to_sheet(printData, { header: Object.values(headerMapping) });
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
            saveAs(blob, 'Sales-Report.xlsx');
            setTimeout(() => {
                setLoader(false);
            }, LOADER_TIME);
        }).catch(error => {
            setTimeout(() => {
                setLoader(false);
            }, LOADER_TIME);
            console.log("error ====> ", error);
        })
    };

    const getOrderItemsNames = (data) => {
        return data && (
            <div className="d-flex flex-wrap">
                {data?.menu_items?.slice(0,2).map((menuItems, index) => (
                    <span key={index} className="mx-1 text-capitalize">
                        {menuItems}
                        {index < data?.menu_items?.length - 1 && ', '}
                    </span>
                ))}
                {data?.menu_items.length > 2 &&
                    <span
                        className="link-text cp"
                        data-bs-target="#exampleModalToggle"
                        data-bs-toggle="modal"
                        onClick={() => setModalData(data.menu_items)} 
                    >
                        ...
                    </span>
                }
            </div>
        );
    };

    const handleFilterChange = (e) => {
        if (e?.target.value) {
            prepareSearch("order_type", e.target.value);
        } else {
            prepareSearch("order_type", "");
        }
    };

    useEffect(() => {
        GetActiveOrderModes(getBrandId()).then(data => {
            setOrderType(data && data.data && data.data.response ? data.data.response : []);
        }).catch(error => {
            console.log("error=====>", error)
        })
    }, [isBrandChanged])


    return (
        <>
            <div className="animation_fade">
                <div className='page-header d-flex align-items-center'>
                    <div className='page-header-left'>
                        <h4>Report</h4>
                    </div>
                    <div className='page-header-right ms-auto'>
                        <button onClick={handleExport} disabled={dataLength === 0} className="btn btn-primary ms-2">Export Report</button>
                    </div>
                </div>
                <div className="card custom-card overflow-hidden">
                    <div className='gap-20 border-bottom'>
                        <div className='col-12'>
                            <div className='d-flex justify-content-between align-items-center'>
                                <ConfigurationReportTab tab='sales' />
                            </div>
                        </div>
                    </div>
                    <div className="gap-20">
                        <div className='page-header d-flex align-items-center mb-0'>
                            {/* <div className='page-header-left'></div> */}
                            <div className='d-flex gap-3 align-items-center'>
                                <div className="form-group mb-0 rangepicker_container">
                                    <input type="search" className="form-control" value={globalSearch} placeholder="Search" onChange={(e) => { setGlobalSearch(e.target.value) }} />
                                </div>
                            </div>
                            <div className='page-header-right ms-auto'>
                                <div className='d-flex gap-3 align-items-center'>
                                    <div className="me-3">
                                        <div className="form-group mb-0 filter_icon_container filter_design">
                                            {/* <div className="select-down-arrow"> */}
                                            <select
                                                ref={selectRef}
                                                className={`form-control cp ${orderType && orderType.length > 5 ? 'scrollable-select' : ''}`}
                                                onChange={handleFilterChange}
                                                id="resetFilter"
                                            >
                                                <option value="">Order Mode</option>
                                                {orderType &&
                                                    orderType.length > 0 &&
                                                    orderType?.map((option, i) => {
                                                        return (
                                                            <option key={i} value={option?.id}>
                                                                {capitalizeFirstLetter(option?.name)}
                                                            </option>
                                                        );
                                                    })}
                                            </select>
                                            {/* </div> */}
                                        </div>
                                    </div>
                                    <div className="date-input">
                                        <CustomRangepicker GetDateRange={(e) => { prepareSearch("created", e) }} resetdate={resetdate} />
                                    </div>
                                    <button type="reset" value='Reset' onClick={resetFilter} className="btn btn-primary mr-2">Reset Filter</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tabler-wrap">
                        <div className="table-responsive">
                            <table className="table table-custom">
                                <thead>
                                    <tr>
                                        <th width="5%">
                                            <div className='sorting_column'>
                                                <span>Order ID  </span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="customer_orders.id" />
                                            </div>
                                        </th>
                                        <th width="5%">
                                            <div className='sorting_column'>
                                                <span>Customer Name</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="customers.customer_name" />
                                            </div>
                                        </th>
                                        <th width="5%">
                                            <div className='sorting_column'>
                                                <span>Customer Number</span>
                                                {/* <Sorting sort={sorting} handleSort={handleSort} column="name" /> */}
                                            </div>
                                        </th>
                                        <th width="5%">
                                            <div className='sorting_column'>
                                                <span>Customer ID</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="customers.id" />
                                            </div>
                                        </th>
                                        <th width="7%">
                                            <div className='sorting_column'>
                                                <span>Order Items</span>
                                                {/* <Sorting sort={sorting} handleSort={handleSort} column="name" /> */}
                                            </div>
                                        </th>
                                        <th width="5%">
                                            <div className='sorting_column'>
                                                <span>Ordered By</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="users.first_name" />
                                            </div>
                                        </th>
                                        <th width="8%">
                                            <div className='sorting_column'>
                                                <span>Total Price</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="transactions.amount" />
                                            </div>
                                        </th>
                                        <th width="5%">
                                            <div className='sorting_column'>
                                                <span>Payment Method</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="transactions.payment_method" />
                                            </div>
                                        </th>
                                        <th width="5%">
                                            <div className='sorting_column'>
                                                <span>Payment Status</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="transactions.payment_status" />
                                            </div>
                                        </th>
                                        <th width="5%">
                                            <div className='sorting_column'>
                                                <span>Order Mode</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="order_mode_configurations.name" />
                                            </div>
                                        </th>
                                        <th width="5%">
                                            <div className='sorting_column'>
                                                <span>Brand Name</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="brands.name" />
                                            </div>
                                        </th>
                                        <th width="5%">
                                            <div className='sorting_column'>
                                                <span>Outlet Name</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="outlets.outlet_name" />
                                            </div>
                                        </th>
                                        <th width="5%">
                                            <div className='sorting_column'>
                                                <span>Aggregator Name</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="aggregators_configurations.name" />
                                            </div>
                                        </th>
                                        <th width="5%">
                                            <div className='sorting_column'>
                                                <span>Date and Time</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="customer_orders.created_at" />
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {loader ?
                                        <tr>
                                            <td colSpan={11}><Loader /></td>
                                        </tr> : <>
                                            {list.length ? list?.map((row, i) => (
                                                <tr key={i}>
                                                    <td>#{(generateOrderId(row?.order_id))}</td>
                                                    <td>{capitalizeFirstLetter(TrimText(row?.customer_name, 30))}</td>
                                                    <td>{row?.customer_number ? row?.customer_number : "-"}</td>
                                                    <td>#{((row?.customer_id))}</td>
                                                    <td>{getOrderItemsNames(row)}</td>
                                                    <td>{row?.order_by_first_name ? `${row?.order_by_first_name} ${row?.order_by_last_name}` : "-"}</td>
                                                    <td>
                                                        {
                                                            row.payment_status == 'refunded' ?
                                                            Number(row?.amount) - Number(row?.refunded_amount) :
                                                            row?.amount
                                                        } SAR
                                                    </td>
                                                    <td>{capitalizeFirstLetter(row?.payment_method)}</td>
                                                    <td>
                                                        {
                                                            row?.payment_status ? 
                                                                row.amount == row.refunded_amount ? 
                                                                capitalizeFirstLetter(row?.payment_status) : 
                                                                'Partial refunded' :
                                                            "N/A"
                                                        }
                                                    </td>
                                                    <td>{capitalizeFirstLetter(row?.order_mode_name)}</td>
                                                    <td>{capitalizeFirstLetter(row?.brand_name)}</td>
                                                    <td>{capitalizeFirstLetter(row?.outlet_name)}</td>
                                                    <td>{row?.aggregator_name ? capitalizeFirstLetter(row?.aggregator_name) : "N/A"}</td>
                                                    <td>{formateDateWithTime((row?.created_at))}</td>
                                                </tr>
                                            )) : <tr>
                                                <td colSpan={11} className="text-center">
                                                    <img src={notFound} style={{ width: "5%" }} alt="Not Found" />
                                                    <p>No record found</p>
                                                </td>
                                            </tr>
                                            }</>}
                                </tbody>
                            </table>
                        </div>
                        <div className='tabler-footer'>
                            <div className='d-flex align-items-center'>
                                <div id="example1_info" role="status" className="table-result" aria-live="polite"><b>Total Records : {dataLength ? dataLength : "0"}</b></div>
                                {dataLength && dataLength > 0 ?
                                    <CustomPagination datalength={dataLength} itemperpage={itemPerPage} setPage={setPage} currentPage={page} pageRoute={[{ name: "report", path: "/company/report/sales_report/list" }]} /> : ""}
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer
                    position="top-right"
                    hideProgressBar={false}
                    autoClose={true | 1000}
                    newestOnTop={true}
                    closeOnClick={false}
                    draggable={false}
                    rtl={false}
                />

            </div>

            {/* modal */}

            {/* <div className="modal fade" id="exampleModalToggle" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabIndex="-1">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="exampleModalToggleLabel">Order Items</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="modal-body">
                                {modalData && (
                                    <ul>
                                        {modalData.map((item, index) => (
                                            <li key={index} className="text-capitalize">
                                                {item}
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            <CustomModal
                title="Order Items"
                content={modalData} 
            />
        </>
    );
}

export default SalesReportList