
import React, { useState, useEffect, useMemo, useRef } from 'react'
import { Formik, Field } from 'formik';
import { CreateOutlet, GetBrandList, registerToZatcaAgain } from '../../services/company_panel/outletService';
import { GetAllCountries, GetAllStates, GetAllCities } from '../../services/company_panel/countryService';
import { useNavigate } from 'react-router-dom';
import Swal from "sweetalert2"
import Breadcrums from '../../components/company_panel/common/breadcrumbs';
import $ from "jquery";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { capitalizeAllFirstLetters, capitalizeFirstLetter, getBrandId, getCompany, getOutletId } from '../../helpers/commonFunctions';
import { getAllAreas } from '../../services/company_panel/setting/settingAreaServices';
import { Form, InputGroup } from 'react-bootstrap';
import { getAllMenus } from '../../services/company_panel/categoryService';
import { Dropdown } from 'semantic-ui-react';
import { ListGroups } from '../../services/company_panel/routingStationService';
import {
  CitySelect,
  StateSelect,
  CountrySelect,
} from "react-country-state-city";
import "react-country-state-city/dist/react-country-state-city.css";
import ZatcaSampleFileXlsx from '../../assets/company/outletZatcaSampleFiles/ZatcaCertificateSample.xlsx';
import ZatcaSampleFileXls from '../../assets/company/outletZatcaSampleFiles/ZatcaCertificateSample.xls';
import ZatcaSampleFileCsv from '../../assets/company/outletZatcaSampleFiles/ZatcaCertificateSample.csv';
import { LOADER_TIME } from '../../utils/Constants';
import { Tooltip, Whisper } from 'rsuite';



const OutletAdd = () => {
  const [btnDisable, setBtnDisable] = useState(true);
  const [btnClicked, setBtnClicked] = useState(false);
  const [page, setPage] = useState(1);
  const [location, setLocation] = useState('');
  const [showMap, setShowMap] = useState(false);
  const [selectedOption, setSelectedOption] = useState('automatic');
  const [hardwareSelection, setHardwareSelection] = useState(null);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [areas, setAreas] = useState([]);
  const [menus, setMenus] = useState([]);
  const [isBrandChanged, setIsBrandChanged] = useState(false);
  const [hardwareError, setHardwareError] = useState({});
  const [zatcaData, setZatcaData] = useState({});

  const formikRef = useRef();

  const [loader, setLoader] = useState(false);

  const [loaderNew, setLoaderNew] = useState(false);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const breadcrumbs = [
    {
      title: "Outlet",
      url: "/company/business/outlet/list/1"
    },
    {
      title: "Create Outlet",
      url: ""
    },
  ]
  const navigate = useNavigate()
  const [submitted, setSubmitted] = useState(false)
  const [brandList, setBrandList] = useState([])
  const [addMoreCount, setAddMoreCount] = useState([1]);
  const [routingGroupList, setRoutingGroupList] = useState([])
  const [selectedTime, setSelectedTime] = useState({
    sunday_opening_time: null,
    monday_opening_time: null,
    tuesday_opening_time: null,
    wednesday_opening_time: null,
    thursday_opening_time: null,
    friday_opening_time: null,
    saturday_opening_time: null,
    sunday_closing_time: null,
    monday_closing_time: null,
    tuesday_closing_time: null,
    wednesday_closing_time: null,
    thursday_closing_time: null,
    friday_closing_time: null,
    saturday_closing_time: null,
  });
  const [hardwareDetail, setHardwareDetail] = useState({
    1: {
      hardware_used: 'printer',
      printer_type: "customer",
      routing_groups: null,
      registration_id: null,
      printer_ip : null,
      name: null,
      sdk_id: null,
      select_menu: '',
    }
  });
  const storeTime = [
    { value: 'monday', label: 'Monday' },
    { value: 'tuesday', label: 'Tuesday' },
    { value: 'wednesday', label: 'Wednesday' },
    { value: 'thursday', label: 'Thursday' },
    { value: 'friday', label: 'Friday' },
    { value: 'saturday', label: 'Saturday' },
    { value: 'sunday', label: 'Sunday' },
  ];

  const [activeInput, setActiveInput] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const fileInputRef = useRef();
  const [zatcaCertificateFile, setZatcaCertificateFile] = useState(null);
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [showZatcaDetails, setShowZatcaDetails] = useState(false);



  useMemo(() => {
    document.addEventListener('brand-changed', function () {
      setIsBrandChanged(!isBrandChanged);
    });
  });

  const getRoutingGroups = (brand_id) => {
    const formData = new FormData()
    formData.append('brand_id', brand_id);
    ListGroups(formData).then(data => {
      setRoutingGroupList(data?.data?.response?.rows);
    }).catch(error => {

      console.log("error ====> ", error);
    })
  }

  useEffect(() => {
    GetAllCountries().then(data => {
      setCountries(data && data.data && data.data.response ? data.data.response : [])
    }).catch(error => {
      console.log("error=====>", error)
    })
    GetBrandList(getCompany().company_id).then(data => {
      setBrandList(data && data?.data && data?.data?.response ? data?.data?.response : [])
    }).catch(error => {
      console.log("error=====>", error)
    })
    getAllMenus({ brand_id: getBrandId() }).then(data => {
      setMenus(data && data.data && data.data.response ? data.data.response : [])
    }).catch(error => {
      console.log("error=====>", error)
    })

    $('.printer').prop("checked", true);
  }, [isBrandChanged])


  const getAreas = (brand_id) => {
    if (!brand_id) {
      return
    }
    getAllAreas({ brand_id: brand_id }).then(data => {
      setAreas(data && data.data && data.data.response ? data.data.response : [])
    }).catch(error => {
      console.log("error=====>", error)
    })
  }

  const getMenus = (brand_id) => {
    if (!brand_id) {
      return
    }
    getAllMenus({ brand_id: brand_id }).then(data => {
      setMenus(data && data.data && data.data.response ? data.data.response : [])
    }).catch(error => {
      console.log("error=====>", error)
    })
  }

  const nextStep = (selected) => {
    setPage(page + 1);
    var currentStepNum = $('#checkout-progress').data('current-step');
    var nextStepNum = (currentStepNum + 1);
    var currentStep = $('.step.step-' + currentStepNum);
    var nextStep = $('.step.step-' + nextStepNum);
    var progressBar = $('#checkout-progress');
    $('.btn-prev').show();
    $('#section' + currentStepNum).toggle();
    $('#section' + nextStepNum).toggle();
    if (nextStepNum == 6) {
      $(this).toggle();
      $('.btn-submit').toggle();
      $('.btn-next').hide();
    }

    $('.checkout-progress').removeClass('.step-' + currentStepNum).addClass('.step-' + (currentStepNum + 1));

    currentStep.removeClass('active').addClass('valid');
    currentStep.find('span').addClass('opaque');
    currentStep.find('.fa.fa-check').removeClass('opaque');

    nextStep.addClass('active');
    progressBar.removeAttr('class').addClass('step-' + nextStepNum).data('current-step', nextStepNum);
  }

  const prevStep = (selected) => {
    setPage(page - 1);
    var currentStepNum = $('#checkout-progress').data('current-step');
    var prevStepNum = (currentStepNum - 1);
    var currentStep = $('.step.step-' + currentStepNum);
    var prevStep = $('.step.step-' + prevStepNum);
    var progressBar = $('#checkout-progress');
    $('.btn-next').removeClass('disabled');
    $('#section' + currentStepNum).toggle();
    $('#section' + prevStepNum).toggle();
    if (currentStepNum == 6) {
      $('.btn-submit').toggle();
      $('.btn-next').toggle();
    }
    if (prevStepNum > 1) {
      $('.btn-prev').show();
    } else {
      $('.btn-prev').hide(); // Hide only if it's step 1
    }

    if (prevStepNum == 1) {
      $(this).addClass('disabled');
    }
    $('.checkout-progress').removeClass('.step-' + currentStepNum).addClass('.step-' + (prevStepNum));

    currentStep.removeClass('active');
    prevStep.find('span').removeClass('opaque');
    prevStep.find('.fa.fa-check').addClass('opaque');

    prevStep.addClass('active').removeClass('valid');
    progressBar.removeAttr('class').addClass('step-' + prevStepNum).data('current-step', prevStepNum);
  };
  const [btnHide, setBtnHide] = useState(false)
  // change hardware Used
  const hardwareUsed = (selected, index, value, hide) => {

    var dataId = selected.target.getAttribute('data-id');
    $('.hardware_tab_' + dataId).hide();
    var hardwareType = selected.target.value;
    $('.hardware_' + hardwareType + '_' + dataId).show();
    handleChangeData(selected, index, value);
    if (hide === 2 || hide === 3) {
      setBtnHide(true)
    } else {
      setBtnHide(false)
    }

  };

  const updateHardwareDetail = (id, updatedDetail) => {
    setHardwareDetail(prevHardwareDetail => ({
      ...prevHardwareDetail,
      [id]: updatedDetail
    }));
  };
  const [routingGroup, setRoutingGroup] = useState([])

  const handleChangeData = (e, id, field, value) => {
    if (field === 'routing_groups') {
      updateHardwareDetail(id, { ...hardwareDetail[id], [field]: value });
    } else {
      let newValue = e.target.value;
      updateHardwareDetail(id, { ...hardwareDetail[id], [field]: newValue });
    }
  };

  const handleDinnerMenu = (e, id, field, menuIndex) => {
    let selectedMenu = e.target.value;
    updateHardwareDetail(id, { ...hardwareDetail[id], [field]: selectedMenu });
  };

  const addMore = (selected) => {
    setAddMoreCount(prevValues => [...prevValues, 1]);
    const newId = Object.keys(hardwareDetail).length + 1;
    updateHardwareDetail(newId, { hardware_used: 'printer', printer_type: 'customer', printer_ip : null, routing_groups: null, registration_id: null, name: null, sdk_id: null, select_menu: null });
  }
  const [activeStep, setActiveStep] = useState(1);

  // Function to handle step changes
  const handleStepChange = (step) => {
    setActiveStep(step);
  };
  const removeLast = () => {
    if (addMoreCount.length <= 1) return;

    setAddMoreCount((prevValues) => prevValues.slice(0, -1));

    setHardwareDetail((prevHardwareDetail) => {
      const newHardwareDetail = { ...prevHardwareDetail };
      const lastKey = Object.keys(newHardwareDetail).length;
      delete newHardwareDetail[lastKey];
      return newHardwareDetail;
    });
  };

  const options = routingGroupList.map((option) => ({
    key: option.id,
    text: capitalizeAllFirstLetters(option.name),
    value: option.id,
  }));
  function validateOpeningTime(errors, values) {
    storeTime.forEach(item => {
      if (values[`is_${item.value}`]) {
        if (values[`${item.value}_opening_time`] && values[`${item.value}_closing_time`]) {
          const [hours, minutes] = values[`${item.value}_closing_time`].split(":");
          const [hours2, minutes2] = values[`${item.value}_opening_time`].split(":");
          const date = new Date();
          const date2 = new Date();
          date.setHours(hours);
          date.setMinutes(minutes);
          date2.setHours(hours2);
          date2.setMinutes(minutes2);
          let diff = (date.getTime() - date2.getTime()) / 1000 / 60;
          const timeGap = 5;
          if (Math.abs(diff) >= timeGap) {
            delete errors[`is_${item.value}`];
          } else {
            errors[`is_${item.value}`] = `Opening time and closing time should be within ${timeGap} minutes gap.`;
          }
        } else {
          errors[`is_${item.value}`] = `Please select opening and closing time`;
        }
      } else {
        delete errors[`is_${item.value}`];
      }
    });
  }
  const [countryid, setCountryid] = useState(0);
  const [stateid, setstateid] = useState(0);
  const [cityid, setcityid] = useState(0);

  const handleAdd = (values, setFieldValue) => {
    setFieldValue('dine_tables', [
      ...values.dine_tables,
      { table_number: '', table_name: '' },
    ]);
  };

  const handleRemove = (values, index, setFieldValue) => {
    setFieldValue(
      'dine_tables',
      values.dine_tables.filter((_, i) => i !== index)
    );
  };

  const clearAll = () => {
    setAddMoreCount([]);
    setHardwareDetail([]);
    setRoutingGroup([]);
  };

  useEffect(() => {
    let ErrorObj = {}
    Object.entries(hardwareDetail).forEach(([index, details]) => {
      if (details.hardware_used == "printer") {
        if ((!details.printer_type || (details.printer_type == 'kitchen' && !details.registration_id)) || !details.name || !details.printer_ip) {
            ErrorObj[index] = true
        }
      } else if (details.hardware_used == "pos") {
          if (!details.routing_groups || !details.name || !details.registration_id || !details.select_menu || details.routing_groups == null || details.routing_groups.length == 0) {
              ErrorObj[index] = true
          }
      }
      // else {
      //   ErrorObj[index] = false
      // }
    })
    setHardwareError(ErrorObj);
    if (Object.entries(ErrorObj).length > 0) {
      setBtnDisable(true);
    } else {
      setBtnDisable(false);
    }
  }, [hardwareDetail]);

  const modifyObjectHardwareDetail = () => {
    const modifiedObj = {};
    for (const key in hardwareDetail) {
      if (hardwareDetail[key].hardware_used === "printer") {
        modifiedObj[key] = {
          ...hardwareDetail[key],
          // hardware_used: hardwareDetail[key].hardware_used,
          select_menu: null,
          routing_groups: null,
        };
      } else if (hardwareDetail[key].hardware_used === "pos") {
        modifiedObj[key] = {
          ...hardwareDetail[key],
          printer_type: null,
          printer_ip : null
        };
      } else {
        modifiedObj[key] = {
          hardware_used: hardwareDetail[key].hardware_used
        };
      }
    }
    return modifiedObj;
  };

  const handleUploadExcel = (e) => {
    if (e.target.files && e.target.files[0]) {
      setZatcaCertificateFile(e.target.files[0]);
      setIsFileUploaded(true);
      Swal.fire({
        customClass: 'swal-wide',
        icon: 'success',
        title: "Certificate uploaded successfully",
        showConfirmButton: false,
        timer: 5000,
        toast: true,
        position: 'top-right',
      });
      e.target.value = null;
    }
  };

  const [data, setData] = useState({});

  const handleGoToList = () => {
    navigate(`/company/business/outlet/list/${1}`);
  };

  const registerToZetca = () => {
    setLoader(true);
    try {
      let formData = new FormData();
      formData.append("id", getOutletId());
      registerToZatcaAgain(formData)
        .then((response) => {
          if (response?.status === 200) {
            Swal.fire({
              customClass: 'swal-wide',
              icon: 'success',
              title: response?.data?.message,
              showConfirmButton: false,
              timer: 2000,
              toast: true,
              position: 'top-right',
            });
            setTimeout(() => {
              setLoader(false);
            }, LOADER_TIME);
          }
          else {
            Swal.fire({
              customClass: 'swal-wide',
              icon: 'error',
              title: "Unable to send to the zatca",
              showConfirmButton: false,
              timer: 2000,
              toast: true,
              position: 'top-right',
            });
          }
          setTimeout(() => {
            setLoader(false);
          }, LOADER_TIME);
        })
        .catch((err) => {
          console.error("Error in API call:", err);
          setLoader(false);
        });
    } catch (error) {
      console.error("Unexpected error:", error);
      setLoader(false);
    }
  };


  return (
    <>
      <div className="page-header d-flex align-items-center">
        <div className="page-header-left">
          <Breadcrums data={breadcrumbs} />
          <h4>Outlet</h4>
        </div>
        <div className="page-header-right ms-auto">

        </div>
      </div>
      <Formik
        innerRef={formikRef}
        enableReinitialize={true}
        validateOnBlur={true}
        initialValues={{
          outlet_name: '',
          outlet_name_ar: '',
          brand_id: '',
          short_code: '',
          // vat_information: '',
          email: '',
          short_description: '',
          short_description_ar: '',
          location: '',
          country_id: '',
          state_id: '',
          city_id: '',
          area: '',
          street: '',
          building: '',
          city_subdivision: '',
          plot_identification: '',
          invoice_header_text_info: '',
          invoice_footer_text_info: '',
          end_of_the_day_configuration: '',
          end_of_the_day_time: '',
          is_sunday: '',
          is_monday: '',
          is_tuesday: '',
          is_wednesday: '',
          is_thursday: '',
          is_friday: '',
          is_saturday: '',
          sunday_opening_time: '',
          monday_opening_time: '',
          tuesday_opening_time: '',
          wednesday_opening_time: '',
          thursday_opening_time: '',
          friday_opening_time: '',
          saturday_opening_time: '',
          sunday_closing_time: '',
          monday_closing_time: '',
          tuesday_closing_time: '',
          wednesday_closing_time: '',
          thursday_closing_time: '',
          friday_closing_time: '',
          saturday_closing_time: '',
          select_menu: '',
          routing_groups: '',
          sdk_id: '',
          name: '',
          registration_id: '',
          printer_type: '',
          hardware_used: '',
          copy_print_count: '1',
          service_type: 'quick_service',
          dine_tables: [{ table_number: '', table_name: '' }],
          postal_zone: '',
          vat_name: '',
          vat_number: '',
          organisation_unit_name: '',
          otp: "",
          erp_configuration: { dimension_value_1: '', dimension_value_2: '', dimension_value_3: '', dimension_value_4: '', dimension_value_5: '', dimension_value_6: '' },
        }}
        validate={values => {
          let error = {};

          if (!values.outlet_name.trim()) {
            error.outlet_name = "Name is required";
          } else if (values.outlet_name.trim().length > 60) {
            error.outlet_name = "Name should not exceed 60 characters";
          }

          if (values.invoice_footer_text_info.trim().length > 250) {
            error.invoice_footer_text_info = "Invoice footer text should not exceed 250 characters";
          }

          if (values.invoice_header_text_info.trim().length > 250) {
            error.invoice_header_text_info = "Invoice header text should not exceed 250 characters";
          }

          if (!values.outlet_name_ar.trim()) {
            error.outlet_name_ar = "Name (Arabic) is required";
          } else if (values.outlet_name_ar.trim().length > 60) {
            error.outlet_name_ar = "Name (Arabic) should not exceed 60 characters";
          }

          if (values.short_code.trim().length > 60) {
            error.short_code = "Short code should not exceed 60 characters";
          }

          if (!values.country_id) {
            error.country_id = "Country is required";
          }
          if (!values.state_id) {
            error.state_id = "State is required";
          }
          if (!values.area) {
            error.area = "Area is required";
          }


          if (!values.brand_id) error.brand_id = "Brand is required";
          if (!values.copy_print_count) {
            error.copy_print_count = 'Please enter a number value';
          } else if (!Number.isInteger(parseInt(values.copy_print_count))) {
            error.copy_print_count = 'Please enter a valid number';
          } else if (parseInt(values.copy_print_count) < 0) {
            error.copy_print_count = 'Please enter a non-negative number';
          } else if (parseInt(values.copy_print_count) > 250) {
            error.copy_print_count = 'Copy print count should not exceed 250';
          } else if (!Number.isInteger(parseFloat(values.copy_print_count))) {
            error.copy_print_count = "Copy print count must be a whole number";
          }
          if (!values.email) {
            error.email = "Email is required";
          } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(values.email)) {
            error.email = 'Invalid email address';
          } else if (/[?+]/.test(values.email)) {
            error.email = 'Invalid email address';
          }

          if (page == 1) {
            if (error.postal_zone || error.outlet_name || error.outlet_name_ar || error.country_id || error.state_id ||
              error.city_id || error.brand_id || error.email || error?.area) {
              setBtnDisable(true);
            } else {
              setBtnDisable(false);
            }
          }
          if (values.short_description.trim().length > 200) {
            error.short_description = "Short description must be 200 characters or less";
          }
          if (values.short_description_ar.trim().length > 200) {
            error.short_description_ar = "Short description arabic must be 200 characters or less";
          }
          if (values.location.trim().length > 200) {
            error.location = "location must be 200 characters or less";
          }
          if (!values.service_type) {
            error.service_type = "Service type is required";
          }

          values?.dine_tables?.forEach((val, idx) => {
            if (values.service_type === 'table_service') {
              if (!val?.table_number?.trim()) {
                error[`dine_tables[${idx}].table_number`] = "Table number is required";
              }
              else if (val?.table_number?.length > 15) {
                error[`dine_tables[${idx}].table_number`] = "Table number should not exceed 15 characters";
              }

              if (!val?.table_name?.trim()) {
                error[`dine_tables[${idx}].table_name`] = "Table name is required";
              } else if (val?.table_name?.trim()?.length >= 25) {
                error[`dine_tables[${idx}].table_name`] = "Table name should not exceed 25 characters";
              }
            }
          });

          // if (values?.erp_configuration) {
          //   if (!values.erp_configuration?.dimension_value_1?.trim()) {
          //     error.dimension_value_1 = "Dimension Value 1 is required";
          //   } else if (values.erp_configuration?.dimension_value_1?.length > 50) {
          //     error.dimension_value_1 = "Dimension Value 1 should not exceed 50 characters";
          //   }

          //   if (!values.erp_configuration?.dimension_value_2?.trim()) {
          //     error.dimension_value_2 = "Dimension Value 2 is required";
          //   } else if (values.erp_configuration?.dimension_value_2?.length > 50) {
          //     error.dimension_value_2 = "Dimension Value 2 should not exceed 50 characters";
          //   }

          //   if (!values.erp_configuration?.dimension_value_3?.trim()) {
          //     error.dimension_value_3 = "Dimension Value 3 is required";
          //   } else if (values.erp_configuration?.dimension_value_3?.length > 50) {
          //     error.dimension_value_3 = "Dimension Value 3 should not exceed 50 characters";
          //   }

          //   if (!values.erp_configuration?.dimension_value_4?.trim()) {
          //     error.dimension_value_4 = "Dimension Value 4 is required";
          //   } else if (values.erp_configuration?.dimension_value_4?.length > 50) {
          //     error.dimension_value_4 = "Dimension Value 4 should not exceed 50 characters";
          //   }

          //   if (!values.erp_configuration?.dimension_value_5?.trim()) {
          //     error.dimension_value_5 = "Dimension Value 5 is required";
          //   } else if (values.erp_configuration?.dimension_value_5?.length > 50) {
          //     error.dimension_value_5 = "Dimension Value 5 should not exceed 50 characters";
          //   }

          //   if (!values.erp_configuration?.dimension_value_6?.trim()) {
          //     error.dimension_value_6 = "Dimension Value 6 is required";
          //   } else if (values.erp_configuration?.dimension_value_6?.length > 50) {
          //     error.dimension_value_6 = "Dimension Value 6 should not exceed 50 characters";
          //   }
          // }

          validateOpeningTime(error, values);

          if (activeInput === "register") {
            if (!values.vat_name?.trim()) {
              error.vat_name = "Organization name is required";
            }

            if (!values.vat_number) {
              error.vat_number = "VAT number is required";
            } else if (!/^\d{15}$/.test(values.vat_number)) {
              error.vat_number = "VAT number must be a 15-digit number";
            }

            if (!values.organisation_unit_name?.trim()) {
              error.organisation_unit_name = "Organization unit name is required";
            }

            if (!values.otp) {
              error.otp = "OTP is required";
            } else if (!/^\d{6}$/.test(values.otp)) {
              error.otp = "OTP must be a 6-digit number";
            }

            if (!values.street) {
              error.street = "Street is required";
            }

            if (!values.building) {
              error.building = "Building number is required";
            } else if (!/^\d{4}$/.test(values.building)) {
              error.building = "Building number must be a 4-digit number";
            }

            if (!values.city_subdivision) {
              error.city_subdivision = "City Subdivision is required";
            }

            if (!values.plot_identification) {
              error.plot_identification = "Plot Identification is required";
            } else if (!/^\d{4}$/.test(values.plot_identification)) {
              error.plot_identification = "Plot identification number must be a 4-digit number";
            }

            if (!values.postal_zone?.trim()) {
              error.postal_zone = "Postal zone is required";
            }

          }
          if (!activeInput) {
            setErrorMessage("Please choose an action (Register or Upload Certificate).");
          }

          return error;
        }}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitted(true)
          setLoaderNew(true);
          let formData = new FormData();
          formData.append('outlet_name', values.outlet_name);
          formData.append('outlet_name_ar', values.outlet_name_ar);
          formData.append('brand_id', values.brand_id);
          formData.append('short_code', values.short_code);
          formData.append('postal_zone', values.postal_zone);
          // formData.append('vat_information', values.vat_information);
          formData.append('email', values.email);
          formData.append('short_description', values.short_description);
          formData.append('short_description_ar', values.short_description_ar);
          formData.append('location', values.location);
          formData.append('city_id', JSON.stringify(values.city_id));
          formData.append('state_id', JSON.stringify(values.state_id));
          formData.append('country_id', JSON.stringify(values.country_id));
          formData.append('area', values.area);
          formData.append('street', values.street);
          formData.append('building', values.building);
          formData.append('city_subdivision', values.city_subdivision);
          formData.append('plot_identification', values.plot_identification);
          formData.append('invoice_header_text_info', values.invoice_header_text_info);
          formData.append('invoice_footer_text_info', values.invoice_footer_text_info);
          formData.append('end_of_the_day_configuration', selectedOption);
          formData.append('end_of_the_day_time', values.end_of_the_day_time);
          formData.append('is_sunday', values.is_sunday);
          formData.append('is_monday', values.is_monday);
          formData.append('is_tuesday', values.is_tuesday);
          formData.append('is_wednesday', values.is_wednesday);
          formData.append('is_thursday', values.is_thursday);
          formData.append('is_friday', values.is_friday);
          formData.append('is_saturday', values.is_saturday);
          formData.append('sunday_opening_time', values.sunday_opening_time);
          formData.append('monday_opening_time', values.monday_opening_time);
          formData.append('tuesday_opening_time', values.tuesday_opening_time);
          formData.append('wednesday_opening_time', values.wednesday_opening_time);
          formData.append('thursday_opening_time', values.thursday_opening_time);
          formData.append('friday_opening_time', values.friday_opening_time);
          formData.append('saturday_opening_time', values.saturday_opening_time);
          formData.append('sunday_closing_time', values.sunday_closing_time);
          formData.append('monday_closing_time', values.monday_closing_time);
          formData.append('tuesday_closing_time', values.tuesday_closing_time);
          formData.append('wednesday_closing_time', values.wednesday_closing_time);
          formData.append('thursday_closing_time', values.thursday_closing_time);
          formData.append('friday_closing_time', values.friday_closing_time);
          formData.append('saturday_closing_time', values.saturday_closing_time);
          formData.append('copy_print_count', Math.floor(values.copy_print_count));
          formData.append('hardware_details', JSON.stringify(modifyObjectHardwareDetail()));
          // formData.append('hardware_details', JSON.stringify(hardwareDetail));
          formData.append('company_id', getCompany().company_id);
          formData.append('service_type', values.service_type);
          formData.append("erp_configuration", JSON.stringify(values.erp_configuration));
          if (values.service_type == 'table_service') {
            formData.append('dine_tables', JSON.stringify(values.dine_tables));
          }
          if (zatcaCertificateFile && activeInput === "upload") {
            formData.append("zatca_certificate", zatcaCertificateFile);
          }
          if (activeInput === "register") {
            formData.append('vat_name', values.vat_name);
            formData.append('vat_number', values.vat_number);
            formData.append('organisation_unit_name', values.organisation_unit_name);
            formData.append('otp', values.otp);
          }
          CreateOutlet(formData).then(response => {
            setSubmitting(false);
            setSubmitted(false);
            if (response.data.status === 1 || response.data.status === "1") {
              Swal.fire({
                customClass: 'swal-wide',
                icon: 'success',
                title: response.data.message,
                showConfirmButton: false,
                timer: 1500,
                toast: true,
                position: 'top-right',
              });
              // setTimeout(() => {
              //   navigate(`/company/business/outlet/list/${1}`)
              // }, 1000);
              setShowZatcaDetails(true);
              setZatcaData(response?.data?.response?.zatca_details);
              setData(response?.data?.response);
              setLoaderNew(false);
            } else {
              Swal.fire({
                customClass: 'swal-wide',
                icon: 'error',
                title: response.data.message,
                showConfirmButton: false,
                timer: 5000,
                toast: true,
                position: 'top-right',
              })
              setSubmitted(false)
              setLoaderNew(false);
            }
          }).catch(error => {
            console.log("error ====> ", error);
            setLoaderNew(false);
          })
        }}
      >{({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        setFieldTouched,
      }) => (
        <form onSubmit={handleSubmit}>
          <div className='steps' data-current-step={activeStep}>
            <ul>
              <li className={`step step-1 ${activeStep >= 1 ? 'active' : ''}`} style={{ cursor: 'default' }} onClick={() => handleStepChange(1)}>
                <span>1</span><p>Outlet Info</p>
              </li>
              <li className={`step step-2 ${activeStep >= 2 ? 'active' : 'tag_disabled'}`} onClick={() => handleStepChange(2)}>
                <span>2</span><p>Hardware Details</p>
              </li>
              <li className={`step step-3 ${activeStep >= 3 ? 'active' : 'tag_disabled'}`} onClick={() => handleStepChange(3)}>
                <span>3</span><p>Store Settings</p>
              </li>
              <li className={`step step-4 ${activeStep >= 4 ? 'active' : 'tag_disabled'}`} onClick={() => handleStepChange(4)}>
                <span>4</span><p>Outlet Services</p>
              </li>
              <li className={`step step-5 ${activeStep >= 5 ? 'active' : 'tag_disabled'}`} onClick={() => handleStepChange(6)}>
                <span>5</span><p>ERP Configuration</p>
              </li>
              <li className={`step step-6 ${activeStep >= 6 ? 'active' : 'tag_disabled'}`} onClick={() => handleStepChange(5)}>
                <span>5</span><p>Zatca Details</p>
              </li>

            </ul>
          </div>
          <div className="card custom-card">
            <div className="card-body">
              <div className='row row-sm'>
                <div className="step-1" id="checkout-progress" data-current-step="1" style={{ display: 'none', padding: '0' }}>
                  <div className="progress-bar">
                    <div className="step step-1 active"><span> 1</span>
                      <div className="fa fa-check opaque"></div>
                      <div className="step-label"> Outlet Info</div>
                    </div>
                    <div className="step step-2"><span> 2</span>
                      <div className="fa fa-check opaque"></div>
                      <div className="step-label"> Hardware Details</div>
                    </div>
                    <div className="step step-3"><span> 3</span>
                      <div className="fa fa-check opaque"></div>
                      <div className="step-label"> Store Settings</div>
                    </div>
                    <div className="step step-4"><span> 4</span>
                      <div className="fa fa-check opaque"></div>
                      <div className="step-label">Table Services</div>
                    </div>
                    <div className="step step-5"><span> 5</span>
                      <div className="fa fa-check opaque"></div>
                      <div className="step-label"> ERP Configuration</div>
                    </div>
                    {/* <div className="step step-5"><span> 5</span>
                      <div className="fa fa-check opaque"></div>
                      <div className="step-label"> Zatca Details</div>
                    </div> */}


                  </div>
                </div>
                <section id="section1" className="section1 " style={{ display: 'block' }}>
                  <div className='row'>
                    <div className='col-lg-6 text-center form-group'>
                      <label htmlFor='outlet_name' className='text-left d-flex'>Outlet Name <span className="requirestar">*</span></label>
                      <input name='outlet_name' id="outlet_name" placeholder='Enter outlet name' type='text' onChange={handleChange} onBlur={handleBlur} value={values.outlet_name} className='form-control' autoComplete='off' />
                      <span className='text-danger d-flex text-left'>{errors.outlet_name && touched.outlet_name && errors.outlet_name}</span>
                    </div>
                    <div className='col-lg-6 text-center form-group'>
                      <label htmlFor='outlet_name_ar' className='text-left d-flex'>Outlet Name (Arabic) <span className="requirestar">*</span></label>
                      <input name='outlet_name_ar' id="outlet_name_ar" placeholder='Enter outlet name (Arabic)' type='text' onChange={handleChange} onBlur={handleBlur} value={values.outlet_name_ar} className='form-control' autoComplete='off' />
                      <span className='text-danger d-flex text-left'>{errors.outlet_name_ar && touched.outlet_name_ar && errors.outlet_name_ar}</span>
                    </div>
                    <div className='col-lg-6 text-center form-group mt-3'>
                      <label htmlFor='brand_id' className='text-left d-flex'>Brand <span className="requirestar">*</span></label>
                      <select i
                        d="brand_id"
                        className='form-control'
                        name='brand_id'
                        onChange={(event) => {
                          handleChange(event);
                          setFieldValue('area', '');
                          setFieldValue('select_menu', '');
                          setFieldValue('routing_groups', '');
                          const selectedValue = event.target.value;
                          if (selectedValue) {
                            getRoutingGroups(selectedValue);
                            getAreas(selectedValue);
                            getMenus(selectedValue)
                          }
                        }}
                        onBlur={handleBlur}>
                        <option value="">Select Brand</option>
                        {Array.isArray(brandList) && brandList.length
                          ? brandList.map((row, i) => (
                            <option key={i} value={row?.id}>
                              {capitalizeFirstLetter(row?.name)}
                            </option>
                          ))
                          : ''}
                      </select>
                      <span className='text-danger d-flex text-left'>{errors.brand_id && touched.brand_id && errors.brand_id}</span>
                    </div>
                    <div className='col-lg-6 text-center form-group mt-3'>
                      <label htmlFor='short_code' className='text-left d-flex'>Shortcode </label>
                      <input name='short_code' id="short_code" type='text' onChange={handleChange} onBlur={handleBlur} value={values.short_code} className='form-control' autoComplete='off' placeholder='Enter Shortcode' />
                      <span className='text-danger d-flex text-left'>{errors.short_code && touched.short_code && errors.short_code}</span>
                    </div>
                    {/* <div className='col-lg-6 text-center form-group mt-3'>
                      <label htmlFor='vat_information' className='text-left d-flex'>VAT information <span className="requirestar">*</span></label>
                      <input name='vat_information' id="vat_information" type='text' onChange={handleChange} onBlur={handleBlur} value={values.vat_information} className='form-control' placeholder='Enter VAT information' autoComplete='off' />
                      <span className='text-danger d-flex text-left'>{errors.vat_information && touched.vat_information && errors.vat_information}</span>
                    </div> */}
                    <div className='col-lg-6 text-center form-group mt-3'>
                      <label htmlFor='email' className='text-left d-flex'>Email <span className="requirestar">*</span></label>
                      <input name='email' id="email" type='text' onChange={handleChange} onBlur={handleBlur} value={values.email} className='form-control' placeholder='Enter Email' autoComplete='off' />
                      <span className='text-danger d-flex text-left'>{errors.email && touched.email && errors.email}</span>
                    </div>
                    <div className='col-lg-6 text-center form-group mt-3'>
                      <label htmlFor='location' className='text-left d-flex'>Location </label>
                      <input name='location' id="location" type='text' placeholder='Enter location' onChange={handleChange} onBlur={handleBlur} value={values.location} className='form-control' autoComplete='off' />
                      <span className='text-danger d-flex text-left'>{errors.location && touched.location && errors.location}</span>
                    </div>
                    <div className='col-lg-6 text-center form-group mt-3'>
                      <label htmlFor='short_description' className='text-left d-flex'>Short description  </label>
                      <textarea name='short_description' placeholder='Enter short description' id="short_description" rows="2" type='text' onChange={handleChange} onBlur={handleBlur} value={values.short_description} className='form-control' />
                      <span className='text-danger d-flex text-left'>{errors.short_description && touched.short_description && errors.short_description}</span>
                    </div>
                    <div className='col-lg-6 text-center form-group mt-3'>
                      <label htmlFor='short_description_ar' className='text-left d-flex'>Short description (Arabic)  </label>
                      <textarea name='short_description_ar' placeholder='Enter short description (Arabic)' id="short_description_ar" rows="2" type='text' onChange={handleChange} onBlur={handleBlur} value={values.short_description_ar} className='form-control' />
                      <span className='text-danger d-flex text-left'>{errors.short_description_ar && touched.short_description_ar && errors.short_description_ar}</span>
                    </div>


                    <div className='col-lg-2 text-center form-group mt-3'>
                      <label htmlFor='country_id' className='text-left d-flex'>
                        Country <span className="requirestar">*</span>
                      </label>
                      <CountrySelect
                        className='form-control'
                        onChange={(e) => {
                          setCountryid(e.id);
                          setFieldValue(`country_id`, e);
                        }}
                        onBlur={() => setFieldTouched('country_id', true)}
                        placeHolder="Select Country"
                      />
                      <span className='text-danger d-flex text-left'>
                        {errors.country_id && touched.country_id && errors.country_id}
                      </span>
                    </div>
                    <div className='col-lg-2 text-center form-group mt-3'>
                      <label htmlFor='state_id' className='text-left d-flex'>
                        State <span className="requirestar">*</span>
                      </label>
                      <StateSelect
                        countryid={countryid}
                        onChange={(e) => {
                          setstateid(e.id);
                          setFieldValue(`state_id`, e);
                        }}
                        onBlur={() => setFieldTouched("state_id", true)}
                        placeHolder="Select State"
                      />
                      <span className='text-danger d-flex text-left'>
                        {errors.state_id && touched.state_id && errors.state_id}
                      </span>
                    </div>
                    <div className='col-lg-2 text-center form-group mt-3'>
                      <label htmlFor='city_id' className='text-left d-flex'>
                        City
                      </label>
                      <CitySelect
                        countryid={countryid}
                        stateid={stateid}
                        onChange={(e) => {
                          setcityid(e.id);
                          setFieldValue(`city_id`, e);
                        }}
                        placeHolder="Select City"
                      />
                      <span className='text-danger d-flex text-left'>
                        {errors.city_id && touched.city_id && errors.city_id}
                      </span>
                    </div>
                    <div className='col-lg-2 text-center form-group mt-3'>
                      <label htmlFor='area' className='text-left d-flex'>
                        Area <span className="requirestar">*</span>
                      </label>
                      <Field
                        as='select'
                        name='area'
                        id='area'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.area}
                        className='form-control'
                      >
                        <option key="" value="">Please select area</option>
                        {areas.map((area) => (
                          <option key={area.id} value={area.id}>
                            {area.name}
                          </option>
                        ))}
                      </Field>
                      <span className='text-danger d-flex text-left'>
                        {errors.area && touched.area && errors.area}
                      </span>
                    </div>

                  </div>
                </section>

                <section id="section2" className="section2" style={{ display: 'none' }}>
                  <div className='select_hardware_used_body'>

                    {addMoreCount?.map((row, index) => (
                      <div className='select_hardware_used '>
                        <div className='row'>
                          <div
                            style={{
                              borderTop: index >= 1 ? '1px solid gray' : 'none',
                              marginTop: index >= 1 ? '40px' : 'none',
                              paddingTop: '1rem',
                              textAlign: 'left',
                              display: 'flex',
                              flexDirection: 'column',
                              width: '50%',
                            }}
                          >
                            <label htmlFor='name' className='text-left d-flex mb-2 '>Select Hardware Used <span className="requirestar">*</span></label>
                            <div className='row ps-3'>
                              <div className="form-check w-25">
                                <input className="form-check-input hardware_used printer  cp" onChange={(e) => hardwareUsed(e, (index + 1), 'hardware_used', 1)} type="radio" name={"hardware_used_" + (index + 1)} id="printer" value="printer" checked={hardwareDetail[index + 1].hardware_used === 'printer'} data-id={index + 1} autoComplete='off' />
                                <label className="form-check-label cp" for="printer"  >Printer</label>
                              </div>
                              <div className="form-check w-25">
                                <input className="form-check-input hardware_used   cp" onChange={(e) => hardwareUsed(e, (index + 1), 'hardware_used', 2)} type="radio" name={"hardware_used_" + (index + 1)} id="swipe_machine" value="swipe_machine" data-id={index + 1} autoComplete='off' />
                                <label className="form-check-label cp" for="swipe_machine" >Swipe Machine</label>
                              </div>
                              <div className="form-check w-25">
                                <input className="form-check-input hardware_used   cp" onChange={(e) => hardwareUsed(e, (index + 1), 'hardware_used', 3)} type="radio" name={"hardware_used_" + (index + 1)} id="cash_drawer" value="cash_drawer" data-id={index + 1} autoComplete='off' />
                                <label className="form-check-label cp" for="cash_drawer" >Cash Drawer</label>
                              </div>
                              <div className="form-check w-25">
                                <input className="form-check-input hardware_used   cp" onChange={(e) => hardwareUsed(e, (index + 1), 'hardware_used', 4)} type="radio" name={"hardware_used_" + (index + 1)} id="pos" value="pos" data-id={index + 1} autoComplete='off' />
                                <label className="form-check-label cp" for="pos">POS</label>
                              </div>
                            </div>
                            <span className='text-danger d-flex text-left'>{errors.name && touched.name && errors.name}</span>
                          </div>
                          <div className='col-lg-6 text-left form-group'></div>
                        </div>
                        <div className={' hardware_printer hardware_printer_' + (index + 1) + ' hardware_tab_' + (index + 1)} data-id={index + 1}>
                          <div className='row'>
                            <div className='col-lg-6 text-left form-group mt-3'>
                              <label htmlFor='name' className='text-left d-flex mb-2'>Printer Type <span className="requirestar">*</span></label>
                              <div className='row ps-3'>
                                <div className="form-check w-25">
                                  <input className="form-check-input" type="radio" style={{ cursor: 'pointer' }} name={"printer_type_" + (index + 1)} id="customer" value="customer" onChange={(e) => handleChangeData(e, (index + 1), 'printer_type')} checked={hardwareDetail[(index + 1)].printer_type == 'customer' ? true : ''} autoComplete='off' />
                                  <label className="form-check-label" for="customer" style={{ cursor: 'pointer' }}>Customer</label>
                                </div>
                                <div className="form-check w-25">
                                  <input className="form-check-input" type="radio" style={{ cursor: 'pointer' }} name={"printer_type_" + (index + 1)} id="kitchen" value="kitchen" onChange={(e) => handleChangeData(e, (index + 1), 'printer_type')} checked={hardwareDetail[(index + 1)].printer_type == 'kitchen' ? true : ''} autoComplete='off' />
                                  <label className="form-check-label" for="kitchen" style={{ cursor: 'pointer' }}>Kitchen</label>
                                </div>
                              </div>
                              <span className='text-danger d-flex text-left'>{errors.name && touched.name && errors.name}</span>
                            </div>
                            <div className='col-lg-6 text-left form-group'></div>
                            {/* <div className='col-lg-6 text-center form-group mt-3'>
                              <Dropdown
                                placeholder="Select routing groups"
                                fluid
                                multiple
                                selection
                                className="routing_groups"
                                id={"routing_groups" + index}
                                value={hardwareDetail[(index + 1)].routing_groups || []}
                                onChange={(e, { value }) => {
                                  setRoutingGroup(value); // Update the state with the selected values
                                  handleChangeData(e, index + 1, 'routing_groups', value); // Pass the updated value to the handler
                                }}
                                options={options}
                              />
                              <span className='text-danger d-flex text-left'>{errors.routing_groups && touched.routing_groups && errors.routing_groups}</span>
                            </div>
                            <div className='col-lg-6 text-left form-group'></div> */}
                            <div className='col-lg-6 text-center form-group mt-3'>
                                <label htmlFor='printer_ip' className='text-left d-flex'>Printer IP</label>
                                <input name='printer_ip[]' id="printer_ip" placeholder='Enter printer IP' type='text' onChange={(e) => handleChangeData(e, (index + 1), 'printer_ip')} value={hardwareDetail[(index + 1)].printer_ip} className='form-control' autoComplete='off' />
                            </div>
                            <div className='col-lg-6 text-left form-group'></div>
                            <div className='col-lg-6 text-center form-group mt-3'>
                              <label htmlFor='name' className='text-left d-flex'>Device Name </label>
                              <input name='name' id="name" type='text' placeholder='Enter name' onChange={(e) => handleChangeData(e, (index + 1), 'name')} value={hardwareDetail[(index + 1)].name} className='form-control' autoComplete='off' />
                            </div>

                            <div className='col-lg-6 text-left form-group'></div>
                            {
                              hardwareDetail[(index + 1)].printer_type == 'kitchen' 
                                ?
                                    <>
                                        <div className='col-lg-6 text-center form-group mt-3'>
                                            <label htmlFor='registration_id' className='text-left d-flex'>Device Registration ID</label>
                                            <input name='registration_id[]' id="registration_id" placeholder='Enter registration ID' type='text' onChange={(e) => handleChangeData(e, (index + 1), 'registration_id')} value={hardwareDetail[(index + 1)].registration_id} className='form-control' autoComplete='off' />
                                        </div> 
                                        <div className='col-lg-6 text-left form-group'></div>
                                    </>
                                : null
                            }
                            {/* <div className='col-lg-6 text-left form-group'></div>
                            <div className='col-lg-6 text-center form-group mt-3'>
                              <label htmlFor='sdk_id' className='text-left d-flex'>SDK ID </label>
                              <input name='sdk_id' id="sdk_id" type='text' placeholder='Enter SDK ID' onChange={(e) => handleChangeData(e, (index + 1), 'sdk_id')} value={hardwareDetail[(index + 1)].sdk_id} className='form-control' autoComplete='off' />
                            </div> */}
                          </div>
                        </div>
                        <div className={' hardware_swipe_machine hardware_swipe_machine_' + (index + 1) + ' hardware_tab_' + (index + 1)} data-id="1" style={{ display: 'none' }}></div>
                        <div className={' hardware_cash_drawer hardware_cash_drawer_' + (index + 1) + ' hardware_tab_' + (index + 1)} data-id="1" style={{ display: 'none' }}></div>
                        <div className={' hardware_pos hardware_pos_' + (index + 1) + ' hardware_tab_' + (index + 1)} data-id="1" style={{ display: 'none' }}>
                          <div className='row'>
                            <div className='col-lg-6 text-center form-group mt-3'>
                              <label htmlFor='registration_id' className='text-left d-flex'>Routing Groups </label>
                              <Dropdown
                                placeholder="Select routing groups"
                                fluid
                                multiple
                                selection
                                className="routing_groups"
                                id={"routing_groups" + index}
                                value={hardwareDetail[(index + 1)].routing_groups || []}
                                onChange={(e, { value }) => {
                                  setRoutingGroup(value); // Update the state with the selected values
                                  handleChangeData(e, index + 1, 'routing_groups', value); // Pass the updated value to the handler
                                }}
                                options={options}
                              />
                              <span className='text-danger d-flex text-left'>{errors.routing_groups && touched.routing_groups && errors.routing_groups}</span>
                            </div>

                            <div className='col-lg-6 text-left form-group'></div>
                            <div className='col-lg-6 text-center form-group mt-3'>
                              <label htmlFor='name' className='text-left d-flex'>Device Name </label>
                              <input name='name[]' id="name" type='text' placeholder='Enter name' onChange={(e) => handleChangeData(e, (index + 1), 'name')} value={hardwareDetail[(index + 1)].name} className='form-control' autoComplete='off' />
                            </div>

                            <div className='col-lg-6 text-left form-group'></div>    
                            <div className='col-lg-6 text-center form-group mt-3'>
                              <label htmlFor='registration_id' className='text-left d-flex'>Device Registration ID </label>
                              <input name='registration_id' id="registration_id" type='text' onChange={(e) => handleChangeData(e, (index + 1), 'registration_id')} value={hardwareDetail[(index + 1)].registration_id} className='form-control' placeholder='Enter Registration ID' autoComplete='off' />
                            </div>
                            
                            {/* <div className='col-lg-6 text-left form-group'></div>
                            <div className='col-lg-6 text-center form-group mt-3'>
                              <label htmlFor='sdk_id' className='text-left d-flex'>SDK ID </label>
                              <input name='sdk_id[]' id="sdk_id" type='text' onChange={(e) => handleChangeData(e, (index + 1), 'sdk_id')} value={hardwareDetail[(index + 1)].sdk_id} className='form-control' placeholder='Enter SDK ID' autoComplete='off' />
                            </div> */}
                            <div className='col-lg-6 text-left form-group'></div>
                            <div className='col-lg-6 text-left form-group mt-4'>
                              <label htmlFor='select_menu' className='text-left d-flex mb-2'>Select Menu</label>
                              <div className='row ps-3'>
                                {
                                  menus.length > 0 ?
                                    menus.map((menu, indexNumber) => {
                                      return (
                                        <div className="form-check w-25" key={menu.id}>
                                          <input
                                            className={'cp form-check-input dinner_menu_cp' + (index + 1)}
                                            type="radio"
                                            name={`select_menu_${index + 1}`}
                                            id={`dinnerMenu${index + 1}_${indexNumber + 1}`}
                                            value={menu.id}
                                            checked={hardwareDetail[index + 1]?.select_menu?.includes(menu.id)}
                                            onChange={(e) => handleDinnerMenu(e, index + 1, 'select_menu', indexNumber)}
                                            autoComplete="off"
                                          />
                                          <label className="form-check-label cp" htmlFor={`dinnerMenu${index + 1}_${indexNumber + 1}`}>
                                            {capitalizeAllFirstLetters(menu.name)}
                                          </label>
                                        </div>
                                      );
                                    })
                                    : null
                                }
                              </div>
                              <span className='text-danger d-flex text-left'>{errors.name && touched.name && errors.name}</span>
                            </div>

                          </div>
                        </div>
                        <span className='text-danger d-flex text-left'>{hardwareError[index + 1] && "All fields are required"}</span>
                      </div>
                    ))}
                  </div>

                  {<button type='button' className='btn btn-light mt-4 text-primary' onClick={addMore} style={{
                    display: btnHide ? 'none' : ''
                  }} >  + Add More</button>}
                  {addMoreCount.length > 1 && (
                    <button
                      type='button'
                      className='btn btn-danger mt-4 mx-3'
                      onClick={removeLast}
                      style={{ display: btnHide ? 'none' : '' }}
                    >
                      Remove
                    </button>
                  )}
                  <button
                    type='button'
                    className='btn btn-danger mt-4'
                    onClick={clearAll}
                    style={{ display: btnHide ? 'none' : '' }}
                  >
                    Clear
                  </button>
                </section>

                <section id="section3" className="section3" style={{ display: 'none' }}>

                  <div className='row'>
                    <div className='col-lg-6 text-center form-group'>
                      <label htmlFor='invoice_header_text_info' className='text-left d-flex'>Invoice Header Text Info </label>
                      <textarea name='invoice_header_text_info' id="invoice_header_text_info" rows="2" type='text' onChange={handleChange} onBlur={handleBlur} value={values.invoice_header_text_info} className='form-control' placeholder='Enter invoice header text info' />
                      <span className='text-danger d-flex text-left'>{errors.invoice_header_text_info && touched.invoice_header_text_info && errors.invoice_header_text_info}</span>

                    </div>
                    <div className='col-lg-6 text-center form-group'>
                      <label htmlFor='invoice_footer_text_info' className='text-left d-flex'>Invoice Footer Text Info </label>
                      <textarea name='invoice_footer_text_info' id="invoice_footer_text_info" rows="2" type='text' onChange={handleChange} onBlur={handleBlur} value={values.invoice_footer_text_info} className='form-control' placeholder='Enter invoice footer text info' />
                      <span className='text-danger d-flex text-left'>{errors.invoice_footer_text_info && touched.invoice_footer_text_info && errors.invoice_footer_text_info}</span>

                    </div>
                    <div className='col-lg-6 text-left form-group mt-4'>
                      <label htmlFor='end_of_the_day_configuration' className='text-left d-flex mb-2'>End of the day configuration </label>
                      <div className='row ps-3'>
                        <div>
                          <div className="d-flex align-items-center">
                            <div className="form-check w-25">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="end_of_the_day_configuration"
                                id="automatic"
                                value="automatic"
                                checked={selectedOption === "automatic"}
                                onChange={handleOptionChange}
                                onBlur={handleBlur}
                                autoComplete='off'
                                style={{ cursor: 'pointer', }}
                              />
                              <label className="form-check-label" htmlFor="automatic" style={{ cursor: 'pointer' }}>
                                Automatic
                              </label>
                            </div>
                            <div className="form-check w-25">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="end_of_the_day_configuration"
                                id="manual"
                                value="manual"
                                checked={selectedOption === "manual"}
                                onChange={handleOptionChange}
                                onBlur={handleBlur}
                                autoComplete='off'
                                style={{ cursor: 'pointer' }}
                              />
                              <label className="form-check-label" htmlFor="manual" style={{ cursor: 'pointer' }}>
                                Manual
                              </label>
                            </div>
                          </div>

                          {selectedOption === 'manual' && (
                            <div className='col-lg-6 text-center form-group mt-3'>
                            </div>
                          )}

                          {selectedOption === 'automatic' && (
                            <div className='col-lg-6 text-center form-group mt-3'>
                              <input
                                name='end_of_the_day_time'
                                id="end_of_the_day_time"
                                type='time'
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.end_of_the_day_time}
                                className='form-control'
                                autoComplete='off'
                              />
                              <span className='text-danger d-flex text-left'>{errors.end_of_the_day_time && touched.end_of_the_day_time && errors.end_of_the_day_time}</span>
                            </div>
                          )}
                        </div>

                      </div>
                    </div>
                    <div className='col-lg-6 text-center form-group mt-3'></div>
                    <div className='col-lg-6 text-center form-group mt-3'>
                    </div>
                    <div className='col-lg-6 text-center form-group mt-3'></div>
                    <div className='col-lg-6 text-center form-group mt-3'>
                      <label htmlFor='name' className='text-left d-flex mb-2'>Store opening and closing time</label>
                      <table width='100%'>
                        {storeTime.map((row, i) => (
                          <tr>
                            <td className='px-2 py-3'>
                              <div className="form-check w-25">
                                <input className="form-check-input" style={{ cursor: 'pointer' }}
                                  type="checkbox"
                                  name={"is_" + row.value}
                                  id={"days_checkbox" + i}
                                  value="1"
                                  onChange={(e) => {
                                    handleChange(e);
                                    setFieldValue(`is_${row.value}`, e.target.checked);
                                  }}

                                  onBlur={handleBlur}
                                  autoComplete='off' />
                                <label className="form-check-label cp" htmlFor={"days_checkbox" + i}>{row.label}</label>
                              </div>
                            </td>
                            <>
                              <td className='p-0 pe-0 w-100' colSpan={3}>
                                <div className='d-flex'>
                                  {values[`is_${row.value}`] && (
                                    <>
                                      <InputGroup className='w-50 me-2'>
                                        <Form.Control
                                          type="time"
                                          placeholder="Opening Time"
                                          name={`${row.value}_opening_time`}
                                          onBlur={handleBlur}
                                          value={values[`${row.value}_opening_time`]}
                                          onChange={(event) => {
                                            const time = event.target.value;
                                            setFieldValue(row.value + '_opening_time', time);
                                          }}
                                        />
                                      </InputGroup>

                                    </>
                                  )}
                                  {values[`is_${row.value}`] && (
                                    <>
                                      {/* <InputGroup className='w-50' onClick={(e) => e.target.showPicker()}> */}
                                      <InputGroup className='w-50'>
                                        <Form.Control
                                          type="time"
                                          placeholder="Closing Time"
                                          name={`${row.value}_closing_time`}
                                          value={values[`${row.value}_closing_time`]}
                                          onBlur={handleBlur}
                                          onChange={(event) => {
                                            const closingTime = event.target.value;
                                            setFieldValue(row.value + '_closing_time', closingTime);
                                          }}
                                        />
                                      </InputGroup>
                                      {/* <span className='text-danger d-block text-start'>{touched[`${row.value}_closing_time`] && errors[`${row.value}_closing_time`]}</span> */}
                                    </>
                                  )}
                                </div>
                                <span className='text-danger d-block text-start'>{touched[`is_${row.value}`] && errors[`is_${row.value}`]}</span>
                              </td>
                            </>
                          </tr>
                        ))}
                      </table>
                    </div>
                    <div className='col-lg-6 text-left form-group mt-4'></div>
                    <div className='col-lg-6 text-left form-group mt-4'>
                      <label htmlFor='copy_print_count' className='text-left d-flex mb-2'>How many copy of the check will be print ?</label>
                      <input name='copy_print_count' id="copy_print_count" type='number' onChange={handleChange} onBlur={handleBlur} value={values.copy_print_count} className='form-control' placeholder='Enter number' autoComplete='off' />
                      <span className='text-danger d-flex text-left'>{(errors.copy_print_count || touched.copy_print_count == true)}</span>
                    </div>
                  </div>
                </section>

                <section id="section4" className="section4" style={{ display: 'none' }}>
                  <div className='row'>
                    <div className="col-lg-12 text-center form-group mt-3">
                      <label className="text-left d-flex">Service Type<span className="requirestar">*</span></label>
                      <div className="row ps-3 mt-3">
                        <div className="form-check w-25 text-start">
                          <input className="form-check-input cp" type="radio" name="service_type" id="quick_service" autocomplete="off" value="quick_service" checked={values.service_type === 'quick_service'} onChange={handleChange} onBlur={handleBlur} />
                          <label className="form-check-label cp" for="quick_service">Quick Service</label>
                        </div>
                        <div className="form-check w-25 text-start">
                          <input className="form-check-input cp" type="radio" name="service_type" id="table_service" autocomplete="off" value="table_service" checked={values.service_type === 'table_service'} onChange={handleChange} onBlur={handleBlur} />
                          <label className="form-check-label cp" for="table_service">Table Service</label>
                        </div>
                      </div>
                      <span className="text-danger d-flex text-left">{errors.service_type && touched.service_type && errors.service_type}</span>
                    </div>
                    {values?.service_type === 'table_service' &&
                      <div className="col-lg-12 text-center form-group mt-3">
                        <label className="text-left d-flex">Table Details</label>
                        {values?.dine_tables?.map((table, index) => (
                          <div className="row gx-3 mb-2" key={index}>
                            <div className="col-3">
                              <input className='form-control' type='text' name={`dine_tables[${index}].table_number`} id={`dine_tables[${index}].table_number`} value={table.table_number} onChange={handleChange} onBlur={handleBlur} placeholder='Enter table number' autoComplete='off' />
                              {
                                errors[`dine_tables[${index}].table_number`] &&
                                touched.dine_tables &&
                                touched.dine_tables[index] &&
                                touched.dine_tables[index].table_number && (
                                  <span className="text-danger d-flex text-left">
                                    {errors[`dine_tables[${index}].table_number`]}
                                  </span>
                                )
                              }
                            </div>
                            <div className="col-3">
                              <input className='form-control' type='text' name={`dine_tables[${index}].table_name`} id={`dine_tables[${index}].table_name`} value={table.table_name} onChange={handleChange} onBlur={handleBlur} placeholder='Enter table name' autoComplete='off' />
                              {
                                errors[`dine_tables[${index}].table_name`] &&
                                touched.dine_tables &&
                                touched.dine_tables[index] &&
                                touched.dine_tables[index].table_name && (
                                  <span className="text-danger d-flex text-left">
                                    {errors[`dine_tables[${index}].table_name`]}
                                  </span>
                                )
                              }
                            </div>
                            <div className='col-1 d-flex'>
                              {values?.dine_tables?.length > 1 &&
                                <button type='button' className="btn btn-danger mx-1 rounded-circle" onClick={() => handleRemove(values, index, setFieldValue)}>✖</button>
                              }
                              {index === values?.dine_tables?.length - 1 &&
                                <button type='button' className="btn btn-primary mx-1 rounded-circle" onClick={() => handleAdd(values, setFieldValue)}>+</button>
                              }
                            </div>
                          </div>
                        ))}
                      </div>
                    }
                  </div>
                </section>

                <section id="section5" className="section5" style={{ display: 'none' }}>
                  <div className='row'>
                    <div className='col-lg-6 text-center form-group'>
                      <label htmlFor='erp_configuration.dimension_value_1' className='text-left d-flex'>Dimenssion value 1
                        <Whisper
                          placement="top"
                          controlId="control-id-hover"
                          trigger="hover"
                          speaker={
                            <Tooltip>
                              Please Enter dimension value 1 as per the erp requirement.
                            </Tooltip>
                          }
                        >
                          <span className="field-more-info ms-1 cp">?</span>
                        </Whisper>
                      </label>
                      <input
                        name='erp_configuration.dimension_value_1'
                        id="erp_configuration.dimension_value_1"
                        placeholder='Enter dimenssion value 1'
                        type='text'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.erp_configuration.dimension_value_1}
                        className='form-control'
                        autoComplete='off'
                      />
                      {/* <span className='text-danger d-flex text-left'>{errors.dimension_value_1 && touched?.erp_configuration?.dimension_value_1 && errors.dimension_value_1}</span> */}
                    </div>

                    <div className='col-lg-6 text-center form-group'>
                      <label htmlFor='erp_configuration.dimension_value_2' className='text-left d-flex'>Dimenssion value 2
                      <Whisper
                          placement="top"
                          controlId="control-id-hover"
                          trigger="hover"
                          speaker={
                            <Tooltip>
                              Please Enter dimension value 2 as per the erp requirement.
                            </Tooltip>
                          }
                        >
                          <span className="field-more-info ms-1 cp">?</span>
                        </Whisper>
                      </label>
                      <input
                        name='erp_configuration.dimension_value_2'
                        id="erp_configuration.dimension_value_2"
                        placeholder='Enter dimenssion value 2'
                        type='text'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.erp_configuration.dimension_value_2}
                        className='form-control'
                        autoComplete='off'
                      />
                      {/* <span className='text-danger d-flex text-left'>{errors.dimension_value_2 && touched?.erp_configuration?.dimension_value_2 && errors.dimension_value_2}</span> */}
                    </div>

                    <div className='col-lg-6 text-center form-group'>
                      <label htmlFor='erp_configuration.dimension_value_3' className='text-left d-flex'>Dimenssion value 3
                      <Whisper
                          placement="top"
                          controlId="control-id-hover"
                          trigger="hover"
                          speaker={
                            <Tooltip>
                              Please Enter dimension value 3 as per the erp requirement.
                            </Tooltip>
                          }
                        >
                          <span className="field-more-info ms-1 cp">?</span>
                        </Whisper>
                      </label>
                      <input
                        name='erp_configuration.dimension_value_3'
                        id="erp_configuration.dimension_value_3"
                        placeholder='Enter dimenssion value 3'
                        type='text'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.erp_configuration.dimension_value_3}
                        className='form-control'
                        autoComplete='off'
                      />
                      {/* <span className='text-danger d-flex text-left'>{errors.dimension_value_3 && touched?.erp_configuration?.dimension_value_3 && errors.dimension_value_3}</span> */}
                    </div>

                    <div className='col-lg-6 text-center form-group'>
                      <label htmlFor='erp_configuration.dimension_value_4' className='text-left d-flex'>Dimenssion value 4
                      <Whisper
                          placement="top"
                          controlId="control-id-hover"
                          trigger="hover"
                          speaker={
                            <Tooltip>
                              Please Enter dimension value 4 as per the erp requirement.
                            </Tooltip>
                          }
                        >
                          <span className="field-more-info ms-1 cp">?</span>
                        </Whisper>
                      </label>
                      <input
                        name='erp_configuration.dimension_value_4'
                        id="erp_configuration.dimension_value_4"
                        placeholder='Enter dimenssion value 4'
                        type='text'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.erp_configuration.dimension_value_4}
                        className='form-control'
                        autoComplete='off'
                      />
                      {/* <span className='text-danger d-flex text-left'>{errors.dimension_value_4 && touched?.erp_configuration?.dimension_value_4 && errors.dimension_value_4}</span> */}
                    </div>

                    <div className='col-lg-6 text-center form-group'>
                      <label htmlFor='erp_configuration.dimension_value_5' className='text-left d-flex'>Dimenssion value 5
                      <Whisper
                          placement="top"
                          controlId="control-id-hover"
                          trigger="hover"
                          speaker={
                            <Tooltip>
                              Please Enter dimension value 5 as per the erp requirement.
                            </Tooltip>
                          }
                        >
                          <span className="field-more-info ms-1 cp">?</span>
                        </Whisper>
                      </label>
                      <input
                        name='erp_configuration.dimension_value_5'
                        id="erp_configuration.dimension_value_5"
                        placeholder='Enter dimenssion value 5'
                        type='text'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.erp_configuration.dimension_value_5}
                        className='form-control'
                        autoComplete='off'
                      />
                      {/* <span className='text-danger d-flex text-left'>{errors.dimension_value_5 && touched?.erp_configuration?.dimension_value_5 && errors.dimension_value_5}</span> */}
                    </div>

                    <div className='col-lg-6 text-center form-group'>
                      <label htmlFor='erp_configuration.dimension_value_6' className='text-left d-flex'>Dimenssion value 6
                      <Whisper
                          placement="top"
                          controlId="control-id-hover"
                          trigger="hover"
                          speaker={
                            <Tooltip>
                              Please Enter dimension value 6 as per the erp requirement.
                            </Tooltip>
                          }
                        >
                          <span className="field-more-info ms-1 cp">?</span>
                        </Whisper>
                      </label>
                      <input
                        name='erp_configuration.dimension_value_6'
                        id="erp_configuration.dimension_value_6"
                        placeholder='Enter dimenssion value 6'
                        type='text'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.erp_configuration.dimension_value_6}
                        className='form-control'
                        autoComplete='off'
                      />
                      {/* <span className='text-danger d-flex text-left'>{errors.dimension_value_6 && touched?.erp_configuration?.dimension_value_6 && errors.dimension_value_6}</span> */}
                    </div>

                  </div>
                </section>

                <section id="section6" className="section6" style={{ display: "none" }}>
                  <div className="col-lg-12">
                    {!showZatcaDetails ?
                      <div className="d-flex justify-content-between align-items-center">
                        {loaderNew && (
                          <div className="loader-overlay">
                            <div className="spinner-border text-primary" role="status">
                              <span className="visually-hidden">Loading...</span>
                            </div>
                          </div>
                        )}
                        <div className="d-flex align-items-center">
                          <button
                            className="btn btn-primary px-4"
                            type="button"
                            onClick={() => {
                              setActiveInput("register");
                              setErrorMessage("");
                            }}
                          >
                            Register
                          </button>
                          <span className="mx-3 text-muted" style={{ fontWeight: "bold" }}>OR</span>
                          <button
                            className="btn btn-secondary px-4"
                            type="button"
                            onClick={() => {
                              setActiveInput("upload");
                              setErrorMessage("");
                            }}
                          >
                            Upload Certificate
                          </button>
                        </div>
                        <div className="btn-group" role="group">
                          <div className="dropdown">
                            <button className="btn btn-primary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                              Sample Zatca Certificate
                            </button>
                            <ul className="dropdown-menu">
                              <li><a className="dropdown-item" href={ZatcaSampleFileCsv} download>Download csv file</a></li>
                              <li><a className="dropdown-item" href={ZatcaSampleFileXls} download>Download xls file</a></li>
                              <li><a className="dropdown-item" href={ZatcaSampleFileXlsx} download>Download xlsx file</a></li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      : null}
                    {errorMessage && (
                      <div className="text-dark mt-2 text-right">
                        <i className="fa fa-exclamation-circle" aria-hidden="true"></i>{" "}
                        {errorMessage}
                      </div>
                    )}
                  </div>
                  {!showZatcaDetails ?
                    <>
                      {activeInput === "register" && (
                        <>
                          <div className="row mt-3">
                            <div className="col-lg-6 form-group mt-3">
                              <label htmlFor="vat_name" className="d-flex">
                                Organization Name <span className="requirestar">*</span>
                              </label>
                              <input
                                name="vat_name"
                                id="vat_name"
                                type="text"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.vat_name}
                                className="form-control"
                                placeholder="Enter organization name"
                                autoComplete="off"
                              />
                              <span className="text-danger d-flex">
                                {errors.vat_name && touched.vat_name && errors.vat_name}
                              </span>
                            </div>
                            <div className="col-lg-6 form-group mt-3">
                              <label htmlFor="vat_number" className="d-flex">
                                VAT Number <span className="requirestar">*</span>
                              </label>
                              <input
                                name="vat_number"
                                id="vat_number"
                                type="number"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.vat_number}
                                className="form-control"
                                placeholder="Enter VAT number"
                                autoComplete="off"
                              />
                              <span className="text-danger d-flex">
                                {errors.vat_number && touched.vat_number && errors.vat_number}
                              </span>
                            </div>
                            <div className="col-lg-6 form-group mt-3">
                              <label htmlFor="organisation_unit_name" className="d-flex">
                                Organization Unit Name <span className="requirestar">*</span>
                              </label>
                              <input
                                name="organisation_unit_name"
                                id="organisation_unit_name"
                                type="text"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.organisation_unit_name}
                                className="form-control"
                                placeholder="Enter organization unit name"
                                autoComplete="off"
                              />
                              <span className="text-danger d-flex">
                                {errors.organisation_unit_name &&
                                  touched.organisation_unit_name &&
                                  errors.organisation_unit_name}
                              </span>
                            </div>

                            <div className='col-lg-6 text-center form-group mt-3'>
                              <label htmlFor='postal_zone' className='text-left d-flex'>Postal Zone<span className="requirestar">*</span></label>
                              <input name='postal_zone' id="postal_zone" type='text' onChange={handleChange} onBlur={handleBlur} value={values.postal_zone} className='form-control' placeholder='Enter Postal Zone' autoComplete='off' />
                              <span className='text-danger d-flex text-left'>{errors.postal_zone && touched.postal_zone && errors.postal_zone}</span>
                            </div>

                            <div className='col-lg-2 text-center form-group mt-3'>
                              <label htmlFor='street' className='text-left d-flex'>Street <span className="requirestar">*</span></label>
                              <input name='street' id="street" type='text' placeholder='Enter street' onChange={handleChange} onBlur={handleBlur} value={values.street} className='form-control' autoComplete='off' />
                              <span className='text-danger d-flex text-left'>{errors.street && touched.street && errors.street}</span>
                            </div>
                            <div className='col-lg-2 text-center form-group mt-3'>
                              <label htmlFor='building' className='text-left d-flex'>Building number <span className="requirestar">*</span></label>
                              <input name='building' id="building" type='number' placeholder='Enter building' onChange={handleChange} onBlur={handleBlur} value={values.building} className='form-control' autoComplete='off' />
                              <span className='text-danger d-flex text-left'>{errors.building && touched.building && errors.building}</span>
                            </div>
                            <div className='col-lg-2 text-center form-group mt-3'>
                              <label htmlFor='plot_identification' className='text-left d-flex'>Plot Identification number <span className="requirestar">*</span></label>
                              <input name='plot_identification' id="plot_identification" type='number' placeholder='Enter plot_identification' onChange={handleChange} onBlur={handleBlur} value={values.plot_identification} className='form-control' autoComplete='off' />
                              <span className='text-danger d-flex text-left'>{errors.plot_identification && touched.plot_identification && errors.plot_identification}</span>
                            </div>
                            <div className='col-lg-2 text-center form-group mt-3'>
                              <label htmlFor='city_subdivision' className='text-left d-flex'>City Subdivision <span className="requirestar">*</span></label>
                              <input name='city_subdivision' id="city_subdivision" type='text' placeholder='Enter city_subdivision' onChange={handleChange} onBlur={handleBlur} value={values.city_subdivision} className='form-control' autoComplete='off' />
                              <span className='text-danger d-flex text-left'>{errors.city_subdivision && touched.city_subdivision && errors.city_subdivision}</span>
                            </div>


                            <div className="col-lg-6 form-group mt-3">
                              <label htmlFor="otp" className="d-flex">
                                OTP <span className="requirestar">*</span>
                              </label>
                              <input
                                name="otp"
                                id="otp"
                                type="number"
                                value={values.otp}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className="form-control"
                                placeholder="Enter OTP"
                                autoComplete="off"
                              />
                              <span className="text-danger d-flex">
                                {errors.otp && touched.otp && errors.otp}
                              </span>
                            </div>

                          </div>
                        </>
                      )}
                    </>
                    : null}
                  {!showZatcaDetails ? <>
                    {activeInput === "upload" && (
                      <form
                        id="reader_id"
                        style={{
                          height: "300px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div className="text-center">
                          <label htmlFor="file_reader" style={{ cursor: "pointer" }}>
                            <input
                              ref={fileInputRef}
                              type="file"
                              className="d-none"
                              onChange={handleUploadExcel}
                              accept=".xls,.xlsx,.csv"
                              id="file_reader"
                            />
                            <i
                              className="fa fa-upload"
                              aria-hidden="true"
                              style={{ fontSize: "7em", color: "#3598ff" }}
                            ></i>
                          </label>
                          <p className="text-muted mb-0">
                            Please upload Zatca Certificate.
                          </p>
                        </div>
                      </form>
                    )}
                  </>
                    : null}
                  {showZatcaDetails ?
                    <div className='col-lg-12 col-md-6 animation_fade mt-3'>
                      {loader && (
                        <div className="loader-overlay">
                          <div className="spinner-border text-primary" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        </div>
                      )}
                      <div className="card custom-card">
                        <div className="card-body">
                          <h3 className="main-content-label mb-3">Outlet is onboarded successfully at zatca </h3>
                          <div className="table-responsive">
                            <table id="simple-table" className="table">
                              <tbody>
                                <tr>
                                  <td className="fw-bold">Common Name</td>
                                  <td style={{ wordBreak: 'break-word' }}>
                                    {data?.outlet_name ? data.outlet_name : "N/A"}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="fw-bold">Organization Name</td>
                                  <td style={{ wordBreak: 'break-word' }}>
                                    {data?.vat_name ? data.vat_name : "N/A"}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="fw-bold">VAT Number</td>
                                  <td style={{ wordBreak: 'break-word' }}>
                                    {data?.vat_number ? data.vat_number : "N/A"}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="fw-bold">Organization Unit Name</td>
                                  <td style={{ wordBreak: 'break-word' }}>
                                    {data?.organisation_unit_name ? data.organisation_unit_name : "N/A"}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="fw-bold">Private Key</td>
                                  <td style={{ wordBreak: 'break-word' }}>
                                    {zatcaData?.private_key ? zatcaData?.private_key : "N/A"}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="fw-bold">CSR</td>
                                  <td style={{ wordBreak: 'break-word' }}>
                                    {zatcaData.csr ? zatcaData?.csr : "N/A"}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="fw-bold">Compliance Request ID</td>
                                  <td style={{ wordBreak: 'break-word' }}>
                                    {zatcaData?.compliance_request_id ? zatcaData.compliance_request_id : "N/A"}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="fw-bold">Compliance Certificate</td>
                                  <td style={{ wordBreak: 'break-word' }}>
                                    {zatcaData?.compliance_certificate ? zatcaData.compliance_certificate : "N/A"}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="fw-bold">Compliance Secret</td>
                                  <td style={{ wordBreak: 'break-word' }}>
                                    {zatcaData?.compliance_secret ? zatcaData.compliance_secret : "N/A"}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="fw-bold">Production Request ID</td>
                                  <td style={{ wordBreak: 'break-word' }}>
                                    {zatcaData?.production_request_id ? zatcaData?.production_request_id : "N/A"}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="fw-bold">Production Certificate</td>
                                  <td style={{ wordBreak: 'break-word' }}>
                                    {zatcaData?.production_certificate ? zatcaData?.production_certificate : "N/A"}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="fw-bold">Production Secret</td>
                                  <td style={{ wordBreak: 'break-word' }}>
                                    {zatcaData?.production_secret ? zatcaData?.production_secret : "N/A"}
                                  </td>
                                </tr>
                                <tr>
                                  <tr>
                                    <td className="fw-bold">Previous Invoice Hash</td>
                                    <td style={{ wordBreak: 'break-word' }}>
                                      {zatcaData?.previous_invoice_hash ? zatcaData?.previous_invoice_hash : "N/A"}
                                    </td>
                                  </tr>
                                  <td className="fw-bold">Public Key</td>
                                  <td style={{ wordBreak: 'break-word' }}>
                                    {zatcaData?.public_key ? zatcaData?.public_key : "N/A"}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="fw-bold">Street</td>
                                  <td style={{ wordBreak: 'break-word' }}>
                                    {data?.street ? data.street : "N/A"}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="fw-bold">Building Number</td>
                                  <td style={{ wordBreak: 'break-word' }}>
                                    {data?.building ? data.building : "N/A"}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="fw-bold">Plot Identification Number</td>
                                  <td style={{ wordBreak: 'break-word' }}>
                                    {data?.plot_identification ? data.plot_identification : "N/A"}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="fw-bold">City Subdivision</td>
                                  <td style={{ wordBreak: 'break-word' }}>
                                    {data?.city_subdivision ? data.city_subdivision : "N/A"}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="fw-bold">Postal Zone</td>
                                  <td style={{ wordBreak: 'break-word' }}>
                                    {data?.postal_zone ? data.postal_zone : "N/A"}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                            <button
                              className="btn btn-primary px-4"
                              type="button"
                              onClick={registerToZetca}
                            >
                              Register again at zatca
                            </button>
                            <button
                              className="btn btn-dark ms-2"
                              type="button"
                              onClick={handleGoToList}

                            >
                              Go To List
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    : null}
                </section>


                <div className="button-container mt-4 p-0">
                  {!showZatcaDetails ?
                    <button className="btn btn-outline-primary btn-prev ms-2" type="button" onClick={prevStep} style={{ display: 'none' }}>Back</button>
                    : null}
                  <button className="btn btn-next btn-primary ms-2" type="button" onClick={nextStep} disabled={btnDisable}> Next</button>
                  {!showZatcaDetails ?
                    <button className="btn btn-primary ms-2 btn-submit" onClick={() => { setBtnClicked(true) }} type="submit" disabled={activeInput === "upload" ? !isFileUploaded : null} style={{ display: 'none' }}>Submit</button>
                    : null}
                </div>
              </div>
            </div>
          </div>
        </form>
      )}
      </Formik>
      <ToastContainer
        position="top-right"
        hideProgressBar={false}
        autoClose={true | 1000}
        newestOnTop={true}
        closeOnClick={false}
        draggable={false}
        rtl={false}
      />
    </>
  );
}

export default OutletAdd
